const firebaseConfig = {
  apiKey: 'AIzaSyCzd48XOo-HUqn8crQN0kvljYhHoiIqg1E',
  authDomain: 'pream-84592.firebaseapp.com',
  databaseURL: 'https://pream-84592.firebaseio.com',
  projectId: 'pream-84592',
  storageBucket: 'pream-84592.appspot.com',
  messagingSenderId: '937651972989',
  appId: '1:937651972989:web:53ec29ff615ccced92fa14',
  measurementId: 'G-6738LW23DN',
};

export default firebaseConfig;
