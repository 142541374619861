import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
  Typography,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  InputAdornment,
  FormHelperText,
  Box,
} from '@material-ui/core';

import { MODAL_DATA, MODAL_TITLE } from 'constants/signup';
import { MAX_LENGTH_FIELDS } from 'constants/settings';

import { signupInfluencerAction } from 'redux/userDuck';
import ActionModal from 'components/feedback/ActionModal';

// Analytics
import firebase from 'firebase/app';
import 'firebase/analytics';

const useStyles = makeStyles({
  groupLabel: {
    marginBottom: 16,
  },
  influencerForm: {
    marginTop: 40,
    marginBottom: 40,
  },
});

/* eslint-disable react/jsx-no-duplicate-props */
function InfluencerSignup({ onSubmit, sessionData }) {
  const analytics = firebase.analytics();
  const { errors, user } = sessionData;
  const classes = useStyles();
  const journalistFields = ['workplace', 'press1', 'press2'];
  const [formErrors, setFormErrors] = useState({});
  const [values, setValues] = useState({
    name: '',
    email: '',
    type: 'influencer',
    twitter: '',
    instagram: '',
    tiktok: '',
    workplace: '',
    press1: '',
    press2: '',
  });

  const handleChange = (prop) => (event) => {
    const newVal = event.target.value;
    if (prop === 'type' && newVal !== 'brands') {
      setValues({ workplace: '', press1: '', press2: '' });
    } else if (newVal === '') {
      setFormErrors({
        ...formErrors,
        [prop]: 'This field is required.',
      });
    } else {
      setFormErrors({ ...formErrors, [prop]: '' });
    }
    if (prop === 'type') {
      setFormErrors({});
    }
    setValues({ ...values, [prop]: newVal });
  };

  const handleSubmit = () => {
    setFormErrors({});
    onSubmit(values);

    analytics.logEvent('influencer_sign_up', {
      influencer_type: values.type,
    });
  };

  const validateFields = () => {
    let formHasErrors = false;
    Object.keys(values).forEach((key) => {
      // check for empty fields
      const journalistError =
        values.type === 'journalist' &&
        values[key] === '' &&
        !['twitter', 'instagram', 'tiktok'].includes(key);

      const influencerError =
        values.type !== 'brands' &&
        values[key] === '' &&
        key !== 'twitter' &&
        key !== 'tiktok' &&
        !journalistFields.includes(key);
      if (journalistError || influencerError) {
        formHasErrors = true;
        setFormErrors((prevState) => ({
          ...prevState,
          [key]: 'This field is required.',
        }));
      }
    });
    if (!formHasErrors) handleSubmit();
  };

  const handleConfirm = () => {
    window.location = '/login';
  };

  const isCompleteUser = () => {
    return (
      user?.profile?.type === 'influencer' ||
      user?.profile?.workplace?.length > 0
    );
  };

  return (
    <>
      <Box pb={4}>
        <Typography gutterBottom variant="h4" component="h2" align="center">
          SIGN UP AS MEDIA
        </Typography>

        <Typography gutterBottom variant="body1" component="h4" align="center">
          Great to have you here!
        </Typography>

        <Typography gutterBottom variant="body1" component="h4" align="center">
          Pream is free for all influencers and journalists
        </Typography>

        <form className={classes.influencerForm}>
          <Typography gutterBottom variant="h6" component="h3" align="center">
            Contact Info
          </Typography>

          <TextField
            onChange={handleChange('name')}
            autoComplete="off"
            fullWidth
            color="secondary"
            inputProps={{
              maxLength: MAX_LENGTH_FIELDS.name,
            }}
            id="name"
            label="Your Name"
            variant="filled"
            error={Boolean(formErrors.name) || Boolean(errors.name)}
            helperText={formErrors.name || errors.name}
          />

          <TextField
            onChange={handleChange('email')}
            autoComplete="off"
            fullWidth
            color="secondary"
            inputProps={{
              maxLength: MAX_LENGTH_FIELDS.email,
            }}
            id="email"
            label="Your Email"
            variant="filled"
            type="email"
            error={Boolean(formErrors.email) || Boolean(errors.email)}
            helperText={formErrors.email || errors.email}
          />

          <Typography gutterBottom variant="h6" component="h3" align="center">
            Career
          </Typography>

          <FormControl component="fieldset">
            <RadioGroup
              aria-label="type"
              value={values.type}
              onChange={handleChange('type')}
            >
              <FormControlLabel
                value="journalist"
                control={<Radio />}
                label="I'm a Journalist"
              />
              <FormControlLabel
                value="influencer"
                control={<Radio />}
                label="I'm an Influencer"
              />
            </RadioGroup>
          </FormControl>

          {values.type === 'journalist' ? (
            <>
              <TextField
                onChange={handleChange('workplace')}
                name="work"
                autoComplete="off"
                fullWidth
                color="secondary"
                id="work"
                inputProps={{
                  maxLength: MAX_LENGTH_FIELDS.name,
                }}
                label="Working at:"
                variant="filled"
                error={
                  Boolean(formErrors.workplace) || Boolean(errors.workplace)
                }
                helperText="Type 'Freelance' if you are one ;)"
              />

              <Typography
                className={classes.groupLabel}
                variant="overline"
                component="h4"
                align="center"
              >
                Please add links to examples of your work:
              </Typography>

              <TextField
                onChange={handleChange('press1')}
                autoComplete="off"
                fullWidth
                color="secondary"
                inputProps={{
                  maxLength: MAX_LENGTH_FIELDS.press,
                }}
                id="press_1"
                label="Press piece #1"
                variant="filled"
                error={Boolean(formErrors.press1) || Boolean(errors.press1)}
                helperText={formErrors.press1 || errors.press1}
              />

              <TextField
                onChange={handleChange('press2')}
                autoComplete="off"
                fullWidth
                inputProps={{
                  maxLength: MAX_LENGTH_FIELDS.press,
                }}
                color="secondary"
                id="press_2"
                label="Press piece #2"
                variant="filled"
                error={Boolean(formErrors.press2) || Boolean(errors.press2)}
                helperText={formErrors.press2 || errors.press2}
              />
            </>
          ) : null}

          <Typography gutterBottom variant="h6" component="h3" align="center">
            Social Media
          </Typography>

          <TextField
            onChange={handleChange('instagram')}
            autoComplete="off"
            fullWidth
            color="secondary"
            inputProps={{
              maxLength: MAX_LENGTH_FIELDS.socialNetwork,
            }}
            label="Instagram handle"
            id="instagramHandle"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">@</InputAdornment>
              ),
            }}
            variant="filled"
            error={Boolean(formErrors.instagram) || Boolean(errors.instagram)}
            helperText={formErrors.instagram || errors.instagram}
          />

          <TextField
            onChange={handleChange('twitter')}
            autoComplete="off"
            fullWidth
            color="secondary"
            inputProps={{
              maxLength: MAX_LENGTH_FIELDS.socialNetwork,
            }}
            label="Twitter handle"
            id="twitterHandle"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">@</InputAdornment>
              ),
            }}
            variant="filled"
            error={Boolean(formErrors.twitter) || Boolean(errors.twitter)}
            helperText={formErrors.twitter || errors.twitter}
          />

          <TextField
            onChange={handleChange('tiktok')}
            autoComplete="off"
            fullWidth
            color="secondary"
            inputProps={{
              maxLength: MAX_LENGTH_FIELDS.socialNetwork,
            }}
            label="TikTok handle"
            id="tiktokHandle"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">@</InputAdornment>
              ),
            }}
            variant="filled"
            error={Boolean(formErrors.tiktok) || Boolean(errors.tiktok)}
            helperText={formErrors.tiktok || errors.tiktok}
          />

          <Button
            onClick={validateFields}
            variant="contained"
            size="large"
            color="secondary"
            disableElevation
            fullWidth
          >
            Apply now
          </Button>

          <FormHelperText>
            By tapping “Apply Now”, you agree to Pream’s Terms of Services and
            Privacy Policy
          </FormHelperText>
        </form>
      </Box>
      <ActionModal
        open={isCompleteUser() || errors.message}
        title={isCompleteUser() ? MODAL_TITLE.success : MODAL_TITLE.error}
        description={isCompleteUser() ? MODAL_DATA.success : MODAL_DATA.error}
        label="OK"
        onClose={handleConfirm}
        onClick={handleConfirm}
      />
    </>
  );
}

InfluencerSignup.propTypes = {
  onSubmit: PropTypes.func,
  sessionData: PropTypes.objectOf(PropTypes.object),
};

InfluencerSignup.defaultProps = {
  onSubmit: () => {},
  sessionData: {},
};

// map redux state to props of component
const mapStateToProps = (state) => ({
  sessionData: state.sessionData,
});

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (values) => {
    dispatch(signupInfluencerAction(values));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InfluencerSignup);
