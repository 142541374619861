import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import MenuIcon from '@material-ui/icons/Menu';
import NavDrawer from 'components/nav/NavDrawer';
import NavBar from 'components/nav/NavBar';
import connect from 'react-redux/es/connect/connect';

// Helpers
import { generateMenu } from 'helpers/helpers';

const useStyles = makeStyles((theme) => ({
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
}));

/* eslint-disable camelcase */
function MainNav({ children, screenTitle, sessionData }) {
  const classes = useStyles();
  const { user } = sessionData || {};
  const isBrand = user?.profile?.type === 'brands';
  const brand = isBrand ? user?.profile?.brands[0] : null;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box display="flex">
      <NavBar
        screenTitle={screenTitle}
        handleIconClick={handleDrawerToggle}
        isMain
      >
        <MenuIcon />
      </NavBar>

      {user && (
        <NavDrawer
          userAvatar={isBrand ? brand.logo : user.profile_picture}
          userName={isBrand ? brand.name : user.name}
          handleDrawer={handleDrawerToggle}
          open={mobileOpen}
          sections={generateMenu(user.profile?.type)}
          isBrand={isBrand}
        />
      )}

      <Box flexGrow={1}>
        <div className={classes.toolbar} />
        {children}
      </Box>
    </Box>
  );
}

MainNav.propTypes = {
  children: PropTypes.node,
  screenTitle: PropTypes.string,
  sessionData: PropTypes.shape({
    user: PropTypes.objectOf(PropTypes.object),
  }),
};

MainNav.defaultProps = {
  children: <h2>No data to show</h2>,
  screenTitle: '',
  sessionData: {},
};

const mapStateToProps = (state) => ({
  sessionData: state.sessionData,
});

export default connect(mapStateToProps)(MainNav);
