import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import {
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Collapse,
  Divider,
  IconButton,
  Typography,
  Box,
  Grid,
} from '@material-ui/core';

import { ExpandMore } from '@material-ui/icons';
import DataTable from 'components/lists/DataTable';
import { WEEK_DAY_SLUGS } from 'constants/campaigns';

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  locationDataTable: {
    '& td': {
      borderBottom: '3px solid #E5E5E5',
      color: '#606060',
      fontSize: 18,
      padding: '1rem 0',
      '&:first-child': {
        paddingLeft: '2rem',
        paddingRight: '.8rem',
        width: '50%',
      },
      '&:last-child': {
        paddingRight: '2rem',
        paddingLeft: '.8rem',
      },
      '&.MuiTableCell-head': {
        fontWeight: 600,
      },
    },
  },
}));

export default function LocationCard({ location, isBrand, hideContactEmail }) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const history = useHistory();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const contactData = [
    {
      head: 'Address',
      value: location.address,
    },
    {
      head: 'Phone',
      value: location.phone,
    },
    {
      head: 'Contact Person',
      value: location.contact,
    },
  ];

  if (!hideContactEmail) {
    contactData.push({
      head: 'Email',
      value: location.email,
    });
  }

  const hoursData = WEEK_DAY_SLUGS.map((day) => {
    const hours = location.hours.find((h) => h.name.substring(0, 3) === day);
    return {
      head: day,
      value:
        typeof hours === 'undefined'
          ? 'Closed'
          : `${hours.start_time.replace(/\s/g, '')} - ${hours.end_time.replace(
              /\s/g,
              ''
            )}`,
    };
  });

  return (
    <Grid item xs={12}>
      <Card className={classes.root} style={{ paddingBottom: '1rem' }}>
        <CardHeader
          disableTypography="true"
          style={{ padding: '1rem 2rem 1.5rem' }}
          action={
            isBrand ? (
              <Button
                color="secondary"
                aria-label="upload picture"
                component="div"
                onClick={() => history.push(`/profile/location/${location.id}`)}
                style={{
                  fontSize: 18,
                  margin: '0 0 .4rem auto',
                  padding: '.8rem .5rem 0 0',
                  textTransform: 'capitalize',
                  textDecoration: 'underline',
                }}
              >
                Edit location
              </Button>
            ) : null
          }
          title={
            <Typography
              variant="h3"
              component="div"
              className={classes.addCampaignTxt}
              style={{
                color: '#606060',
                fontSize: 24,
                fontWeight: 600,
              }}
            >
              {location.name}
            </Typography>
          }
        />

        <CardContent
          style={{ padding: 0 }}
          className={classes.locationDataTable}
        >
          <DataTable rows={contactData} condensed />
        </CardContent>

        <Divider style={{ height: 3, backgroundColor: '#E5E5E5' }} />

        <CardActions disableSpacing style={{ padding: '8px 1.5rem' }}>
          <Box ml={1}>
            <Typography
              variant="overline"
              style={{
                color: '#606060',
                fontSize: 18,
              }}
            >
              Operation hours
            </Typography>
          </Box>

          <IconButton
            style={{
              border: '1px solid #606060',
              color: '#606060',
              height: '2rem',
              marginRight: '.4rem',
              width: '2rem',
            }}
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show hours"
            color="primary"
          >
            <ExpandMore />
          </IconButton>
        </CardActions>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent
            style={{ padding: 0 }}
            className={classes.locationDataTable}
          >
            <DataTable rows={hoursData} condensed />
          </CardContent>
        </Collapse>
      </Card>
    </Grid>
  );
}

LocationCard.propTypes = {
  location: PropTypes.objectOf(PropTypes.object),
  isBrand: PropTypes.bool,
  hideContactEmail: PropTypes.bool,
};

LocationCard.defaultProps = {
  location: {
    id: 0,
    name: '',
    address: '',
    phone: '',
    email: '',
    contact: '',
    hours: [
      {
        name: '',
        start_time: '',
        end_time: '',
      },
    ],
  },
  isBrand: false,
  hideContactEmail: false,
};
