import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { logoutAction } from 'redux/userDuck';
import connect from 'react-redux/es/connect/connect';

import {
  Button,
  Divider,
  Drawer,
  Hidden,
  Avatar,
  CardHeader,
  List,
  ListItem,
  ListItemText,
  Box,
  Typography,
} from '@material-ui/core';

import DRAWER_WIDTH from 'constants/navigation';
import LogoBlue from 'images/logo-blue.svg';
import Rings from 'images/rings.svg';
import HelpIcon from 'images/help-icon.svg';

const useStyles = makeStyles((theme) => ({
  navLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
    backgroundColor: '#EBC8B3',
  },
  drawerPaperMediaUsr: {
    width: DRAWER_WIDTH,
    backgroundColor: '#CCDDE9',
  },
  drawerLogo: {
    background: `url(${HelpIcon}) no-repeat 215px 30px`,
    marginLeft: theme.spacing(2),
    position: 'absolute',
    bottom: theme.spacing(3),
    '& img': {
      width: '10rem',
    },
    width: '90%',
    [theme.breakpoints.down('xs')]: {
      background: 'transparent',
      bottom: theme.spacing(4),
    },
    '& .MuiTypography-caption': {
      [theme.breakpoints.down('xs')]: {
        paddingLeft: '4rem',
      },
    },
  },
  userNameBox: {
    marginBottom: 15,
  },
  userName: {
    fontSize: 24,
    fontWeight: 600,
  },
  divider: {
    borderBottom: `3px solid #E5E5E5`,
  },
  ringsIcon: {
    marginLeft: 150,
    paddingTop: '2rem',
  },
  helpIcon: {
    marginLeft: 150,
    paddingTop: '4rem',
    width: 30,
  },
}));

function NavDrawer({
  // eslint-disable-next-line react/prop-types
  onLogout,
  window,
  userName,
  userAvatar,
  open,
  handleDrawer,
  sections,
  isBrand,
  sessionData,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();

  const { user } = sessionData || {};

  const handleLogout = (logoutUser) => {
    onLogout(logoutUser);
  };

  const drawer = (
    <div>
      <Box className={classes.ringsIcon}>
        <img src={Rings} alt="Pream" />
      </Box>
      {/* Main navigation whit space */}
      <CardHeader
        avatar={
          <Avatar
            alt={userName}
            src={userAvatar}
            style={{ width: 120, height: 120 }}
          />
        }
        title={
          <Typography
            className={classes.userName}
            variant="caption"
            display="block"
            color="primary"
          >
            {userName}
          </Typography>
        }
        className={classes.userNameBox}
        classes={{
          title: 'MuiTypography-h6',
        }}
      />
      <List>
        <Divider className={classes.divider} />
        {sections.map((section) => (
          <NavLink
            to={`/${section.name.toLowerCase()}`}
            className={classes.navLink}
            key={section.name}
          >
            <ListItem
              button
              key={section.name}
              selected={location.pathname.includes(section.name.toLowerCase())}
              onClick={open ? handleDrawer : () => {}}
            >
              <ListItemText
                primary={
                  <Typography variant="h5" display="block">
                    {section.name}
                  </Typography>
                }
                primaryTypographyProps={{ variant: 'h6' }}
              />
            </ListItem>
          </NavLink>
        ))}
      </List>

      <Box className={classes.drawerLogo}>
        <Box>
          <img src={LogoBlue} alt="Pream" />
        </Box>
        {/* <Box className={classes.helpIcon}>
          <img src={HelpIcon} alt="Pream" />
        </Box> */}
        <Button
          color="primary"
          variant="contained"
          size="medium"
          style={{
            borderRadius: 29,
            boxShadow: 'none',
            fontSize: '1rem',
            fontWeight: 700,
            margin: '1rem 0',
            padding: '.6rem 2rem',
            textTransform: 'capitalize',
          }}
          onClick={() => handleLogout(user)}
        >
          Sign out
        </Button>
        <Typography variant="caption" display="block">
          ©Pream, 2021 | Beta Version
        </Typography>
      </Box>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <nav className={classes.drawer} aria-label="navigation options">
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={open}
          onClose={handleDrawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>

      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: isBrand ? classes.drawerPaper : classes.drawerPaperMediaUsr,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
}

NavDrawer.propTypes = {
  userName: PropTypes.string,
  userAvatar: PropTypes.string,
  open: PropTypes.bool,
  handleDrawer: PropTypes.func,
  sections: PropTypes.arrayOf(PropTypes.object),
  isBrand: PropTypes.bool,
  sessionData: PropTypes.shape({
    user: PropTypes.objectOf(PropTypes.object),
  }),
  onLogout: PropTypes.func,
  window: PropTypes.func,
};

NavDrawer.defaultProps = {
  userName: 'User',
  userAvatar: 'U',
  open: false,
  handleDrawer: () => null,
  sections: [
    {
      name: 'Campaigns',
    },
  ],
  isBrand: false,
  sessionData: {},
  onLogout: () => {},
  window: undefined,
};

// take redux state and put it in the props of this component
const mapStateToProps = (state) => ({
  sessionDat: state.sessionData,
});

const mapDispatchToProps = (dispatch) => ({
  onLogout: (user) => {
    dispatch(logoutAction(user));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NavDrawer);
