import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect, useHistory } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import { loginAction } from 'redux/userDuck';
import { getUserDefaultRoute } from 'helpers/helpers';
import { MAX_LENGTH_FIELDS } from 'constants/settings';

import {
  Container,
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Card,
  CardContent,
} from '@material-ui/core';

import { Visibility, VisibilityOff, ArrowBack } from '@material-ui/icons';
import MarketingNav from 'components/nav/MarketingNav';

const useStyles = makeStyles((theme) => ({
  whiteBg: {
    backgroundColor: '#FFF',
    marginTop: theme.spacing(8),
    height: '45vh',
  },
  centered: {
    marginLeft: 'auto',
    marginTop: 40,
  },
}));

/* eslint-disable react/jsx-no-duplicate-props */
function Login({ onSubmit, sessionData }) {
  const { errors, loggedIn, user } = sessionData;
  const loginRoute = getUserDefaultRoute(user);
  const history = useHistory();
  const classes = useStyles();
  const [formErrors, setFormErrors] = useState({});
  const [values, setValues] = useState({
    password: '',
    showPassword: false,
  });

  const handleBackButton = () => {
    history.goBack();
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleChange = (prop) => (event) => {
    const newVal = event.target.value;
    if (newVal === '') {
      setFormErrors({
        ...formErrors,
        [prop]: 'This field is required.',
      });
    } else {
      setFormErrors({ ...formErrors, [prop]: '' });
    }
    setValues({ ...values, [prop]: newVal });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = () => {
    setFormErrors({});
    onSubmit(values);
  };

  const validateFields = () => {
    let formHasErrors = false;
    Object.keys(values).forEach((key) => {
      // check for empty fields
      if (values[key] === '') {
        formHasErrors = true;
        setFormErrors((prevState) => ({
          ...prevState,
          [key]: 'This field is required.',
        }));
      }
    });
    if (!formHasErrors) handleSubmit();
  };

  return loggedIn && user ? (
    <Redirect to={loginRoute} />
  ) : (
    <>
      <MarketingNav screenTitle="Login" handleClick={handleBackButton}>
        <ArrowBack />
      </MarketingNav>

      <Container className={classes.centered} maxWidth="xs">
        <Typography gutterBottom variant="h4" component="h2" align="center">
          Welcome Back
        </Typography>

        <Box mt={4}>
          <form>
            <TextField
              onChange={handleChange('email')}
              inputProps={{
                maxLength: MAX_LENGTH_FIELDS.email,
              }}
              autoComplete="off"
              fullWidth
              color="secondary"
              id="email"
              label="Email"
              variant="filled"
              type="email"
              error={Boolean(formErrors.email) || Boolean(errors.email)}
              helperText={formErrors.email || errors.email}
            />

            <TextField
              autoComplete="off"
              fullWidth
              color="secondary"
              label="Password"
              id="password"
              type={values.showPassword ? 'text' : 'password'}
              value={values.password}
              onChange={handleChange('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {' '}
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                maxLength: MAX_LENGTH_FIELDS.password,
              }}
              variant="filled"
              error={Boolean(formErrors.password) || Boolean(errors.password)}
              helperText={formErrors.password || errors.password}
            />

            <Button
              onClick={validateFields}
              variant="contained"
              size="large"
              color="secondary"
              disableElevation
              fullWidth
            >
              Log in
            </Button>
          </form>
        </Box>

        <Grid container justify="center">
          <Button size="small" color="secondary" href="/password-reset">
            Forgot password?
          </Button>
        </Grid>
      </Container>

      <Container maxWidth="sm">
        <Card>
          <Box pt={4} pl={6} pb={4} pr={6}>
            <CardContent>
              <Typography variant="h5" component="h2" align="center">
                Don&apos;t have an account yet?
              </Typography>

              <Typography gutterBottom variant="body1" align="center">
                You can get started with pream today.
              </Typography>

              <Button
                variant="outlined"
                size="large"
                disableElevation
                fullWidth
                href="/signup"
              >
                Create Account
              </Button>
            </CardContent>
          </Box>
        </Card>
      </Container>
    </>
  );
}

Login.propTypes = {
  onSubmit: PropTypes.func,
  sessionData: PropTypes.objectOf(PropTypes.object),
};

Login.defaultProps = {
  onSubmit: () => {},
  sessionData: {},
};

// map redux state to props of component
const mapStateToProps = (state) => ({
  sessionData: state.sessionData,
});

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (values) => {
    dispatch(loginAction(values));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
