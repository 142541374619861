import React, { useState } from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { uploadMenuAction, deleteMenuAction } from 'redux/userDuck';

import {
  Container,
  Typography,
  Card,
  CardContent,
  Button,
  Box,
  TextField,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import NavBar from 'components/nav/NavBar';
import ListAction from 'components/lists/ListAction';

import { MAX_LENGTH_FIELDS } from 'constants/settings';

const useStyles = makeStyles((theme) => ({
  profileContainer: {
    marginBottom: theme.spacing(5),
    '& .MuiCard-root': {
      marginBottom: theme.spacing(2),
    },
    condensed: {
      fontSize: '0.875rem',
    },
  },
  fileUpload: {
    display: 'none',
  },
  removeButton: {
    marginRight: 2,
    color: theme.palette.error.main,
  },
  fileUploadButton: {
    marginRight: theme.spacing(1),
  },
  pdfContainer: {
    borderRadius: 6,
    overflow: 'hidden',
  },
  cardLink: {
    color: 'inherit',
    textDecoration: 'none',
  },
  form: {
    marginBottom: 24,
  },
}));

function MenusEdit({ uploadMenu, sessionData, deleteMenu }) {
  const classes = useStyles();
  const history = useHistory();

  const { user, fetching } = sessionData || {};
  const brand = user?.profile?.brands[0];
  const { menus } = brand;

  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState(null);

  const handleBackButton = () => {
    history.goBack();
  };

  const uploadFile = () => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', fileName);

    uploadMenu(formData, brand.id, fileName);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFileNameChange = (event) => {
    setFileName(event.target.value);
  };

  const handleDelete = (menu) => {
    deleteMenu(menu, brand.id);
  };

  return (
    <>
      <NavBar screenTitle="Menus" handleIconClick={handleBackButton}>
        <CloseIcon />
      </NavBar>

      <Container className={classes.profileContainer} maxWidth="sm">
        <form>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="overline" component="h2">
                Add Menus
              </Typography>

              <TextField
                fullWidth
                color="secondary"
                id="name"
                label="Menu Name"
                variant="filled"
                value={fileName}
                name="name"
                inputProps={{
                  maxLength: MAX_LENGTH_FIELDS.name,
                }}
                onChange={handleFileNameChange}
              />

              <Box
                className={classes.form}
                display="flex"
                justifyContent="space-between"
              >
                <label htmlFor="pdf-upload">
                  <input
                    className={classes.fileUpload}
                    accept="application/pdf"
                    id="pdf-upload"
                    type="file"
                    onChange={handleFileChange}
                  />

                  <Button
                    className={classes.fileUploadButton}
                    variant="outlined"
                    size="small"
                    component="span"
                  >
                    Choose file
                  </Button>
                  {file ? file.name : ''}
                </label>

                <Button
                  className={classes.fileUploadButton}
                  component="span"
                  variant="contained"
                  color="secondary"
                  disabled={!(fileName !== '' && file && !fetching)}
                  onClick={uploadFile}
                >
                  Upload file
                </Button>
              </Box>

              <ListAction
                items={menus}
                type="delete"
                handleDelete={handleDelete}
              />
            </CardContent>
          </Card>
        </form>
      </Container>
    </>
  );
}

MenusEdit.propTypes = {
  sessionData: PropTypes.shape({
    user: PropTypes.object,
    errors: PropTypes.shape({
      brand: PropTypes.shape({
        menu: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        photos: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      }),
    }),
  }),
  uploadMenu: PropTypes.func,
  deleteMenu: PropTypes.func,
};

MenusEdit.defaultProps = {
  sessionData: {},
  uploadMenu: () => {},
  deleteMenu: () => {},
};

const mapStateToProps = (state) => ({
  sessionData: state.sessionData,
});

const mapDispatchToProps = (dispatch) => ({
  uploadMenu: (formData, brandId, fileName) => {
    dispatch(uploadMenuAction(formData, brandId, fileName));
  },
  deleteMenu: (path, brandId) => {
    dispatch(deleteMenuAction(path, brandId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MenusEdit);
