import React from 'react';
import protectedRoutes from 'config';
import dayjs from 'dayjs';
import { WEEK_DAY_SLUGS, WEEK_DAYS } from 'constants/campaigns';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import FacebookIcon from 'images/icons/facebook.svg';
import InstagramIcon from 'images/icons/instagram.svg';
import YouTubeIcon from 'images/icons/youtube.svg';
import TwitterIcon from 'images/icons/twitter.svg';
import TikTokIcon from 'images/icons/tiktok.svg';

// remove unnecessary characters from social media handle
const formatSocialHandle = (handle) => {
  if (!handle) return '';
  return handle.charAt(0) === '@' || handle.charAt(0) === '/'
    ? handle.substr(1)
    : handle;
};

// format social network data
export const formatSocialNetwork = (network) => {
  if (!network) return {};
  let icon;
  let handle;
  let link;
  const formattedHandle = formatSocialHandle(network.handle);

  switch (network.name) {
    case 'instagram':
      icon = InstagramIcon;
      handle = `@${formattedHandle}`;
      link = `https://www.instagram.com/${formattedHandle}`;
      break;
    case 'tiktok':
      icon = TikTokIcon;
      handle = `@${formattedHandle}`;
      link = `https://www.tiktok.com/@${formattedHandle}`;
      break;
    case 'twitter':
      icon = TwitterIcon;
      handle = `@${formattedHandle}`;
      link = `https://www.twitter.com/${formattedHandle}`;
      break;
    case 'youtube':
      icon = YouTubeIcon;
      handle = `/${formattedHandle}`;
      link = `https://www.youtube.com/${formattedHandle}`;
      break;
    default:
      icon = FacebookIcon;
      handle = `/${formattedHandle}`;
      link = `https://www.facebook.com/${formattedHandle}`;
  }
  return { icon, handle, link };
};

// get file previews from an array of File objects or vanilla Objects with a path property.
export const getFilePreviews = (fileArr) => {
  if (fileArr?.length > 0) {
    return fileArr.map((file) => ({
      ...file,
      preview: file instanceof File ? URL.createObjectURL(file) : file.path,
    }));
  }
  return null;
};

export const generateMenu = (userType) => {
  const sections = [
    {
      name: 'Dashboard',
    },
    {
      name: 'Campaigns',
    },
    {
      name: 'Bookings',
    },
    userType !== 'brands' && {
      name: 'Tasks',
    },
    {
      name: 'Profile',
      icon: <AccountCircleIcon />,
    },
  ];

  return sections.filter((section) => {
    return section || null;
  });
};

export const getSessionData = () => {
  let sessionData = localStorage.getItem('sessionData');
  sessionData = JSON.parse(sessionData);
  if (sessionData) return sessionData;
  return null;
};

export const saveSession = (data) => {
  localStorage.setItem('sessionData', JSON.stringify(data));
};

// checks if user type (influencer, journalist, brands) authorized to access route
export const isTypeAuthorized = (user, path) => {
  if (!user) return false;
  const userType = user.profile?.type;
  return !protectedRoutes[userType].includes(path);
};

// sets default routes by user type
export const getUserDefaultRoute = (user) => {
  if (!user) return '';
  const type = user.profile?.type;
  if (type === 'journalist' || type === 'influencer') {
    return '/campaigns';
  }
  if (type === 'brands') {
    return user.status === 'inexistent' ? '/plans' : '/campaigns';
  }
  return '';
};

export const removeTimeZone = (stringDate) => {
  const arrayDate = stringDate.split('T');
  let formattedDate = '';

  if (arrayDate.length > 1) {
    arrayDate.pop();
    formattedDate = arrayDate.join('-');
  }
  return formattedDate !== '' ? formattedDate : stringDate;
};

export const formatDate = (date) => {
  return dayjs(removeTimeZone(date)).format('MM/DD/YY');
};

export const formatDateNextMonth = (date) => {
  return dayjs(removeTimeZone(date)).add('30', 'day').format('MM/DD/YY');
};

export const capitalize = (str) => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const removeLeadingZero = (str) => {
  if (str.charAt(0) === '0') {
    return str.substr(1);
  }
  return str;
};

export const getSlug = (term) => {
  return term.charAt(0).toLowerCase() + term.substring(1, 3);
};

export const getKeyByValue = (obj, val) => {
  return Object.keys(obj).find((key) => obj[key] === val);
};

export const startsWithNum = (str) => {
  return str.match(/^\d/);
};

export const formatLocationHours = (days) => {
  if (days.length === 0) return [];
  return days.map((d) => {
    const dayIndex = WEEK_DAY_SLUGS.indexOf(capitalize(d.name)) + 1;
    const dayName = dayIndex === 7 ? 'Sunday' : WEEK_DAYS[dayIndex];
    return {
      ...d,
      name: dayName,
    };
  });
};

export const removeEmptyFields = (obj) => {
  Object.keys(obj).forEach(
    (key) => (obj[key] === '' || obj[key] === null) && delete obj[key]
  );
};

export const fileSizeMB = (size) => (size / 1000000).toFixed(1);
