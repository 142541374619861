import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  getBrandBookingsAction,
  updateBrandBookingsAction,
  updateInfluencerBookingsAction,
  setActiveBookingAction,
  getInfluencerBookingsAction,
} from 'redux/bookingDuck';
import { rateInfluencerAction, clearTaskDataReduxAction } from 'redux/taskDuck';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import Alert from '@material-ui/lab/Alert';
import {
  Grid,
  Snackbar,
  Box,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import BookingCard from 'components/cards/BookingCard';
import RateInfluencer from 'components/forms/RateInfluencer';
import ActionModal from 'components/feedback/ActionModal';
import InfiniteScroll from 'react-infinite-scroll-component';
import EmptyStateImage from 'images/empty.svg';
import ErrorStateImage from 'images/error.svg';
import { capitalize } from 'helpers/helpers';

const useStyles = makeStyles({
  emptyState: {
    marginTop: 80,
    '& > *:not(:first-child)': {
      marginTop: 40,
    },
  },
});

/* eslint-disable camelcase */
function BookingsGrid({
  getBookings,
  bookingData,
  sessionData,
  taskData,
  status,
  updateBooking,
  updateInfluencerBooking,
  setActiveBooking,
  rateVisit,
  clearTask,
}) {
  const classes = useStyles();
  const { user } = sessionData || {};
  const isBrand = user?.profile?.type === 'brands';
  const brandId = isBrand ? user?.profile?.brands[0]?.id : null;
  const bookings = bookingData.bookings[status].data || [];
  const totalPages = bookingData.bookings[status].pages;
  const { influencerRatingUpdated } = taskData;

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [currentBooking, setCurrentBooking] = useState({});
  const [newlyRated, setNewlyRated] = useState([]);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [cancelBookingModalOpen, setCancelBookingModalOpen] = useState(false);
  const [rateFormOpen, setRateFormOpen] = useState(false);
  const [snackbarFields, setSnackbarFields] = useState({
    open: false,
    severity: 'success',
    message: '',
  });

  const handleAcceptClick = (bookingId) => {
    updateBooking({ status: 'accepted' }, brandId, bookingId);
  };

  const handleRejectClick = (bookingId) => {
    setActiveBooking(bookingId);
    setRejectModalOpen(true);
  };

  const handleCancelClick = (bookingId, bookingDate) => {
    const dateOfBooking = dayjs(bookingDate);
    const dateNow = dayjs();

    setActiveBooking(bookingId);

    if (dateOfBooking.diff(dateNow, 'hour') <= 24) {
      setCancelBookingModalOpen(true);
      return;
    }

    updateInfluencerBooking({ status: 'cancelled' }, user.id, bookingId);
  };

  const handleRejectConfirmClick = () => {
    updateBooking({ status: 'declined' }, brandId, bookingData.activeBookingId);
    setRejectModalOpen(false);
    setActiveBooking(0);
  };

  const handleCancelConfirmClick = () => {
    updateInfluencerBooking(
      { status: 'cancelled' },
      user.id,
      bookingData.activeBookingId
    );
    setCancelBookingModalOpen(false);
    setActiveBooking(0);
  };

  const handleRateClick = (booking) => {
    setCurrentBooking(booking);
    setRateFormOpen(true);
  };

  const handleRateSubmit = (rating) => {
    rateVisit(currentBooking.id, rating);
  };

  useEffect(() => {
    // Close modal and update newly rated ids when influencer rating is success
    if (
      influencerRatingUpdated === 'SUCCESS' &&
      Object.keys(currentBooking).length > 0
    ) {
      setNewlyRated([...newlyRated, currentBooking.id]);
      setRateFormOpen(false);
      setCurrentBooking({});
      clearTask();
    }
  }, [
    currentBooking,
    setNewlyRated,
    newlyRated,
    setRateFormOpen,
    setCurrentBooking,
    influencerRatingUpdated,
    clearTask,
  ]);

  const handleClose = (setState) => {
    setState(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarFields({ ...snackbarFields, open: false });
  };

  const getNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    } else {
      setHasMore(false);
    }
  };

  useEffect(() => {
    getBookings(user, status, page, 10);
  }, [getBookings, page, user, status]);

  useEffect(() => {
    if (bookingData.updated === 'SUCCESS') {
      setSnackbarFields({
        open: true,
        severity: 'success',
        message: 'Great! The booking was updated',
      });
    }
    if (bookingData.updated === 'ERROR') {
      setSnackbarFields({
        open: true,
        severity: 'error',
        message: 'Sorry, something went wrong',
      });
    }
  }, [bookingData.updated]);

  return (
    <>
      {bookingData.bookings[status].errors &&
      bookingData.bookings[status].errors.data ? (
        <Box
          className={classes.emptyState}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <img src={ErrorStateImage} alt="error bookings grid" />

          <Typography variant="h6" align="center">
            Sorry, something went wrong.
          </Typography>
        </Box>
      ) : null}

      {bookings.length === 0 && bookingData.fetching ? (
        <Box mt={2} display="flex" justifyContent="center">
          <CircularProgress color="secondary" size={24} />
        </Box>
      ) : null}

      {bookings.length === 0 &&
      !bookingData.fetching &&
      Object.keys(bookingData.bookings[status].errors).length === 0 ? (
        <Box
          className={classes.emptyState}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <img src={EmptyStateImage} alt="empty bookings grid" />

          <Typography variant="h5" align="center">
            No bookings yet.
          </Typography>
        </Box>
      ) : null}

      {bookings.length > 0 ? (
        <Box mb={5}>
          <InfiniteScroll
            dataLength={bookings.length} // This is important field to render the next data
            next={getNext}
            hasMore={hasMore}
            style={{ overflow: 'hidden' }} // Necessary for appropriate scroll behavior on mobile
            loader={
              bookingData.fetching ? (
                <Box mt={2} display="flex" justifyContent="center">
                  <CircularProgress color="secondary" size={24} />
                </Box>
              ) : null
            }
          >
            <Grid container spacing={3}>
              {bookings && isBrand
                ? bookings.map(
                    (booking) =>
                      ({
                        pending: (
                          <BookingCard
                            key={booking.id}
                            id={booking.id}
                            isBrand
                            action="accept"
                            date={booking.booking_date}
                            guests={booking.guests}
                            influencer={booking.influencer?.user?.id}
                            tier={booking.influencer?.tier_range}
                            campaignName={booking.campaign?.name}
                            onAcceptClick={handleAcceptClick}
                            onRejectClick={handleRejectClick}
                            status="pending"
                            location={booking.location?.name}
                            compensation={
                              booking.campaign?.type?.booking.compensation
                            }
                          />
                        ),
                        accepted: (
                          <BookingCard
                            key={booking.id}
                            id={booking.id}
                            isBrand
                            hasPassed
                            avatar={booking?.influencer?.user?.profile_picture}
                            name={booking?.influencer?.user?.name}
                            subheader={capitalize(booking?.influencer?.type)}
                            date={booking.booking_date}
                            guests={booking.guests}
                            influencer={booking.influencer?.user?.id}
                            onRateClick={() => handleRateClick(booking)}
                            compensation={
                              booking.campaign?.type?.booking.compensation
                            }
                            campaignName={booking.campaign?.name}
                            location={booking.location?.name}
                          />
                        ),
                        finished: (
                          <BookingCard
                            key={booking.id}
                            id={booking.id}
                            isBrand
                            action="rate"
                            hasPassed
                            avatar={booking?.influencer?.user?.profile_picture}
                            name={booking?.influencer?.user?.name}
                            subheader="Influencer"
                            date={booking.booking_date}
                            guests={booking.guests}
                            influencer={booking.influencer?.user?.id}
                            allowRating={
                              !booking.review &&
                              newlyRated.indexOf(booking.id) === -1
                            }
                            onRateClick={() => handleRateClick(booking)}
                            status={booking.status}
                          />
                        ),
                        declined: (
                          <BookingCard
                            key={booking.id}
                            id={booking.id}
                            isBrand
                            avatar={booking?.influencer?.user?.profile_picture}
                            name={booking?.influencer?.user?.name}
                            subheader="Influencer"
                            date={booking.booking_date}
                            guests={booking.guests}
                            influencer={booking.influencer?.user?.id}
                            status="Declined"
                          />
                        ),
                        cancelled: (
                          <BookingCard
                            key={booking.id}
                            id={booking.id}
                            isBrand
                            avatar={booking?.influencer?.user?.profile_picture}
                            name={booking?.influencer?.user?.name}
                            subheader="Influencer"
                            date={booking.booking_date}
                            guests={booking.guests}
                            influencer={booking.influencer?.user?.id}
                            status="Cancelled"
                          />
                        ),
                      }[booking.status])
                  )
                : null}

              {bookings && !isBrand
                ? bookings.map((booking) => (
                    <BookingCard // eslint-disable-line
                      id={booking.id}
                      avatar={booking.campaign?.brand?.logo}
                      name={booking.campaign?.brand?.name}
                      subheader={booking.location?.name}
                      date={booking.booking_date}
                      status={booking.status}
                      guests={booking.guests}
                      influencer={booking.influencer?.user?.id}
                      onMediaCancelClick={handleCancelClick}
                    />
                  ))
                : null}
            </Grid>
          </InfiniteScroll>
        </Box>
      ) : null}

      <ActionModal
        decision
        title="Cancel this booking?"
        description="We accept cancellations up to 24hrs in advance of your scheduled visit. For any cancellations or adjustments within the 24hr window, please call the property directly with your request."
        open={cancelBookingModalOpen}
        label="Do not cancel"
        dangerLabel="Yes, alert pream"
        onDangerClick={() => handleCancelConfirmClick()}
        onClose={() => handleClose(setCancelBookingModalOpen)}
        onClick={() => handleClose(setCancelBookingModalOpen)}
      />

      <ActionModal
        decision
        title="Reject this request?"
        description="You are about to reject this booking request. This action can not be undone, do you want to proceed?"
        open={rejectModalOpen}
        label="Do not reject"
        dangerLabel="Yes, reject"
        onDangerClick={() => handleRejectConfirmClick()}
        onClose={() => handleClose(setRejectModalOpen)}
        onClick={() => handleClose(setRejectModalOpen)}
      />

      <RateInfluencer
        influencerName={currentBooking.influencer?.user?.name}
        open={rateFormOpen}
        onClose={() => handleClose(setRateFormOpen)}
        onCancelClick={() => handleClose(setRateFormOpen)}
        onSubmitClick={handleRateSubmit}
        error={
          influencerRatingUpdated === 'SUCCESS' ? '' : influencerRatingUpdated
        }
        fetching={!!taskData.fetching}
      />

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={snackbarFields.open}
        onClose={() => handleSnackbarClose()}
        autoHideDuration={4000}
      >
        <Alert severity={snackbarFields.severity}>
          {snackbarFields.message}
        </Alert>
      </Snackbar>
    </>
  );
}

BookingsGrid.propTypes = {
  getBookings: PropTypes.func,
  sessionData: PropTypes.shape({
    user: PropTypes.objectOf(PropTypes.object),
  }),
  bookingData: PropTypes.shape({
    fetching: PropTypes.bool,
    updated: PropTypes.string,
    activeBookingId: PropTypes.number,
    bookings: PropTypes.shape({
      pending: PropTypes.shape({
        bookings: PropTypes.array,
        fetching: PropTypes.bool,
        pages: PropTypes.number,
      }),
      accepted: PropTypes.shape({
        bookings: PropTypes.array,
        fetching: PropTypes.bool,
        pages: PropTypes.number,
      }),
      'finished,declined,cancelled': PropTypes.shape({
        bookings: PropTypes.array,
        fetching: PropTypes.bool,
        pages: PropTypes.number,
      }),
    }),
  }),
  taskData: PropTypes.shape(PropTypes.object),
  status: PropTypes.string,
  updateBooking: PropTypes.func,
  updateInfluencerBooking: PropTypes.func,
  setActiveBooking: PropTypes.func,
  rateVisit: PropTypes.func,
  clearTask: PropTypes.func,
};

BookingsGrid.defaultProps = {
  getBookings: () => {},
  bookingData: {},
  sessionData: {},
  taskData: {},
  status: '',
  updateBooking: () => {},
  updateInfluencerBooking: () => {},
  setActiveBooking: () => {},
  rateVisit: () => {},
  clearTask: () => {},
};

const mapStateToProps = (state) => ({
  bookingData: state.bookingData,
  sessionData: state.sessionData,
  taskData: state.taskData,
});

const mapDispatchToProps = (dispatch) => ({
  getBookings: (user, status, page, size) => {
    const isBrand = user?.profile?.type === 'brands';
    if (isBrand) {
      dispatch(
        getBrandBookingsAction(user?.profile?.brands[0]?.id, status, page, size)
      );
    } else {
      dispatch(getInfluencerBookingsAction(user?.id, status, page, size));
    }
  },
  updateBooking: (booking, brandId, bookingId) => {
    dispatch(updateBrandBookingsAction(booking, brandId, bookingId));
  },
  updateInfluencerBooking: (booking, influencerId, bookingId) => {
    dispatch(updateInfluencerBookingsAction(booking, influencerId, bookingId));
  },
  setActiveBooking: (booking) => {
    dispatch(setActiveBookingAction(booking));
  },
  rateVisit: (bookingId, rating) => {
    dispatch(rateInfluencerAction(bookingId, rating));
  },
  clearTask: () => {
    dispatch(clearTaskDataReduxAction());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingsGrid);
