import { createMuiTheme } from '@material-ui/core/styles';
import deepOrange from '@material-ui/core/colors/deepOrange';
import indigo from '@material-ui/core/colors/indigo';

const colorBlack = '#141313';
const colorCream = '#F7F5F2';
const colorMerino = '#F7F0EA';
const colorScorpion = '#606060';
const colorBurntSienna = '#EB5757';
const colorCatalinaBlue = '#031873';
// const colorJustRight = '#EBC8B3';
const colorMercury = '#E5E5E5';
const colorCarnation = '#EF4B4E';

// Custom theme, refer to https://material-ui.com/customization/default-theme/ for a list of options
const theme = createMuiTheme({
  palette: {
    common: {
      black: colorBlack,
      white: '#fff',
    },
    background: {
      paper: '#FFF',
      default: colorMerino,
    },
    primary: {
      main: colorCatalinaBlue,
      light: deepOrange[500],
    },
    secondary: {
      main: colorCarnation,
    },
    text: {
      primary: colorBlack,
    },
    action: {
      active: '#434242',
      selected: 'transparent',
    },
  },
  typography: {
    fontFamily: 'Montserrat, -apple-system, Arial',
    h3: {
      fontWeight: 500,
    },
    h4: {
      fontWeight: 500,
    },
    h5: {
      fontWeight: 700,
    },
    button: {
      fontWeight: 550,
      letterSpacing: '0.046875em',
    },
    overline: {
      fontWeight: 600,
    },
    caption: {
      color: colorScorpion,
      fontSize: 16,
    },
  },
  shape: {
    borderRadius: 10,
  },

  // SPECIFIC OVERRIDES START HERE
  overrides: {
    // Layout
    MuiContainer: {
      root: {
        marginTop: 16,
        marginBottom: 40,
        '& .MuiCard-root': {
          marginBottom: 16,
        },
      },
    },
    // Type
    MuiTypography: {
      gutterBottom: {
        marginBottom: 16,
      },
    },

    // Icon
    MuiIconButton: {
      root: {
        color: colorBlack,
      },
    },

    // Paper
    MuiTabs: {
      indicator: {
        backgroundColor: 'transparent',
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: 0,
      },
    },

    MuiTab: {
      root: {
        borderRight: `1px solid #E5E5E5`,
        backgroundColor: 'transparent',
        border: 0,
        color: '#979797',
        fontSize: 18,
        fontWeight: 700,
        textDecoration: 'none',
        padding: '1.5rem 2.8rem',
        textTransform: 'uppercase',
        '&:last-child': {
          borderRight: 0,
        },
        '&:hover': {
          color: colorCarnation,
        },
        '&$selected': {
          backgroundColor: 'transparent',
          color: 'orange',
          '&:active': {
            backgroundColor: 'cyan',
            color: 'green',
          },
        },
      },
    },

    // Button
    MuiButton: {
      containedSizeLarge: {
        padding: 16,
        marginTop: 16,
        marginBottom: 8,
      },
      outlinedSizeLarge: {
        padding: 16,
        marginTop: 16,
      },
      outlinedSizeSmall: {
        paddingLeft: 8,
        paddingRight: 8,
      },
      textSizeSmall: {
        padding: 8,
      },
    },

    MuiToggleButton: {
      root: {
        color: colorBlack,
        '&:hover': {
          backgroundColor: indigo[50],
        },
        '&.Mui-selected': {
          backgroundColor: indigo[100],
        },
      },
    },
    MuiAutocomplete: {
      tag: {
        marginLeft: 0,
        marginTop: 8,
        marginBottom: 8,
      },
    },

    MuiFab: {
      root: {
        boxShadow:
          '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
      },
    },
    MuiSpeedDialAction: {
      staticTooltipLabel: {
        minWidth: '160px',
        textAlign: 'center',
        color: 'white',
        backgroundColor: 'rgba(0,0,0,0.75)',
        boxShadow:
          '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
      },
    },

    MuiChip: {
      sizeSmall: {
        marginLeft: 4,
        marginBottom: 4,
      },
    },

    // Cards
    MuiCard: {
      root: {
        backgroundColor: '#FAFAFA',
        boxShadow:
          '0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2)',
        borderRadius: 12,
      },
    },
    MuiCardContent: {
      root: {
        padding: 24,
      },
    },
    MuiCardHeader: {
      title: {
        fontWeight: 500,
      },
    },
    MuiCardActionArea: {
      root: {
        '&:hover': {
          backgroundColor: colorCream,
        },
      },
    },

    // Lists
    MuiTableContainer: {
      root: {
        marginBottom: 24,
      },
    },
    MuiTableRow: {
      root: {
        '& td:first-child': {
          width: '35%',
        },
      },
    },
    MuiTableCell: {
      root: {
        verticalAlign: 'top',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: '40px',
      },
    },
    MuiGridList: {
      root: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiGridListTile: {
      tile: {
        borderRadius: 8,
        overflow: 'hidden',
      },
    },
    MuiGridListTileBar: {
      root: {
        background:
          'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
      },
    },
    MuiList: {
      padding: {
        paddingRight: 18,
        paddingLeft: 18,
      },
    },
    MuiListItem: {
      root: {
        alignItems: 'top',
        margin: 0,
        paddingLeft: 0,
        paddingRight: 0,
        borderRadius: 0,
        fontWeight: 550,
        color: colorCatalinaBlue,
        borderBottom: `3px solid ${colorMercury}`,
        '&:focus': {
          backgroundColor: 'transparent',
          color: colorCatalinaBlue,
        },
        '&$selected': {
          color: colorBurntSienna,
          backgroundColor: 'transparent',
          '& .MuiListItemIcon-root': {
            color: colorCatalinaBlue,
          },
        },
      },
      gutters: {
        paddingLeft: 0,
        paddingRight: 0,
      },
      selected: {
        backgroundColor: 'transparent',
      },
    },
    MuiStepper: {
      root: {
        background: 'transparent',
        padding: 0,
      },
    },
    MuiStepIcon: {
      root: {
        '&.MuiStepIcon-active': {
          color: colorBlack,
        },
        '&.MuiStepIcon-completed': {
          color: colorBlack,
        },
      },
    },

    // Bars
    MuiAppBar: {
      colorDefault: {
        color: colorScorpion,
        backgroundColor: colorMerino,
      },
    },

    // Date & Time
    MuiPickersStaticWrapper: {
      staticWrapperRoot: {
        borderRadius: 8,
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: colorBlack,
      },
    },
    MuiPickersBasePicker: {
      container: {
        backgroundColor: '#FFF',
      },
      pickerView: {
        maxWidth: '100%',
      },
    },
    // Form
    MuiFormHelperText: {
      root: {
        marginTop: 6,
        textAlign: 'left',
      },
    },
    MuiFormControl: {
      root: {
        marginBottom: 16,
        width: '100%',
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: 'rgba(137,120,118,0.12)',
        '&.Mui-focused': {
          backgroundColor: '#FFF',
        },
      },
    },
    MuiFormControlLabel: {
      labelPlacementStart: {
        marginLeft: 0,
      },
    },
    MuiFormLabel: {
      root: {
        '&.Mui-focused': {
          color: indigo[700],
        },
      },
    },
    WAMuiChipInput: {
      underline: {
        '&::after': {
          borderBottom: `2px solid ${indigo[700]}`,
        },
      },
    },
  },
});

// Custom shadows
theme.shadows[12] =
  '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);';
theme.shadows[8] =
  '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)';

export default theme;
