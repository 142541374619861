// A duck is a Redux Reducer Bundle (info: https://github.com/erikras/ducks-modular-redux)
import API from 'api/Api';

// constants
const initialData = {
  fetching: false,
  errors: {},
  campaigns: {
    // Used for influencer/journalist discover feed
    approved: {
      data: [],
      pages: 0,
      errors: {},
    },
    // Used for brand campaign fetch
    'approved,review,draft': {
      data: [],
      pages: 0,
      errors: {},
    },
    expired: {
      data: [],
      pages: 0,
      errors: {},
    },
  },
  campaignDetails: null,
  campaignAvailability: null,
  activeStep: 0,
  notificationSent: {},
};

// Brands
const CREATE = 'CREATE';
const CREATE_SUCCESS = 'CREATE_SUCCESS';
const CREATE_ERROR = 'CREATE_ERROR';
const CLEAR_ERRORS = 'CLEAR_ERRORS';

const GET_CAMPAIGN = 'GET_CAMPAIGN';
const GET_CAMPAIGN_SUCCESS = 'GET_CAMPAIGN_SUCCESS';
const GET_CAMPAIGN_ERROR = 'GET_CAMPAIGN_ERROR';

const GET_CAMPAIGNS = 'GET_CAMPAIGNS';
const GET_CAMPAIGNS_SUCCESS = 'GET_CAMPAIGNS_SUCCESS';
const GET_CAMPAIGNS_ERROR = 'GET_CAMPAIGNS_ERROR';

const GET_HOURS = 'GET_HOURS';
const GET_HOURS_SUCCESS = 'GET_HOURS_SUCCESS';
const GET_HOURS_ERROR = 'GET_HOURS_ERROR';

const UPDATE_FORM_STEP = 'UPDATE_STEP';

const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN';
const UPDATE_CAMPAIGN_SUCCESS = 'UPDATE_CAMPAIGN_SUCCESS';
const UPDATE_CAMPAIGN_ERROR = 'UPDATE_CAMPAIGN_ERROR';

const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN';
const DELETE_CAMPAIGN_SUCCESS = 'DELETE_CAMPAIGN_SUCCESS';
const DELETE_CAMPAIGN_ERROR = 'DELETE_CAMPAIGN_ERROR';

const CLEAR_DRAFT_CAMPAIGN = 'CLEAR_DRAFT_CAMPAIGN';

const DELETE_CAMPAIGN_PHOTO = 'DELETE_CAMPAIGN_PHOTO';
const DELETE_CAMPAIGN_PHOTO_SUCCESS = 'DELETE_CAMPAIGN_PHOTO_SUCCESS';
const DELETE_CAMPAIGN_PHOTO_ERROR = 'DELETE_CAMPAIGN_PHOTO_ERROR';

// Influencers

const GET_CAMPAIGN_DETAILS = 'GET_CAMPAIGN_DETAILS';
const GET_CAMPAIGN_DETAILS_SUCCESS = 'GET_CAMPAIGN_DETAILS_SUCCESS';
const GET_CAMPAIGN_DETAILS_ERROR = 'GET_CAMPAIGN_DETAILS_ERROR';

// Influencers

const SEND_MESSAGE_TO_BRAND = 'SEND_MESSAGE_TO_BRAND';
const SEND_MESSAGE_TO_BRAND_SUCCESS = 'SEND_MESSAGE_TO_BRAND_SUCCESS';
const SEND_MESSAGE_TO_BRAND_ERROR = 'SEND_MESSAGE_TO_BRAND_ERROR';

// reducer
export default function reducer(state = initialData, action) {
  let campaigns;
  let newCampaigns;

  switch (action.type) {
    // Brand-specific actions
    case UPDATE_FORM_STEP:
      return { ...state, activeStep: action.payload };

    case GET_CAMPAIGN:
      return { ...state, fetching: true };
    case GET_CAMPAIGN_SUCCESS:
      return { ...state, fetching: false, campaignDetails: action.payload };
    case GET_CAMPAIGN_ERROR:
      return {
        ...state,
        fetching: false,
        campaignDetails: null,
        errors: action.payload,
      };

    case GET_HOURS:
      return { ...state, fetching: true };
    case GET_HOURS_SUCCESS:
      return {
        ...state,
        fetching: false,
        campaignAvailability: action.payload,
      };
    case GET_HOURS_ERROR:
      return { ...state, fetching: false };

    case CREATE:
      return { ...state, fetching: true };
    case CREATE_SUCCESS:
      return {
        ...state,
        fetching: false,
        campaignDetails: action.payload,
        errors: {},
      };
    case CREATE_ERROR:
      return {
        ...state,
        fetching: false,
        errors: action.payload?.data?.errors || action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        errors: {},
      };
    case UPDATE_CAMPAIGN:
      return { ...state, fetching: true };
    case UPDATE_CAMPAIGN_SUCCESS:
      return { ...state, fetching: false, campaignDetails: action.payload };
    case UPDATE_CAMPAIGN_ERROR:
      return { ...state, fetching: false, errors: action.payload.data?.errors };

    case CLEAR_DRAFT_CAMPAIGN:
      return { ...state, campaignDetails: {}, activeStep: 0 };

    case DELETE_CAMPAIGN:
      return { ...state, fetching: true };
    case DELETE_CAMPAIGN_SUCCESS:
      return { ...state, fetching: false, campaignDetails: null };
    case DELETE_CAMPAIGN_ERROR:
      return { ...state, fetching: false, errors: action.payload.data?.errors };

    case GET_CAMPAIGNS:
      return { ...state, fetching: true };
    case GET_CAMPAIGNS_SUCCESS:
      campaigns = state.campaigns;
      newCampaigns = action.payload.result.data;

      // Remove any new campaigns which already exist.
      campaigns[action.payload.status].data.forEach((campaign) => {
        const i = newCampaigns.findIndex((b) => b.id === campaign.id);
        if (i >= 0) {
          newCampaigns.splice(i, 1);
        }
      });

      campaigns[action.payload.status] = {
        data: [...state.campaigns[action.payload.status].data, ...newCampaigns],
        pages: action.payload.result.last_page,
        errors: {},
      };
      return {
        ...state,
        fetching: false,
        campaigns,
      };
    case GET_CAMPAIGNS_ERROR:
      campaigns = state.campaigns;

      campaigns[action.payload.status] = {
        data: state.campaigns[action.payload.status].data,
        errors: action.payload.errors,
      };
      return {
        ...state,
        fetching: false,
        currentFilter: '',
        campaigns,
      };

    case DELETE_CAMPAIGN_PHOTO:
      return { ...state, fetching: true };
    case DELETE_CAMPAIGN_PHOTO_SUCCESS:
      return { ...state, fetching: false };
    case DELETE_CAMPAIGN_PHOTO_ERROR:
      return { ...state, fetching: false };

    // Influencer-specific actions
    case GET_CAMPAIGN_DETAILS:
      return { ...state, fetching: true };
    case GET_CAMPAIGN_DETAILS_SUCCESS:
      return { ...state, fetching: false, campaignDetails: action.payload };
    case GET_CAMPAIGN_DETAILS_ERROR:
      return { ...state, fetching: false, errors: action.payload };

    case SEND_MESSAGE_TO_BRAND:
      return { ...state, notificationSent: {} };
    case SEND_MESSAGE_TO_BRAND_SUCCESS:
      return {
        ...state,
        notificationSent: {
          data: action.payload,
        },
      };
    case SEND_MESSAGE_TO_BRAND_ERROR:
      return {
        ...state,
        notificationSent: {
          errors: action.payload,
        },
      };
    default:
      return state;
  }
}

// action creators
export const getBrandCampaignsAction = (brandId, status, page, size) => (
  dispatch
) => {
  dispatch({
    type: GET_CAMPAIGNS,
  });
  API.BRANDS.getCampaigns(brandId, status, page, size)
    .then((res) => {
      dispatch({
        type: GET_CAMPAIGNS_SUCCESS,
        payload: {
          status,
          result: res.data,
        },
      });
    })
    .catch((e) => {
      dispatch({
        type: GET_CAMPAIGNS_ERROR,
        payload: {
          status,
          errors: e.response,
        },
      });
    });
};

export const deleteCampaignPhotoAction = (id, path) => (dispatch) => {
  dispatch({
    type: DELETE_CAMPAIGN_PHOTO,
  });
  API.deleteCampaignPhoto(id, path)
    .then((res) => {
      dispatch({
        type: DELETE_CAMPAIGN_PHOTO_SUCCESS,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: DELETE_CAMPAIGN_PHOTO_ERROR,
        payload: e.response,
      });
    });
};

export const updateStepAction = (step) => (dispatch) => {
  dispatch({
    type: UPDATE_FORM_STEP,
    payload: step,
  });
};

export const getCampaignAction = (id) => (dispatch) => {
  dispatch({
    type: GET_CAMPAIGN,
  });
  API.BRANDS.getCampaign(id)
    .then((res) => {
      dispatch({
        type: GET_CAMPAIGN_SUCCESS,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: GET_CAMPAIGN_ERROR,
        payload: e.response,
      });
    });
};

export const getCampaignHoursAction = (userId, campaignId, location) => (
  dispatch
) => {
  dispatch({
    type: GET_HOURS,
  });
  API.INFLUENCERS.getAvailableHours(userId, campaignId, location)
    .then((res) => {
      dispatch({
        type: GET_HOURS_SUCCESS,
        payload: res.data,
      });
    })
    .catch(() => {
      dispatch({
        type: GET_HOURS_ERROR,
      });
    });
};

export const createCampaignAction = (formData) => (dispatch, getState) => {
  dispatch({
    type: CREATE,
  });
  API.createCampaign(formData)
    .then((res) => {
      dispatch({
        type: CREATE_SUCCESS,
        payload: res.data,
      });
      dispatch(getCampaignAction(res.data.campaign?.id));
      const currentStep = getState().campaignData.activeStep;
      dispatch(updateStepAction(currentStep + 1));
    })
    .catch((e) => {
      const res = e?.response || e.toString();
      dispatch({
        type: CREATE_ERROR,
        payload: res,
      });
    });
};

export const updateCampaignAction = (formData, id) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_CAMPAIGN,
  });
  API.updateCampaign(formData, id)
    .then((res) => {
      dispatch({
        type: UPDATE_CAMPAIGN_SUCCESS,
        payload: res.data,
      });
      dispatch(getCampaignAction(res.data.campaign?.id));
      const currentStep = getState().campaignData.activeStep;
      dispatch(updateStepAction(currentStep + 1));
    })
    .catch((e) => {
      dispatch({
        type: UPDATE_CAMPAIGN_ERROR,
        payload: e.response,
      });
    });
};

export const deleteCampaignAction = (id) => (dispatch) => {
  dispatch({
    type: DELETE_CAMPAIGN,
  });
  API.deleteCampaign(id)
    .then(() => {
      dispatch({
        type: DELETE_CAMPAIGN_SUCCESS,
      });
    })
    .catch((e) => {
      dispatch({
        type: DELETE_CAMPAIGN_ERROR,
        payload: e.response,
      });
    });
};

export const getDiscoverCampaignsAction = (page, size) => (
  dispatch,
  getState
) => {
  dispatch({
    type: GET_CAMPAIGNS,
  });
  const { id } = getState().sessionData.user;
  API.INFLUENCERS.getCampaigns(id, page, size)
    .then((res) => {
      dispatch({
        type: GET_CAMPAIGNS_SUCCESS,
        payload: {
          status: 'approved',
          result: res.data,
        },
      });
    })
    .catch((e) => {
      dispatch({
        type: GET_CAMPAIGNS_ERROR,
        payload: {
          status: 'approved',
          errors: e.response,
        },
      });
    });
};

export const getCampaignDetailsAction = (userId, campaignId, userType) => (
  dispatch
) => {
  dispatch({
    type: GET_CAMPAIGN_DETAILS,
  });
  if (userId) {
    API.INFLUENCERS.getCampaignDetails(userId, campaignId, userType)
      .then((res) => {
        dispatch({
          type: GET_CAMPAIGN_DETAILS_SUCCESS,
          payload: res.data,
        });
      })
      .catch(() => {
        dispatch({
          type: GET_CAMPAIGN_DETAILS_ERROR,
          payload: { message: 'Error fetching campaigns.' },
        });
      });
  }
};

export const sendMessageToBrandAction = (userId, brandId, message) => (
  dispatch
) => {
  dispatch({
    type: SEND_MESSAGE_TO_BRAND,
  });
  if (userId) {
    API.INFLUENCERS.contactBrand(userId, brandId, message)
      .then((res) => {
        dispatch({
          type: SEND_MESSAGE_TO_BRAND_SUCCESS,
          payload: res.data,
        });
      })
      .catch(() => {
        dispatch({
          type: SEND_MESSAGE_TO_BRAND_ERROR,
          payload: { message: 'Error sending message.' },
        });
      });
  }
};

export const clearDraftAction = () => (dispatch) => {
  dispatch({
    type: CLEAR_DRAFT_CAMPAIGN,
  });
};

export const clearErrorsAction = () => (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
