import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getFilePreviews, fileSizeMB } from 'helpers/helpers';
import { MAX_SIZE_IMG, ERROR_IMG_SIZE } from 'constants/settings';

import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Grid,
  GridList,
  GridListTile,
  FormHelperText,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    '& .MuiGridListTile-tile': {
      borderRadius: 0,
    },
  },
  gridList: {
    '@media (max-width: 320px)': {
      width: 256,
    },
  },
  fileUpload: {
    display: 'none',
  },
  removeButton: {
    marginRight: 2,
    color: theme.palette.error.main,
  },
  uploadBtn: {
    display: 'flex',
    '& .MuiButtonBase-root': {
      fontSize: 18,
      margin: '1rem 0 .4rem auto',
      padding: 0,
      textTransform: 'capitalize',
      textDecoration: 'underline',
      [theme.breakpoints.down('xs')]: {
        margin: 0,
      },
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      margin: '-1rem 0 1.8rem',
    },
  },
}));

export default function FilesUpload({
  handleChange,
  title,
  buttonLabel,
  formName,
  draftFiles,
  onClearPhotos,
  helpText,
}) {
  const classes = useStyles();
  const [filePreviews, setFilePreviews] = useState(
    getFilePreviews(draftFiles) || []
  );
  const [featuredPhotos, setFeaturedPhotos] = useState(draftFiles || []);

  const [errorImage, setErrorImage] = useState('');

  const handleFileChange = (event) => {
    setErrorImage('');
    const fileList = Array.from(event.target.files);
    fileList.forEach((file) => {
      file.status = 'NEW';
      const sizeMB = fileSizeMB(file.size);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        if (sizeMB <= MAX_SIZE_IMG) {
          setFeaturedPhotos(featuredPhotos.concat(fileList));
        } else {
          setErrorImage(ERROR_IMG_SIZE);
        }
      };
    });

    let mappedFiles = fileList.map((file) => {
      if (fileSizeMB(file.size) <= MAX_SIZE_IMG) {
        return {
          ...file,
          preview: URL.createObjectURL(file),
        };
      }
      return '';
    });

    mappedFiles = mappedFiles.filter((f) => f !== '');
    if (mappedFiles.length > 0) {
      setFilePreviews(filePreviews.concat(mappedFiles));
    }
  };

  const handleRemoveImages = () => {
    onClearPhotos();
    setFilePreviews([]);
    setFeaturedPhotos([]);
  };

  const updateCallback = () => {
    handleChange({ featuredPhotos });
  };

  useEffect(updateCallback, [featuredPhotos]);

  const returnMsgNoImage = () =>
    filePreviews.length === 0 ? 'Please add at least one image' : '';

  return (
    <Card style={{ paddingBottom: '2rem', height: '100%' }}>
      <CardContent style={{ padding: '.4rem 2rem', height: '100%' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <Typography
              variant="overline"
              component="h2"
              style={{ fontSize: 24, color: '#606060' }}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box
              className={classes.uploadBtn}
              alignItems="center"
              justifyContent="flex-end"
            >
              {/* Use MIME types for the accept attribute */}
              {filePreviews.length < 5 ? (
                <label htmlFor={formName}>
                  <input
                    accept="image/png, image/jpeg"
                    className={classes.fileUpload}
                    id={formName}
                    type="file"
                    multiple
                    onChange={handleFileChange}
                  />

                  <Button
                    color="secondary"
                    aria-label="upload picture"
                    component="div"
                  >
                    {buttonLabel}
                  </Button>
                </label>
              ) : null}
            </Box>
          </Grid>
        </Grid>

        {filePreviews.length > 0 ? (
          <Box className={classes.root} mt={2}>
            <GridList className={classes.gridList} cellHeight={180} cols={2}>
              {filePreviews.map((file, index) => (
                <GridListTile key={file.preview} cols={1}>
                  <img src={file.preview} alt={`preview-${index}`} />
                </GridListTile>
              ))}
            </GridList>
          </Box>
        ) : null}

        {filePreviews.length > 0 ? (
          <Button
            color="secondary"
            aria-label="upload picture"
            component="div"
            onClick={handleRemoveImages}
            style={{
              fontSize: 18,
              margin: '2rem 0 .4rem auto',
              padding: 0,
              textTransform: 'capitalize',
              textDecoration: 'underline',
              width: '100%',
            }}
          >
            Clear all
          </Button>
        ) : null}

        <FormHelperText error>
          {errorImage !== '' ? errorImage : returnMsgNoImage()}
        </FormHelperText>
        {helpText ? (
          <FormHelperText size="small">{helpText}</FormHelperText>
        ) : null}
      </CardContent>
    </Card>
  );
}

FilesUpload.propTypes = {
  title: PropTypes.string,
  buttonLabel: PropTypes.string,
  formName: PropTypes.string,
  handleChange: PropTypes.func,
  onClearPhotos: PropTypes.func,
  helpText: PropTypes.string,
  draftFiles: PropTypes.arrayOf(PropTypes.object),
};

FilesUpload.defaultProps = {
  title: 'Images',
  buttonLabel: 'Upload Photos',
  formName: 'image-upload',
  handleChange: () => {},
  onClearPhotos: () => {},
  draftFiles: [],
  helpText: null,
};
