// A duck is a Redux Reducer Bundle (info: https://github.com/erikras/ducks-modular-redux)
import API, { setToken } from 'api/Api';
import {
  saveSession,
  getSessionData,
  removeEmptyFields,
} from 'helpers/helpers';

// check for a valid session for initializing state
let validSession = null;
const sessionData = JSON.parse(localStorage.getItem('sessionData')) || {};
if (sessionData.loginUser) {
  validSession = sessionData;
  // set access token in headers for API calls
  const accessToken = sessionData.loginUser.access_token || '';
  if (accessToken) setToken(accessToken);
} else {
  localStorage.removeItem('sessionData');
}

// constants
const initialData = {
  loggedIn: !!sessionData?.loginUser,
  fetching: false,
  updated: false,
  errors: {
    influencer: {},
    brand: {},
    social: {},
    profile: {},
  },
  user: validSession?.user || null,
  profileUser: {
    data: {},
    errors: {},
  },
  activeLocation: {
    updated: false,
    deleted: false,
  },
};

const SIGNUP = 'SIGNUP';
const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
const SIGNUP_ERROR = 'SIGNUP_ERROR';

const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
const CLEAR_FORGOT_PASSWORD = 'CLEAR_FORGOT_PASSWORD';

const LOGIN = 'LOGIN';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_ERROR = 'LOGIN_ERROR';
const LOGOUT = 'LOGOUT';

const UPDATE_PROFILE = 'UPDATE_PROFILE';
const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR';

const UPDATE_INFLUENCER = 'UPDATE_INFLUENCER';
const UPDATE_INFLUENCER_SUCCESS = 'UPDATE_INFLUENCER_SUCCESS';
const UPDATE_INFLUENCER_ERROR = 'UPDATE_INFLUENCER_ERROR';

const UPDATE_INFLUENCER_PHOTO = 'UPDATE_INFLUENCER_PHOTO';
const UPDATE_INFLUENCER_PHOTO_SUCCESS = 'UPDATE_INFLUENCER_PHOTO_SUCCESS';
const UPDATE_INFLUENCER_PHOTO_ERROR = 'UPDATE_INFLUENCER_PHOTO_ERROR';

const SET_APP_USER = 'SET_APP_USER';
const SET_APP_USER_ERROR = 'SET_APP_USER';

const GET_PROFILE_USER = 'GET_PROFILE_USER';
const GET_PROFILE_USER_SUCCESS = 'GET_PROFILE_USER_SUCCESS';
const GET_PROFILE_USER_ERROR = 'GET_PROFILE_USER_ERROR';

const UPDATE_BRAND = 'UPDATE_BRAND';
const UPDATE_BRAND_FIELD = 'UPDATE_BRAND_FIELD';
const UPDATE_BRAND_FIELD_SUCCESS = 'UPDATE_BRAND_FIELD_SUCCESS';
const UPDATE_BRAND_SUCCESS = 'UPDATE_BRAND_SUCCESS';
const UPDATE_BRAND_FIELD_ERROR = 'UPDATE_BRAND_FIELD_ERROR';

const UPLOAD_MENU = 'UPLOAD_MENU';
const UPLOAD_MENU_SUCCESS = 'UPLOAD_MENU_SUCCESS';
const UPLOAD_MENU_ERROR = 'UPLOAD_MENU_ERROR';
const CLEAR_MENU_PATH = 'CLEAR_MENU_PATH';
const DELETE_MENU = 'DELETE_MENU';
const DELETE_MENU_SUCCESS = 'DELETE_MENU_SUCCESS';
const DELETE_MENU_ERROR = 'DELETE_MENU_ERROR';

const UPDATE_BRAND_SOCIAL = 'UPDATE_BRAND_SOCIAL';
const UPDATE_BRAND_SOCIAL_SUCCESS = 'UPDATE_BRAND_SOCIAL_SUCCESS';
const UPDATE_BRAND_SOCIAL_ERROR = 'UPDATE_BRAND_SOCIAL_ERROR';

const ADD_BRAND_PHOTO_SUCCESS = 'ADD_BRAND_PHOTO_SUCCESS';
const DELETE_BRAND_PHOTO_SUCCESS = 'DELETE_BRAND_PHOTO_SUCCESS';

const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS';
const UPDATE_LOCATION_ERROR = 'UPDATE_LOCATION_ERROR';
const UPDATE_LOCATION = 'UPDATE_LOCATION';

const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS';
const CLEAR_ACTIVE_LOCATION = 'CLEAR_ACTIVE_LOCATION';
const CLEAR_UPDATED = 'CLEAR_UPDATED';
const CLEAR_UPDATED_BRAND_PROFILE = 'CLEAR_UPDATED_BRAND_PROFILE';

// reducer
export default function reducer(state = initialData, action) {
  let newState;
  let brandIndex;
  let socialIndex;
  let locationIndex;

  switch (action.type) {
    case SIGNUP:
      return { ...state, fetching: true };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        fetching: false,
        user: action.payload,
        errors: { influencer: {}, brand: {}, social: {}, profile: {} },
      };
    case SIGNUP_ERROR:
      return { ...state, fetching: false, errors: action.payload.data?.errors };
    case FORGOT_PASSWORD:
      return { ...state, fetching: true };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        fetching: false,
        user: action.payload,
        errors: { influencer: {}, brand: {}, social: {}, profile: {} },
      };
    case FORGOT_PASSWORD_ERROR:
      return { ...state, fetching: false, errors: action.payload.data?.errors };
    case LOGIN:
      return { ...state, fetching: true };
    case LOGIN_SUCCESS:
      saveSession({
        loginUser: action.payload,
      });
      return {
        ...state,
        fetching: false,
        loggedIn: true,
        loginUser: action.payload,
        errors: { influencer: {}, brand: {}, social: {}, profile: {} },
      };
    case LOGIN_ERROR:
      return { ...state, fetching: false, errors: action.payload.data?.errors };
    case LOGOUT:
      return { ...state, user: null, loginUser: null, loggedIn: false };
    case UPDATE_INFLUENCER:
      return { ...state, fetching: true, updated: false };
    case UPDATE_INFLUENCER_SUCCESS:
      newState = {
        ...state,
        fetching: false,
        updated: true,
        user: { ...state.user, ...action.payload },
      };
      saveSession({
        ...getSessionData(),
        user: newState.user,
      });
      return newState;
    case UPDATE_INFLUENCER_ERROR:
      return {
        ...state,
        fetching: false,
        updated: false,
        errors: { ...state.errors, influencer: action.payload },
      };
    case UPDATE_INFLUENCER_PHOTO:
      return { ...state, fetching: true };
    case UPDATE_INFLUENCER_PHOTO_SUCCESS:
      newState = {
        ...state,
        fetching: false,
        updated: true,
        user: { ...state.user, ...action.payload },
      };
      saveSession({
        ...getSessionData(),
        user: newState.user,
      });
      return newState;
    case UPDATE_INFLUENCER_PHOTO_ERROR:
      return {
        ...state,
        fetching: false,
        errors: { ...state.errors, influencer: action.payload },
      };
    case SET_APP_USER:
      newState = {
        ...state,
        user: action.payload?.user
          ? action.payload.user
          : { ...state.user, ...action.payload },
        errors: { influencer: {}, brand: {}, social: {}, profile: {} },
      };
      saveSession({
        ...getSessionData(),
        user: newState.user,
      });
      return newState;
    case SET_APP_USER_ERROR:
      return {
        ...state,
        errors: action.payload,
      };
    case UPDATE_PROFILE:
      return {
        ...state,
        fetching: true,
        updated: false,
        errors: { ...state.errors, profile: {} },
      };
    case UPDATE_PROFILE_SUCCESS:
      newState = {
        ...state,
        fetching: false,
        updated: true,
        user: { ...state.user, ...action.payload.user },
      };
      saveSession({
        ...getSessionData(),
        user: newState.user,
      });
      return newState;
    case UPDATE_PROFILE_ERROR:
      return {
        ...state,
        fetching: false,
        updated: false,
        errors: { ...state.errors, profile: action.payload },
      };
    case GET_PROFILE_USER:
      return { ...state, fetching: true };
    case GET_PROFILE_USER_SUCCESS:
      return {
        ...state,
        fetching: false,
        profileUser: {
          data: action.payload,
        },
      };
    case GET_PROFILE_USER_ERROR:
      return {
        ...state,
        fetching: false,
        profileUser: {
          errors: action.payload,
        },
      };
    case UPDATE_BRAND_FIELD:
      return {
        ...state,
        errors: {
          ...state.errors,
          brand: {
            ...state.errors.brand,
            [action.payload.data.field]: null,
          },
        },
      };
    case UPLOAD_MENU:
      return { ...state, fetching: true };
    case UPLOAD_MENU_SUCCESS:
      return {
        ...state,
        fetching: false,
        user: {
          ...state.user,
          profile: {
            ...state.user.profile,
            brands: Object.assign([], state.user.profile.brands, {
              // eslint-disable-next-line
              [0]: {
                ...state.user.profile.brands[0],
                menus: Object.assign([], state.user.profile.brands[0].menus, {
                  ...state.user.profile.brands[0].menus,
                  [state.user.profile.brands[0].menus.length]: {
                    path: action.payload.menuPath,
                    name: action.payload.fileName,
                  },
                }),
              },
            }),
          },
        },
        errors: {
          ...state.errors,
          brand: {
            ...state.errors.brand,
            menus: null,
          },
        },
      };
    case UPLOAD_MENU_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          brand: {
            ...state.errors.brand,
            menus: action.payload.errorMsg,
          },
        },
      };
    case DELETE_MENU:
      return { ...state };
    case DELETE_MENU_SUCCESS:
      return {
        ...state,
        fetching: false,
        ...state,
        user: {
          ...state.user,
          profile: {
            ...state.user.profile,
            brands: Object.assign([], state.user.profile.brands, {
              // eslint-disable-next-line
              [0]: {
                ...state.user.profile.brands[0],
                menus: state.user.profile.brands[0].menus.filter(
                  (item) => action.payload.path !== item.path
                ),
              },
            }),
          },
        },
        errors: {
          ...state.errors,
          brand: {
            ...state.errors.brand,
            menus: null,
          },
        },
      };
    case DELETE_MENU_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          menu: action.payload.errorMsg,
        },
      };
    case UPDATE_BRAND_FIELD_SUCCESS:
      brandIndex = state.user.profile.brands.findIndex(
        (b) => b.id === action.payload.brandId
      );
      newState = {
        ...state,
        user: {
          ...state.user,
          profile: {
            ...state.user.profile,
            brands: Object.assign([], state.user.profile.brands, {
              [brandIndex]: {
                ...state.user.profile.brands[brandIndex],
                [action.payload.data.field]: action.payload.data.value,
              },
            }),
          },
        },
        errors: {
          ...state.errors,
          brand: {
            ...state.errors.brand,
            [action.payload.data.field]: false,
          },
        },
      };
      saveSession({
        ...getSessionData(),
        user: newState.user,
      });
      return newState;
    case UPDATE_BRAND_SUCCESS:
      return {
        ...state,
        isBrandUpdated: action.payload.isBrandUpdated,
        user: {
          ...state.user,
          profile: {
            ...state.user.profile,
            brands: [
              {
                ...state.user.profile.brands[0],
                ...action.payload.data,
              },
            ],
          },
        },
      };
    case UPDATE_BRAND_FIELD_ERROR:
      return {
        ...state,
        isBrandUpdated: false,
        errors: {
          ...state.errors,
          brand: {
            ...state.errors.brand,
            [action.payload.data.field]: action.payload.errorMsg,
          },
        },
      };
    case UPDATE_BRAND_SOCIAL:
      return {
        ...state,
        errors: {
          ...state.errors,
          social: {
            ...state.errors.social,
            [action.payload.socialNetworkId]: {
              [action.payload.data.field]: null,
            },
          },
        },
      };
    case UPDATE_BRAND_SOCIAL_SUCCESS:
      brandIndex = state.user.profile.brands.findIndex(
        (b) => b.id === action.payload.brandId
      );
      socialIndex = state.user.profile.brands[
        brandIndex
      ].socialnetworks.findIndex(
        (n) => n.id === action.payload.socialNetworkId
      );
      newState = {
        ...state,
        user: {
          ...state.user,
          profile: {
            ...state.user.profile,
            brands: Object.assign([], state.user.profile.brands, {
              [brandIndex]: {
                ...state.user.profile.brands[brandIndex],
                socialnetworks: Object.assign(
                  [],
                  state.user.profile.brands[brandIndex].socialnetworks,
                  {
                    [socialIndex]: {
                      ...state.user.profile.brands[brandIndex].socialnetworks[
                        socialIndex
                      ],
                      [action.payload.data.field]: action.payload.data.value,
                    },
                  }
                ),
              },
            }),
          },
        },
        errors: {
          ...state.errors,
          social: {
            ...state.errors.social,
            [action.payload.socialNetworkId]: {
              [action.payload.data.field]: false,
            },
          },
        },
      };
      saveSession({
        ...getSessionData(),
        user: newState.user,
      });
      return newState;
    case UPDATE_BRAND_SOCIAL_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          social: {
            ...state.errors.social,
            [action.payload.socialNetworkId]: {
              [action.payload.data.field]: action.payload.errorMsg,
            },
          },
        },
      };
    case ADD_BRAND_PHOTO_SUCCESS:
      brandIndex = state.user.profile.brands.findIndex(
        (b) => b.id === action.payload.brandId
      );
      newState = {
        ...state,
        user: {
          ...state.user,
          profile: {
            ...state.user.profile,
            brands: Object.assign([], state.user.profile.brands, {
              [brandIndex]: {
                ...state.user.profile.brands[brandIndex],
                photos: [
                  ...state.user.profile.brands[brandIndex].photos,
                  action.payload.data.value,
                ],
              },
            }),
          },
        },
        errors: {
          ...state.errors,
          photos: false,
        },
      };
      saveSession({
        ...getSessionData(),
        user: newState.user,
      });
      return newState;
    case DELETE_BRAND_PHOTO_SUCCESS:
      brandIndex = state.user.profile.brands.findIndex(
        (b) => b.id === action.payload.brandId
      );
      newState = {
        ...state,
        user: {
          ...state.user,
          profile: {
            ...state.user.profile,
            brands: Object.assign([], state.user.profile.brands, {
              [brandIndex]: {
                ...state.user.profile.brands[brandIndex],
                photos: state.user.profile.brands[brandIndex].photos.filter(
                  (p) => p.id !== action.payload.data.value
                ),
              },
            }),
          },
        },
        errors: {
          ...state.errors,
          photos: false,
        },
      };
      saveSession({
        ...getSessionData(),
        user: newState.user,
      });
      return newState;
    case UPDATE_LOCATION:
      return {
        ...state,
        fetching: true,
        activeLocation: {
          ...state.activeLocation,
          updated: false,
          deleted: false,
        },
        errors: {
          ...state.errors,
          activeLocation: Object.prototype.hasOwnProperty.call(
            action.payload,
            'errors'
          )
            ? action.payload.errors
            : state.errors.activeLocation,
        },
      };
    case UPDATE_LOCATION_SUCCESS:
      brandIndex = state.user.profile.brands.findIndex(
        (b) => b.id === action.payload.brandId
      );
      locationIndex = state.user.profile.brands[brandIndex].locations.findIndex(
        (l) => l.id === action.payload.locationId
      );
      locationIndex =
        locationIndex === -1
          ? state.user.profile.brands[brandIndex].locations.length
          : locationIndex;
      newState = {
        ...state,
        fetching: false,
        activeLocation: {
          ...state.activeLocation,
          data: action.payload.data,
          updated: action.payload.updated,
        },
        user: {
          ...state.user,
          profile: {
            ...state.user.profile,
            brands: Object.assign([], state.user.profile.brands, {
              [brandIndex]: {
                ...state.user.profile.brands[brandIndex],
                locations: Object.assign(
                  [],
                  state.user.profile.brands[brandIndex].locations,
                  {
                    [locationIndex]: action.payload.data,
                  }
                ),
              },
            }),
          },
        },
        errors: {
          ...state.errors,
          activeLocation: Object.prototype.hasOwnProperty.call(
            action.payload,
            'errors'
          )
            ? action.payload.errors
            : state.errors.activeLocation,
        },
      };
      saveSession({
        ...getSessionData(),
        user: newState.user,
      });
      return newState;
    case UPDATE_LOCATION_ERROR:
      return {
        ...state,
        fetching: false,
        errors: {
          ...state.errors,
          activeLocation: {
            ...state.errors.activeLocation,
            ...action.payload.errors,
          },
        },
      };
    case DELETE_LOCATION_SUCCESS:
      brandIndex = state.user.profile.brands.findIndex(
        (b) => b.id === action.payload.brandId
      );
      newState = {
        ...state,
        fetching: false,
        activeLocation: {
          ...state.activeLocation,
          data: action.payload.data,
          deleted: true,
        },
        user: {
          ...state.user,
          profile: {
            ...state.user.profile,
            brands: Object.assign([], state.user.profile.brands, {
              [brandIndex]: {
                ...state.user.profile.brands[brandIndex],
                locations: state.user.profile.brands[
                  brandIndex
                ].locations.filter((l) => l.id !== action.payload.locationId),
              },
            }),
          },
        },
        errors: {
          ...state.errors,
          activeLocation: Object.prototype.hasOwnProperty.call(
            action.payload,
            'errors'
          )
            ? action.payload.errors
            : state.errors.activeLocation,
        },
      };
      saveSession({
        ...getSessionData(),
        user: newState.user,
      });
      return newState;
    case CLEAR_ACTIVE_LOCATION:
      return {
        ...state,
        activeLocation: initialData.activeLocation,
      };
    case CLEAR_MENU_PATH:
      return {
        ...state,
        newMenuPath: null,
      };
    case CLEAR_UPDATED:
      return {
        ...state,
        updated: false,
      };
    case CLEAR_UPDATED_BRAND_PROFILE:
      return {
        ...state,
        isBrandUpdated: false,
      };
    case CLEAR_FORGOT_PASSWORD:
      return {
        ...state,
        user: null,
        errors: initialData.errors,
      };
    default:
      return state;
  }
}

// action creators
export const setAppUserAction = () => (dispatch) => {
  API.getProfile()
    .then((res) => {
      dispatch({
        type: SET_APP_USER,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: SET_APP_USER_ERROR,
        payload: e.response,
      });
    });
};

export const updateProfileAction = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROFILE,
  });
  API.updateProfile(data)
    .then((res) => {
      dispatch({
        type: UPDATE_PROFILE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: UPDATE_PROFILE_ERROR,
        payload: e.response,
      });
    });
};

export const getUserAction = (id) => (dispatch) => {
  dispatch({
    type: GET_PROFILE_USER,
  });
  API.INFLUENCERS.getInfluencer(id)
    .then((res) => {
      dispatch({
        type: GET_PROFILE_USER_SUCCESS,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: GET_PROFILE_USER_ERROR,
        payload: e.response,
      });
    });
};

export const logoutAction = (user) => (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
  API.logout(user).then(() => {
    localStorage.removeItem('sessionData');
  });
};

export const setSubStatusAction = () => (dispatch) => {
  API.SUBSCRIPTIONS.getSubscriptionStatus()
    // eslint-disable-next-line no-unused-vars
    .then((res) => {
      dispatch({
        type: SET_APP_USER,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: SET_APP_USER_ERROR,
        payload: e.response,
      });
    });
};

export const loginAction = (user) => (dispatch) => {
  dispatch({
    type: LOGIN,
  });
  API.login({
    email: user.email,
    password: user.password,
  })
    .then((res) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      setToken(res.data.access_token);
      API.getProfile()
        .then((response) => {
          dispatch({
            type: SET_APP_USER,
            payload: response.data,
          });
          if (response.data.user.profile.type === 'brands') {
            dispatch(setSubStatusAction());
          }
        })
        .catch((e) => {
          dispatch({
            type: SET_APP_USER_ERROR,
            payload: e.response,
          });
        });
    })
    .catch((e) => {
      dispatch({
        type: LOGIN_ERROR,
        payload: e.response
          ? e.response
          : {
              data: {
                errors: {
                  email: [''],
                  password: ['Incorrect email or password.'],
                },
              },
            },
      });
    });
};

export const updateInfluencerAction = (id, user) => (dispatch) => {
  dispatch({
    type: UPDATE_INFLUENCER,
  });
  API.INFLUENCERS.updateInfluencer(id, user)
    .then((res) => {
      dispatch({
        type: UPDATE_INFLUENCER_SUCCESS,
        payload: { ...res.data.influencer },
      });
    })
    .catch((e) => {
      dispatch({
        type: UPDATE_INFLUENCER_ERROR,
        payload: e.response,
      });
    });
};

export const updateInfluencerPhotoAction = (file, influencerId) => (
  dispatch
) => {
  dispatch({
    type: UPDATE_INFLUENCER_PHOTO,
  });
  API.INFLUENCERS.updatePicture(file, influencerId)
    .then((res) => {
      dispatch({
        type: UPDATE_INFLUENCER_PHOTO_SUCCESS,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: UPDATE_INFLUENCER_PHOTO_ERROR,
        payload: e.response,
      });
    });
};

export const signupInfluencerAction = (user) => (dispatch) => {
  dispatch({
    type: SIGNUP,
  });

  removeEmptyFields(user);

  API.INFLUENCERS.signupInfluencer(user)
    .then((res) => {
      dispatch({
        type: SIGNUP_SUCCESS,
        payload: res.data.influencer,
      });
      const { id, profile } = res.data.influencer;
      // if journalist, update with additional fields
      if (profile.type === 'journalist') {
        const { workplace } = user;
        const pressPieces = JSON.stringify([user.press1, user.press2]);
        const newFields = { workplace, press_places: pressPieces };
        dispatch(updateInfluencerAction(id, newFields));
      }
    })
    .catch((e) => {
      dispatch({
        type: SIGNUP_ERROR,
        payload: e.response,
      });
    });
};

export const signupBrandAction = (user) => (dispatch) => {
  dispatch({
    type: SIGNUP,
  });
  API.signupBrand({
    name: user.name,
    email: user.email,
    address: user.address,
    password: user.password,
    phones: user.phone,
    zip: user.zip,
  })
    .then((res) => {
      dispatch({
        type: SIGNUP_SUCCESS,
        payload: res.data,
      });
      dispatch(loginAction(user));
    })
    .catch((e) => {
      dispatch({
        type: SIGNUP_ERROR,
        payload: e.response,
      });
    });
};

export const forgotPasswordAction = (email) => (dispatch) => {
  dispatch({
    type: FORGOT_PASSWORD,
  });
  API.resetPassword({
    email,
  })
    .then((res) => {
      dispatch({
        type: FORGOT_PASSWORD_SUCCESS,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: FORGOT_PASSWORD_ERROR,
        payload: e.response,
      });
    });
};

export const updateBrandFieldAction = (field, brandId) => (dispatch) => {
  dispatch({
    type: UPDATE_BRAND_FIELD,
    payload: {
      brandId,
      data: field,
    },
  });
  API.BRANDS.updateProfileField(field, brandId)
    .then(() => {
      dispatch({
        type: UPDATE_BRAND_FIELD_SUCCESS,
        payload: {
          brandId,
          data: field,
        },
      });
    })
    .catch((e) => {
      dispatch({
        type: UPDATE_BRAND_FIELD_ERROR,
        payload: {
          brandId,
          data: field,
          errorMsg:
            e.response?.status === 422
              ? e.response?.data?.message
              : "Sorry, we couldn't save this",
        },
      });
    });
};

export const updateBrandAction = (fields, brandId) => (dispatch) => {
  dispatch({
    type: UPDATE_BRAND,
    payload: {
      brandId,
      data: fields,
    },
  });
  API.BRANDS.update(fields, brandId)
    .then(() => {
      dispatch({
        type: UPDATE_BRAND_SUCCESS,
        payload: {
          brandId,
          data: fields,
          isBrandUpdated: true,
        },
      });
    })
    .catch((e) => {
      dispatch({
        type: UPDATE_BRAND_FIELD_ERROR,
        payload: {
          brandId,
          data: fields,
          isBrandUpdated: false,
          errorMsg:
            e.response?.status === 422
              ? e.response?.data?.message
              : "Sorry, we couldn't save this",
        },
      });
    });
};

export const updateBrandSocialNetworkAction = (
  field,
  brandId,
  socialNetworkId
) => (dispatch) => {
  dispatch({
    type: UPDATE_BRAND_SOCIAL,
    payload: {
      brandId,
      socialNetworkId,
      data: field,
    },
  });
  API.BRANDS.updateSocialNetwork(field, socialNetworkId)
    .then(() => {
      dispatch({
        type: UPDATE_BRAND_SOCIAL_SUCCESS,
        payload: {
          brandId,
          socialNetworkId,
          data: field,
        },
      });
    })
    .catch((e) => {
      dispatch({
        type: UPDATE_BRAND_SOCIAL_ERROR,
        payload: {
          brandId,
          socialNetworkId,
          data: field,
          errorMsg:
            e.response?.status === 422
              ? e.response?.data?.errors?.message
              : "Sorry, we couldn't save this",
        },
      });
    });
};

export const updateBrandLogoAction = (file, brandId) => (dispatch) => {
  dispatch({
    type: UPDATE_BRAND_FIELD,
    payload: {
      brandId,
      data: {
        field: 'logo',
        value: file,
      },
    },
  });
  API.BRANDS.updateLogo(file, brandId)
    .then((res) => {
      dispatch({
        type: UPDATE_BRAND_FIELD_SUCCESS,
        payload: {
          brandId,
          data: {
            field: 'logo',
            value: res.data.logo,
          },
        },
      });
    })
    .catch((e) => {
      dispatch({
        type: UPDATE_BRAND_FIELD_ERROR,
        payload: {
          brandId,
          data: {
            field: 'logo',
            value: file,
          },
          errorMsg:
            e.response?.status === 422
              ? e.response?.data?.message
              : "Sorry, we couldn't save this",
        },
      });
    });
};

export const addBrandPhotoAction = (file, brandId) => (dispatch) => {
  dispatch({
    type: UPDATE_BRAND_FIELD,
    payload: {
      brandId,
      data: {
        field: 'photos',
        value: file,
      },
    },
  });
  API.BRANDS.addPhoto(file)
    .then((res) => {
      dispatch({
        type: ADD_BRAND_PHOTO_SUCCESS,
        payload: {
          brandId,
          data: {
            field: 'photos',
            value: res.data.photo,
          },
        },
      });
    })
    .catch((e) => {
      dispatch({
        type: UPDATE_BRAND_FIELD_ERROR,
        payload: {
          brandId,
          data: {
            field: 'photos',
            value: file,
          },
          errorMsg:
            e.response?.status === 422
              ? e.response?.data?.message
              : "Sorry, we couldn't save this",
        },
      });
    });
};

export const deleteBrandPhotoAction = (photoId, brandId) => (dispatch) => {
  dispatch({
    type: UPDATE_BRAND_FIELD,
    payload: {
      brandId,
      data: {
        field: 'photos',
        value: photoId,
      },
    },
  });
  API.BRANDS.deletePhoto(photoId)
    .then(() => {
      dispatch({
        type: DELETE_BRAND_PHOTO_SUCCESS,
        payload: {
          brandId,
          data: {
            field: 'photos',
            value: photoId,
          },
        },
      });
    })
    .catch((e) => {
      dispatch({
        type: UPDATE_BRAND_FIELD_ERROR,
        payload: {
          brandId,
          data: {
            field: 'photos',
            value: photoId,
          },
          errorMsg:
            e.response?.status === 422
              ? e.response?.data?.message
              : "Sorry, we couldn't save this",
        },
      });
    });
};

export const uploadMenuAction = (formData, brandId, fileName) => (dispatch) => {
  dispatch({
    type: UPLOAD_MENU,
  });
  API.BRANDS.uploadMenu(formData, brandId)
    .then((res) => {
      dispatch({
        type: UPLOAD_MENU_SUCCESS,
        payload: {
          menuPath: res.data.path,
          fileName,
        },
      });
    })
    .catch((e) => {
      dispatch({
        type: UPLOAD_MENU_ERROR,
        payload: {
          brandId,
          errorMsg:
            e.response?.status === 422
              ? e.response?.data?.message
              : "Sorry, we couldn't save this",
        },
      });
    });
};

export const deleteMenuAction = (path, brandId) => (dispatch) => {
  dispatch({
    type: DELETE_MENU,
  });
  API.BRANDS.deleteMenu(path, brandId)
    .then(() => {
      dispatch({
        type: DELETE_MENU_SUCCESS,
        payload: {
          path,
        },
      });
    })
    .catch((e) => {
      dispatch({
        type: DELETE_MENU_ERROR,
        payload: {
          brandId,
          errorMsg:
            e.response?.status === 422
              ? e.response?.data?.message
              : "Sorry, we couldn't remove it",
        },
      });
    });
};

export const deleteBrandFieldAction = (field, brandId) => (dispatch) => {
  dispatch({
    type: UPDATE_BRAND_FIELD,
    payload: {
      brandId,
      data: field,
    },
  });
  API.BRANDS.deleteProfileField(field, brandId)
    .then((res) => {
      dispatch({
        type: UPDATE_BRAND_FIELD_SUCCESS,
        payload: {
          brandId,
          data: {
            field: field.field,
            value: res.data[field.field],
          },
        },
      });
    })
    .catch((e) => {
      dispatch({
        type: UPDATE_BRAND_FIELD_ERROR,
        payload: {
          brandId,
          data: field,
          errorMsg:
            e.response?.status === 422
              ? e.response?.data?.message
              : "Sorry, we couldn't save this",
        },
      });
    });
};

export const addLocationAction = (location) => (dispatch) => {
  dispatch({
    type: UPDATE_LOCATION,
    payload: {
      data: location,
      errors: null,
    },
  });
  API.addLocation(location)
    .then((res) => {
      dispatch({
        type: UPDATE_LOCATION_SUCCESS,
        payload: {
          locationId: res.data.location.id,
          brandId: res.data.location.brand_id,
          data: res.data.location,
          errors: false,
          updated: true,
        },
      });
    })
    .catch((e) => {
      dispatch({
        type: UPDATE_LOCATION_ERROR,
        payload: {
          locationId: 0,
          data: location,
          errors:
            e.response?.status === 422
              ? e.response?.data?.errors
              : { general: "Sorry, we couldn't save this" },
        },
      });
    });
};

export const getLocationAction = (locationId) => (dispatch) => {
  dispatch({
    type: UPDATE_LOCATION,
    payload: {
      data: locationId,
    },
  });
  API.getLocation(locationId).then((res) => {
    dispatch({
      type: UPDATE_LOCATION_SUCCESS,
      payload: {
        locationId: res.data.id,
        brandId: res.data.brand_id,
        data: res.data,
        updated: false,
      },
    });
  });
};

export const updateLocationAction = (location) => (dispatch) => {
  dispatch({
    type: UPDATE_LOCATION,
    payload: {
      data: location,
      errors: null,
    },
  });
  API.updateLocation(location)
    .then((res) => {
      dispatch({
        type: UPDATE_LOCATION_SUCCESS,
        payload: {
          locationId: res.data.location.id,
          brandId: res.data.location.brand_id,
          data: res.data.location,
          errors: false,
          updated: true,
        },
      });
    })
    .catch((e) => {
      dispatch({
        type: UPDATE_LOCATION_ERROR,
        payload: {
          locationId: location.id,
          data: location,
          errors:
            e.response?.status === 422
              ? e.response?.data?.errors
              : { general: "Sorry, we couldn't save this" },
        },
      });
    });
};

export const deleteLocationAction = (location) => (dispatch) => {
  dispatch({
    type: UPDATE_LOCATION,
    payload: {
      data: location.id,
      errors: null,
    },
  });
  API.deleteLocation(location.id)
    .then(() => {
      dispatch({
        type: DELETE_LOCATION_SUCCESS,
        payload: {
          locationId: location.id,
          brandId: location.brand_id,
          data: null,
          errors: false,
        },
      });
    })
    .catch((e) => {
      dispatch({
        type: UPDATE_LOCATION_ERROR,
        payload: {
          locationId: location.id,
          data: location,
          errors:
            e.response?.status === 422
              ? e.response?.data?.errors
              : { general: "Sorry, we couldn't save this" },
        },
      });
    });
};

export const clearActiveLocationAction = () => (dispatch) => {
  dispatch({
    type: CLEAR_ACTIVE_LOCATION,
  });
};

export const clearUpdatedAction = () => (dispatch) => {
  dispatch({
    type: CLEAR_UPDATED,
  });
};

export const clearUpdatedBrandProfileAction = () => (dispatch) => {
  dispatch({
    type: CLEAR_UPDATED_BRAND_PROFILE,
  });
};

export const clearForgotPasswordAction = () => (dispatch) => {
  dispatch({
    type: CLEAR_FORGOT_PASSWORD,
  });
};

export const clearMenuPathAction = () => (dispatch) => {
  dispatch({
    type: CLEAR_MENU_PATH,
  });
};
