import React, { useState, useEffect } from 'react';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { HELP_TEXT } from 'constants/dropzone';

import {
  Container,
  Box,
  Typography,
  Card,
  CardContent,
  TextField,
  Switch,
} from '@material-ui/core';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import FilesUpload from 'components/forms/FilesUpload';
import PropTypes from 'prop-types';
import { CAMPAIGN_CREATION_HELPER } from 'constants/campaigns';
import { MAX_LENGTH_FIELDS } from 'constants/settings';

import CampaignHeader from './CampaignHeader';

export default function GeneralUpdates({
  onUpdate,
  errors,
  campaignDraft,
  onClearPhotos,
}) {
  // set initial values
  const today = new Date();
  const tomorrow = new Date(today);
  const [values, setValues] = useState({
    name: campaignDraft?.name || '',
    description: campaignDraft?.description || '',
    launchDate: campaignDraft?.launchDate || today,
    endDate: campaignDraft?.endDate || tomorrow.setDate(tomorrow.getDate() + 1),
    switchState: campaignDraft?.switchState || false,
  });

  const handleChange = (prop) => (event) => {
    let newVal = '';
    if (prop === 'launchDate' || prop === 'endDate') {
      newVal = event;
    } else if (prop === 'switchState') {
      newVal = !values.switchState;
    } else {
      newVal = event.target.value;
    }
    setValues({ ...values, [prop]: newVal });
  };

  const updateCallback = () => {
    onUpdate(values);
  };

  useEffect(updateCallback, [values]);

  return (
    <Container maxWidth="sm" style={{ marginTop: '2.5rem' }}>
      <form>
        <CampaignHeader />

        <Card>
          <CardContent>
            <Typography gutterBottom variant="overline" component="h2">
              General Information
            </Typography>

            <Typography gutterBottom variant="body2" component="p">
              {CAMPAIGN_CREATION_HELPER.generalUpdates}
            </Typography>

            <TextField
              fullWidth
              required
              inputProps={{
                maxLength: MAX_LENGTH_FIELDS.name,
              }}
              value={values.name}
              color="secondary"
              id="event-name"
              label="Event Name"
              variant="filled"
              onChange={handleChange('name')}
              error={values.name === '' || Boolean(errors?.name)}
              helperText={
                errors?.name || `${MAX_LENGTH_FIELDS.name} characters max`
              }
            />

            <TextField
              fullWidth
              value={values.description}
              inputProps={{
                maxLength: MAX_LENGTH_FIELDS.description,
              }}
              color="secondary"
              id="description"
              label="Description"
              multiline
              rows={3}
              variant="filled"
              error={values.description === '' || Boolean(errors?.description)}
              helperText={
                errors?.description ||
                `${MAX_LENGTH_FIELDS.description} characters max`
              }
              onChange={handleChange('description')}
              required
            />

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                fullWidth
                disableToolbar
                margin="normal"
                variant="inline"
                format="MM/dd/yyyy"
                id="launch-date"
                label="Launch Date"
                value={values.launchDate}
                onChange={handleChange('launchDate')}
                KeyboardButtonProps={{
                  'aria-label': 'launch date',
                }}
              />
            </MuiPickersUtilsProvider>

            <Box
              mt={2}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="subtitle2" component="h2">
                Would you like this campaign to have an end date?
              </Typography>

              <Switch
                checked={values.switchState}
                onChange={handleChange('switchState')}
                name="addEndDate"
                inputProps={{ 'aria-label': 'add end date' }}
              />
            </Box>

            {values.switchState ? (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  disableToolbar
                  margin="normal"
                  variant="inline"
                  format="MM/dd/yyyy"
                  id="end-date"
                  label="End Date"
                  value={values.endDate}
                  onChange={handleChange('endDate')}
                  KeyboardButtonProps={{
                    'aria-label': 'end date',
                  }}
                />
              </MuiPickersUtilsProvider>
            ) : null}
          </CardContent>
        </Card>

        <FilesUpload
          handleChange={onUpdate}
          draftFiles={campaignDraft?.featuredPhotos}
          errors={errors?.featuredPhotos}
          onClearPhotos={onClearPhotos}
          helpText={HELP_TEXT}
        />
      </form>
    </Container>
  );
}

GeneralUpdates.propTypes = {
  onUpdate: PropTypes.func,
  onClearPhotos: PropTypes.func,
  errors: PropTypes.objectOf(PropTypes.object),
  campaignDraft: PropTypes.shape({
    switchState: PropTypes.bool,
  }),
};

GeneralUpdates.defaultProps = {
  onUpdate: () => {},
  onClearPhotos: () => {},
  errors: {},
  campaignDraft: {},
};
