import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { capitalize, fileSizeMB } from 'helpers/helpers';
import {
  MAX_LENGTH_FIELDS,
  MAX_SIZE_IMG,
  ERROR_IMG_SIZE,
} from 'constants/settings';

import {
  updateInfluencerAction,
  updateInfluencerPhotoAction,
  clearUpdatedAction,
} from 'redux/userDuck';

import {
  Button,
  Card,
  CardContent,
  Container,
  InputAdornment,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';
import AvatarUpload from 'components/forms/AvatarUpload';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import InfluencerDashboard from 'components/screens/InfluencerDashboard';

const useStyles = makeStyles((theme) => ({
  profileContainer: {
    marginBottom: theme.spacing(5),
    '& .MuiCard-root': {
      marginBottom: theme.spacing(2),
    },
  },
  fileUpload: {
    display: 'none',
  },
  fileUploadButton: {
    marginLeft: theme.spacing(1),
  },
}));

/* eslint-disable camelcase */
/* eslint-disable react/jsx-no-duplicate-props */
function InfluencerProfileEdit({
  sessionData,
  updateInfluencer,
  updateInfluencerPhoto,
  clearUpdatedFlag,
}) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { user, errors, updated } = sessionData || {};
  const formErrors = errors.influencer?.data?.errors || {};

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [influencer, setInfluencer] = useState({
    name: user?.name || '',
    bio: user?.profile?.bio || '',
    location: user?.profile?.location || '',
    workplace: user?.profile?.workplace || '',
  });
  const [profilePicture, setProfilePicture] = useState(
    user?.profile_picture || ''
  );
  const [socialNetworks, setSocialNetworks] = useState(
    user?.profile?.socialnetworks || []
  );

  const [errorPhoto, setErrorPhoto] = useState('');

  useEffect(() => {
    if (updated) {
      setOpenSnackbar(true);
    } else {
      setOpenSnackbar(false);
    }
  }, [updated, setOpenSnackbar]);

  const handleSubmit = () => {
    if (
      !profilePicture.size ||
      fileSizeMB(profilePicture?.size) <= MAX_SIZE_IMG
    ) {
      const social = socialNetworks.reduce(
        (obj, key) => ({ ...obj, [key.name]: key.handle }),
        {}
      );

      if (user.profile?.type !== 'brands') {
        delete influencer.workplace;
      }

      updateInfluencer(user.id, { ...influencer, ...social });

      if (profilePicture instanceof File) {
        const formData = new FormData();
        formData.append('file', profilePicture);
        updateInfluencerPhoto(formData, user.id);
      }
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
    clearUpdatedFlag();
  };

  const handleFieldChange = (event) => {
    setInfluencer({ ...influencer, [event.target.name]: event.target.value });
  };

  const handleSocialFieldChange = (event) => {
    const index = socialNetworks.findIndex((n) => n.name === event.target.name);
    setSocialNetworks(
      Object.assign([], socialNetworks, {
        [index]: {
          ...socialNetworks[index],
          handle: event.target.value,
        },
      })
    );
  };

  const handleAvatarChange = (newFile) => {
    setErrorPhoto('');
    setProfilePicture(newFile);
    if (fileSizeMB(newFile.size) > MAX_SIZE_IMG) {
      setErrorPhoto(ERROR_IMG_SIZE);
    }
  };

  const userData = {
    ...influencer,
    id: user.id,
    avatar: profilePicture,
    email: user.email,
  };

  userData.socialNetworks = socialNetworks;

  return (
    <>
      {location.pathname === '/dashboard' ? (
        <InfluencerDashboard influencer={userData} />
      ) : (
        <>
          <Container
            className={classes.profileContainer}
            maxWidth="sm"
            style={{ marginTop: '2.5rem' }}
          >
            <form>
              <Card>
                <CardContent>
                  <Typography gutterBottom variant="overline" component="h2">
                    General Info
                  </Typography>

                  <AvatarUpload
                    avatar={profilePicture}
                    onChange={handleAvatarChange}
                    alt={influencer.name}
                    error={errorPhoto}
                  />

                  <TextField
                    fullWidth
                    color="secondary"
                    id="name"
                    label="Name"
                    variant="filled"
                    value={influencer.name}
                    inputProps={{
                      maxLength: MAX_LENGTH_FIELDS.name,
                    }}
                    name="name"
                    onChange={handleFieldChange}
                    error={formErrors.name && influencer.name === ''}
                    helperText={influencer.name === '' ? formErrors.name : ''}
                  />

                  <TextField
                    fullWidth
                    color="secondary"
                    id="bio"
                    label="Short Bio"
                    multiline
                    rows={3}
                    variant="filled"
                    value={influencer.bio}
                    inputProps={{
                      maxLength: MAX_LENGTH_FIELDS.description,
                    }}
                    name="bio"
                    onChange={handleFieldChange}
                    error={formErrors.bio && influencer.bio === ''}
                    helperText={influencer.bio === '' ? formErrors.bio : ''}
                  />

                  <TextField
                    fullWidth
                    color="secondary"
                    id="location"
                    inputProps={{
                      maxLength: MAX_LENGTH_FIELDS.location,
                    }}
                    label="Location"
                    variant="filled"
                    value={influencer.location}
                    name="location"
                    onChange={handleFieldChange}
                    error={formErrors.location && influencer.location === ''}
                    helperText={
                      influencer.location === '' ? formErrors.location : ''
                    }
                  />

                  {user?.profile?.type === 'journalist' && (
                    <TextField
                      fullWidth
                      color="secondary"
                      id="workplace"
                      label="Affiliation"
                      variant="filled"
                      inputProps={{
                        maxLength: MAX_LENGTH_FIELDS.description,
                      }}
                      multiline
                      rows={3}
                      value={influencer.workplace}
                      name="workplace"
                      onChange={handleFieldChange}
                      error={influencer.workplace === ''}
                      placeholder="If freelance, please list the outlets you have contributed to in the past calendar year"
                      helperText="This field is required."
                    />
                  )}
                </CardContent>
              </Card>

              <Card>
                <CardContent>
                  <Typography gutterBottom variant="overline" component="h2">
                    Social Media
                  </Typography>

                  {socialNetworks.map((network, i) => (
                    <TextField
                      key={i} // eslint-disable-line react/no-array-index-key
                      autoComplete="off"
                      fullWidth
                      color="secondary"
                      label={`${capitalize(network.name)} handle`}
                      id={network.name}
                      inputProps={{
                        maxLength: MAX_LENGTH_FIELDS.socialNetwork,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {network.name === 'instagram' ||
                            network.name === 'twitter' ||
                            network.name === 'tiktok'
                              ? '@'
                              : '/'}
                          </InputAdornment>
                        ),
                      }}
                      variant="filled"
                      value={network.handle ? network.handle : ''}
                      name={network.name}
                      onChange={handleSocialFieldChange}
                      helperText={errors?.social[network.id]?.handle || ''}
                    />
                  ))}
                </CardContent>
              </Card>

              <Button
                variant="outlined"
                size="large"
                color="secondary"
                disableElevation
                fullWidth
                onClick={handleSubmit}
              >
                Save changes
              </Button>

              <Button
                variant="outlined"
                size="large"
                color="secondary"
                disableElevation
                fullWidth
                onClick={() => history.push('/settings/password-change')}
              >
                Change Password
              </Button>
            </form>
          </Container>

          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={openSnackbar}
            autoHideDuration={4000}
            onClose={handleSnackbarClose}
            message="Profile updated!"
          />
        </>
      )}
    </>
  );
}

InfluencerProfileEdit.propTypes = {
  sessionData: PropTypes.shape({
    user: PropTypes.object,
    errors: PropTypes.shape({
      influencer: PropTypes.object,
      social: PropTypes.object,
    }),
  }),
  updateInfluencer: PropTypes.func,
  updateInfluencerPhoto: PropTypes.func,
  clearUpdatedFlag: PropTypes.func,
};

InfluencerProfileEdit.defaultProps = {
  sessionData: {},
  updateInfluencer: () => {},
  updateInfluencerPhoto: () => {},
  clearUpdatedFlag: () => {},
};

const mapStateToProps = (state) => ({
  sessionData: state.sessionData,
});

const mapDispatchToProps = (dispatch) => ({
  updateInfluencer: (id, user) => {
    dispatch(updateInfluencerAction(id, user));
  },
  updateInfluencerPhoto: (file, brandId) => {
    dispatch(updateInfluencerPhotoAction(file, brandId));
  },
  clearUpdatedFlag: () => {
    dispatch(clearUpdatedAction());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InfluencerProfileEdit);
