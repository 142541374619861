import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect, useHistory } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import formatSubscriptionFeatures from 'helpers/subscriptions';
import {
  getSubscriptionPlansAction,
  createSubscriptionAction,
} from 'redux/subscriptionDuck';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';

import {
  Container,
  Typography,
  Card,
  CardContent,
  Box,
  Grid,
  Button,
  Icon,
  FormHelperText,
  CircularProgress,
  useMediaQuery,
} from '@material-ui/core';
import { ArrowBack, Lock } from '@material-ui/icons';

import NavBar from 'components/nav/NavBar';
import FeaturesList from 'components/lists/FeatureList';
import VisaIcon from 'images/icons/card-visa.svg';
import MasterIcon from 'images/icons/card-mastercard.svg';
import AmexIcon from 'images/icons/card-amex.svg';
import DiscoverIcon from 'images/icons/card-discover.svg';

const useStyles = makeStyles((theme) => ({
  cartTitle: {
    fontSize: 18,
    lineHeight: 1.33,
    fontWeight: 500,
    margin: '0 0 17px 0',
  },
  field: {
    display: 'flex',
    alignItems: 'center',
    height: '37px',
    width: '100%',
    paddingRight: '8px',
    paddingLeft: '8px',
    fontWeight: 400,
    fontSize: '14px',
    backgroundColor: '#fff',
    border: '1px solid #E2E2E2',
    borderRadius: '4px',
    fontFamily: 'sans-serif',
    '& > div': {
      width: '100%',
    },
    '&:focus-visible': {
      outline: 'none',
    },
    '@media (min-width: 1400px)': {
      paddingRight: '16px',
      paddingLeft: '16px',
    },
  },
  fieldLabel: {
    display: 'block',
    fontSize: 14,
    lineHeight: 1.71,
    fontWeight: 500,
    marginBottom: 4,
  },
  fieldRow: {
    marginBottom: 9,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  fieldNumber: {
    position: 'relative',
  },
  iconsContainer: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    top: '50%',
    right: '8px',
    transform: 'translateY(-50%)',
    margin: 0,
    '& > *': {
      width: 'auto',
      height: 'auto',
      flexShrink: 0,
    },
    '& img': {
      display: 'block',
    },
    '& > *:not(:first-child)': {
      marginLeft: '6px',
    },
    '@media (min-width: 1400px)': {
      right: '18px',
    },
  },
  spanContainer: {
    fontSize: 16,
    marginBottom: 32,
  },
  planPrice: {
    marginRight: theme.spacing(1),
    textDecorationLine: 'line-through',
  },
}));

// Stripe subscription docs: https://stripe.com/docs/billing/subscriptions/fixed-price
/* eslint-disable camelcase */
function Payment({
  subscriptionData,
  getPlan,
  createSubscription,
  sessionData,
}) {
  const classes = useStyles();
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const mdScreen = useMediaQuery('(max-width:1400px)');
  const xsScreen = useMediaQuery('(max-width:350px)');
  const cardIconWidth = () => {
    if (xsScreen) {
      return 0;
    }
    if (mdScreen) {
      return 20;
    }
    return 32;
  };

  const { user } = sessionData || {};
  const { fetching, subscription } = subscriptionData;
  const paymentError = subscription?.errors?.data?.message;
  const plan = subscriptionData.plans?.data[0] || {};
  const planFormatted = formatSubscriptionFeatures(plan);

  const [cardholderName, setCardholderName] = useState('');
  const [validationErrors, setValidationErrors] = useState([]);
  const [stripeError, setStripeError] = useState('');

  useEffect(() => {
    getPlan();
  }, [getPlan]);

  useEffect(() => {
    setStripeError(paymentError);
  }, [paymentError]);

  const handleBackButton = () => {
    history.goBack();
  };

  const handleNameChange = (event) => {
    setCardholderName(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setStripeError('');
    setValidationErrors([]);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: cardholderName,
      },
    });

    if (error) {
      if (error.type === 'validation_error') {
        setValidationErrors([...validationErrors, error.message]);
      } else if (error.type === 'invalid_request_error') {
        setValidationErrors([
          ...validationErrors,
          'The cardholder name is incomplete.',
        ]);
      }
    } else {
      const paymentMethodId = paymentMethod.id;
      // Create the subscription
      createSubscription(plan.id, paymentMethodId);
    }
  };

  return user?.current_subscription?.plan_id ? (
    <Redirect to="/profile" />
  ) : (
    <>
      <NavBar screenTitle="Checkout" handleIconClick={handleBackButton}>
        <ArrowBack />
      </NavBar>

      <Container maxWidth="md" style={{ marginTop: '3.5rem' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            {/* TO DO: Style Stripe form elements */}
            <form onSubmit={handleSubmit}>
              <Card>
                <CardContent>
                  <h4 className={classes.cartTitle}>Payment Information</h4>

                  <div className={classes.fieldRow}>
                    <span className={classes.fieldLabel}>Cardholder Name</span>

                    <input
                      className={classes.field}
                      type="text"
                      id="name"
                      required
                      onChange={handleNameChange}
                    />
                  </div>

                  <div className={classes.fieldRow}>
                    <span className={classes.fieldLabel}>Card Number</span>

                    <div className={classes.fieldNumber}>
                      <CardNumberElement className={classes.field} />

                      <Box className={classes.iconsContainer} my={2}>
                        <Icon>
                          <img
                            src={VisaIcon}
                            alt="Visa"
                            width={cardIconWidth()}
                            height={21}
                          />
                        </Icon>

                        <Icon>
                          <img
                            src={MasterIcon}
                            alt="MasterCard"
                            width={cardIconWidth()}
                            height={21}
                          />
                        </Icon>

                        <Icon>
                          <img
                            src={AmexIcon}
                            alt="AMEX"
                            width={cardIconWidth()}
                            height={21}
                          />
                        </Icon>

                        <Icon>
                          <img
                            src={DiscoverIcon}
                            alt="Discover"
                            width={cardIconWidth()}
                            height={21}
                          />
                        </Icon>
                      </Box>
                    </div>
                  </div>

                  <div className={classes.fieldRow}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={6}>
                        <span className={classes.spanContainer}>
                          <span className={classes.fieldLabel}>
                            Expiration date
                          </span>

                          <CardExpiryElement className={classes.field} />
                        </span>
                      </Grid>

                      <Grid item xs={6} md={6}>
                        <span className={classes.spanContainer}>
                          <span className={classes.fieldLabel}>
                            Security Code
                          </span>

                          <CardCvcElement className={classes.field} />
                        </span>
                      </Grid>
                    </Grid>
                  </div>
                </CardContent>
              </Card>

              {validationErrors.length > 0
                ? validationErrors.map((err) => (
                    <FormHelperText error>{err}</FormHelperText> // eslint-disable-line react/jsx-indent
                  ))
                : null}

              {stripeError ? (
                <FormHelperText error>{stripeError}</FormHelperText>
              ) : null}

              <Button
                fullWidth
                disableElevation
                color="secondary"
                variant="contained"
                size="large"
                disabled={!stripe || fetching}
                endIcon={fetching ? <CircularProgress size={18} /> : null}
                type="submit"
              >
                Pay now
              </Button>
            </form>

            <Box
              mt={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Lock />
              <Typography variant="subtitle2" align="center">
                Secure payment
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h4">
                  {plan.title ? plan.title : ''}
                </Typography>

                <Box mt={2}>
                  <Typography variant="h6" color="secondary">
                    {`$${plan.price}/mo, then $149*/month`}
                  </Typography>

                  <Typography variant="body2">Renews monthly</Typography>

                  <FeaturesList
                    featuresData={planFormatted.features}
                    condensed
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

Payment.propTypes = {
  subscriptionData: PropTypes.objectOf(PropTypes.object),
  sessionData: PropTypes.objectOf(PropTypes.object),
  getPlan: PropTypes.func,
  createSubscription: PropTypes.func,
};

Payment.defaultProps = {
  subscriptionData: {},
  sessionData: {},
  getPlan: () => {},
  createSubscription: () => {},
};

const mapStateToProps = (state) => ({
  subscriptionData: state.subscriptionData,
  sessionData: state.sessionData,
});

const mapDispatchToProps = (dispatch) => ({
  getPlan: () => {
    dispatch(getSubscriptionPlansAction());
  },
  createSubscription: (planId, paymentId) => {
    dispatch(
      createSubscriptionAction({
        selected_plan: planId,
        payment_method: paymentId,
      })
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
