export const MAX_SIZE_IMG = 10;
export const ERROR_IMG_SIZE = 'The image you are trying to upload exceeds 10MB';
export const MAX_LENGTH_FIELDS = {
  name: 50,
  description: 250,
  email: 100,
  password: 50,
  address: 100,
  zip: 5,
  phone: 15,
  socialNetwork: 50,
  location: 15,
  contact: 50,
  workplace: 50,
  press: 250,
};
