import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import { useHistory } from 'react-router-dom';

import { Paper, Tabs, Tab, Container } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import MarketingNav from 'components/nav/MarketingNav';
import BrandSignup from 'components/forms/BrandSignup';
import InfluencerSignup from 'components/forms/InfluencerSignup';

const useStyles = makeStyles({
  root: {
    marginBottom: 40,
    marginTop: 40,
  },
  centered: {
    marginLeft: 'auto',
  },
});

export default function Signup() {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const history = useHistory();

  const handleBackButton = () => {
    history.goBack();
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <>
      <MarketingNav screenTitle="Sign up" handleClick={handleBackButton}>
        <ArrowBackIcon />
      </MarketingNav>

      <Container className={classes.centered} maxWidth="sm">
        <Container maxWidth="xs">
          <Paper className={classes.root} elevation={8}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary" // this is disabled via theme
              textColor="primary"
              centered
              variant="standard"
            >
              <Tab label="For Brands" />
              <Tab label="For Media" />
            </Tabs>
          </Paper>
        </Container>

        <SwipeableViews
          index={value}
          onChangeIndex={onChangeIndex}
          animateTransitions={false}
        >
          <BrandSignup />
          <InfluencerSignup />
        </SwipeableViews>
      </Container>
    </>
  );
}
