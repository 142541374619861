import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { Paper, Tabs, Tab, Container } from '@material-ui/core';

const useStyles = makeStyles({
  tabsPaper: {
    borderRadius: 29,
    zIndex: 1000,
    boxShadow:
      '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
  },
  tabsContainer: {
    position: 'sticky',
    zIndex: 1000,
    top: 70,
    marginLeft: 'auto',
    marginBottom: 24,
  },
});

export default function FloatingTabs({ tabs, onChange, routeValue }) {
  const classes = useStyles();
  const [value, setValue] = useState(routeValue);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const updateCallback = () => {
    onChange(value);
  };

  useEffect(updateCallback, [value]);

  return (
    <Container className={classes.tabsContainer} maxWidth="sm">
      <Paper className={classes.tabsPaper} variant="outlined">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary" // this is disabled via theme
          textColor="secondary"
          centered
          variant="standard"
        >
          {tabs.map((tab) => {
            if (tab.to) {
              return <Tab label={tab.label} component={Link} to={tab.to} />;
            }
            return <Tab label={tab.label} value={tab.value} />;
          })}
        </Tabs>
      </Paper>
    </Container>
  );
}

FloatingTabs.propTypes = {
  onChange: PropTypes.func,
  tabs: PropTypes.arrayOf(PropTypes.object),
  routeValue: PropTypes.number,
};

FloatingTabs.defaultProps = {
  onChange: () => {},
  tabs: [],
  routeValue: 0,
};
