// A duck is a Redux Reducer Bundle (info: https://github.com/erikras/ducks-modular-redux)
import API from 'api/Api';

// constants
const initialData = {
  fetching: false,
  updated: '',
  activeBookingId: 0,
  // Store newly created booking for BookingRequest
  newBooking: {
    data: {},
    errors: {},
  },
  bookings: {
    pending: {
      data: [],
      pages: 0,
      errors: {},
    },
    accepted: {
      data: [],
      pages: 0,
      errors: {},
    },
    'finished,declined,cancelled': {
      data: [],
      pages: 0,
      errors: {},
    },
  },
};

const CREATE_BOOKING = 'CREATE_BOOKING';
const CREATE_BOOKING_SUCCESS = 'CREATE_BOOKING_SUCCESS';
const CREATE_BOOKING_ERROR = 'CREATE_BOOKING_ERROR';

const CLEAR_BOOKING = 'CLEAR_BOOKING';

const SET_ACTIVE_BOOKING = 'SET_ACTIVE_BOOKING';

const GET_BOOKINGS = 'GET_BOOKINGS';
const GET_BOOKINGS_SUCCESS = 'GET_BOOKINGS_SUCCESS';
const GET_BOOKINGS_ERROR = 'GET_BOOKINGS_ERROR';

const UPDATE_BRAND_BOOKING = 'UPDATE_BRAND_BOOKING';
const UPDATE_BRAND_BOOKING_SUCCESS = 'UPDATE_BRAND_BOOKING_SUCCESS';
const UPDATE_BRAND_BOOKING_ERROR = 'UPDATE_BRAND_BOOKING_ERROR';

const UPDATE_INFLUENCER_BOOKING = 'UPDATE_INFLUENCER_BOOKING';
const UPDATE_INFLUENCER_BOOKING_SUCCESS = 'UPDATE_INFLUENCER_BOOKING_SUCCESS';
const UPDATE_INFLUENCER_BOOKING_ERROR = 'UPDATE_INFLUENCER_BOOKING_ERROR';

// reducer
export default function reducer(state = initialData, action) {
  let bookings;
  let newBookings;
  let updatedBooking;

  const updateBookingCards = () => {
    Object.keys(bookings).forEach((status) => {
      const i = bookings[status].data.findIndex(
        (b) => b.id === updatedBooking.id
      );
      if (i >= 0) {
        bookings[status].data.splice(i, 1);
      }
    });
  };

  switch (action.type) {
    case CREATE_BOOKING:
      return { ...state, fetching: true };

    case CREATE_BOOKING_SUCCESS:
      return {
        ...state,
        fetching: false,
        newBooking: {
          data: action.payload,
          errors: {},
        },
      };

    case CREATE_BOOKING_ERROR:
      return {
        ...state,
        fetching: false,
        newBooking: {
          data: {},
          errors: action.payload.data?.errors,
        },
      };

    case CLEAR_BOOKING:
      return {
        ...state,
        newBooking: {
          data: {},
          errors: {},
        },
      };

    case SET_ACTIVE_BOOKING:
      return { ...state, activeBookingId: action.payload };

    case GET_BOOKINGS:
      return { ...state, fetching: true };

    case GET_BOOKINGS_SUCCESS:
      bookings = state.bookings;
      newBookings = action.payload.result.data;

      // Remove any new bookings which already exist (can happen if the booking's state was updated).
      bookings[action.payload.status].data.forEach((booking) => {
        const i = newBookings.findIndex((b) => b.id === booking.id);
        if (i >= 0) {
          newBookings.splice(i, 1);
        }
      });

      bookings[action.payload.status] = {
        data: [...state.bookings[action.payload.status].data, ...newBookings],
        pages: action.payload.result.last_page,
        errors: {},
      };
      return {
        ...state,
        fetching: false,
        bookings,
      };

    case GET_BOOKINGS_ERROR:
      bookings = state.bookings;
      bookings[action.payload.status] = {
        data: state.bookings[action.payload.status].data,
        errors: action.payload.data?.errors,
      };
      return {
        ...state,
        fetching: false,
        bookings,
      };

    // Brand specific actions
    case UPDATE_BRAND_BOOKING:
      return { ...state, updated: '' };

    case UPDATE_BRAND_BOOKING_SUCCESS:
      bookings = state.bookings;
      updatedBooking = action.payload.booking;

      // Remove it from all statuses
      updateBookingCards();

      // Add to its current status. Note this means that updated bookings are always moved to the top.
      bookings[
        updatedBooking.status === 'declined' ||
        updatedBooking.status === 'cancelled' ||
        updatedBooking.status === 'finished'
          ? 'finished,declined,cancelled'
          : updatedBooking.status
      ].data.unshift(updatedBooking);

      return {
        ...state,
        updated: 'SUCCESS',
        bookings,
      };

    case UPDATE_BRAND_BOOKING_ERROR:
      return { ...state, updated: 'ERROR' };

    case UPDATE_INFLUENCER_BOOKING_SUCCESS:
      bookings = state.bookings;
      updatedBooking = action.payload.booking;

      // Remove it from all statuses
      updateBookingCards();

      return {
        ...state,
        updated: 'SUCCESS',
        bookings,
      };

    case UPDATE_INFLUENCER_BOOKING_ERROR:
      return { ...state, updated: 'ERROR' };

    default:
      return state;
  }
}

// action creators
export const createBookingAction = (booking) => (dispatch) => {
  dispatch({
    type: CREATE_BOOKING,
  });
  API.createBooking(booking)
    .then((res) => {
      dispatch({
        type: CREATE_BOOKING_SUCCESS,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: CREATE_BOOKING_ERROR,
        payload: e.response,
      });
    });
};

export const clearBookingAction = () => (dispatch) => {
  dispatch({
    type: CLEAR_BOOKING,
  });
};

export const setActiveBookingAction = (bookingId) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_BOOKING,
    payload: bookingId,
  });
};

export const getBrandBookingsAction = (brandId, status, page, size) => (
  dispatch
) => {
  dispatch({
    type: GET_BOOKINGS,
  });
  API.BRANDS.getBookings(brandId, status, page, size)
    .then((res) => {
      dispatch({
        type: GET_BOOKINGS_SUCCESS,
        payload: {
          status,
          result: res.data,
        },
      });
    })
    .catch((e) => {
      dispatch({
        type: GET_BOOKINGS_ERROR,
        payload: {
          status,
          errors: e.response,
        },
      });
    });
};

export const updateBrandBookingsAction = (booking, brandId, bookingId) => (
  dispatch
) => {
  dispatch({
    type: UPDATE_BRAND_BOOKING,
  });
  API.BRANDS.updateBooking(booking, brandId, bookingId)
    .then((res) => {
      dispatch({
        type: UPDATE_BRAND_BOOKING_SUCCESS,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: UPDATE_BRAND_BOOKING_ERROR,
        payload: e.response,
      });
    });
};

export const updateInfluencerBookingsAction = (
  booking,
  influencerId,
  bookingId
) => (dispatch) => {
  dispatch({
    type: UPDATE_INFLUENCER_BOOKING,
  });
  API.INFLUENCERS.updateBooking(booking, influencerId, bookingId)
    .then((res) => {
      dispatch({
        type: UPDATE_INFLUENCER_BOOKING_SUCCESS,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: UPDATE_INFLUENCER_BOOKING_ERROR,
        payload: e.response,
      });
    });
};

export const getInfluencerBookingsAction = (
  influencerId,
  status,
  page,
  size
) => (dispatch) => {
  dispatch({
    type: GET_BOOKINGS,
  });
  API.INFLUENCERS.getBookings(influencerId, status, page, size)
    .then((res) => {
      dispatch({
        type: GET_BOOKINGS_SUCCESS,
        payload: {
          status,
          result: res.data,
        },
      });
    })
    .catch((e) => {
      dispatch({
        type: GET_BOOKINGS_ERROR,
        payload: {
          status,
          errors: e.response,
        },
      });
    });
};
