import React from 'react';
import { Container, Typography } from '@material-ui/core';

export default function CampaignHeader() {
  return (
    <Container>
      <Typography gutterBottom variant="h4" component="h2" align="center">
        Let&apos;s get started.
      </Typography>

      <Typography gutterBottom component="p" align="center">
        Tell us about your campaign.
      </Typography>
    </Container>
  );
}
