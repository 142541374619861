import React, { useEffect, useState } from 'react';
import 'date-fns';

import {
  Container,
  Box,
  Typography,
  Card,
  CardContent,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Slider,
  FormHelperText,
  IconButton,
  Tooltip,
} from '@material-ui/core';

import HelpIcon from '@material-ui/icons/Help';
import {
  INFLUENCER_TIERS,
  CAMPAIGN_CREATION_HELPER,
} from 'constants/campaigns';
import PropTypes from 'prop-types';

export default function CampaignAudience({ onUpdate, campaignDraft }) {
  const [values, setValues] = useState({
    tier: campaignDraft?.tier || [1, 2],
    audience: campaignDraft?.audience || {
      influencer: true,
      journalist: false,
    },
  });

  const handleAudienceChange = (event) => {
    // Disable unchecking both boxes
    if (!event.target.checked) {
      if (event.target.name === 'journalist' && !values.audience.influencer)
        return;
      if (event.target.name !== 'brands' && !values.audience.journalist) return;
    }
    setValues({
      ...values,
      audience: {
        ...values.audience,
        [event.target.name]: event.target.checked,
      },
    });
  };

  const handleTierChange = (event, newValue) => {
    setValues({ ...values, tier: newValue });
  };

  const updateCallback = () => {
    onUpdate(values);
  };

  useEffect(updateCallback, [values]);

  const sliderValueText = (value) => `Tier ${value}`;

  return (
    <Container maxWidth="sm" style={{ marginTop: '2.5rem' }}>
      <form>
        <Container align="center">
          <Typography gutterBottom variant="h4" component="h2">
            Who would you like to see and access your Campaign?
          </Typography>

          <Typography gutterBottom component="p">
            Pream promotes your campaign to journalists and/or influencers.
          </Typography>

          <Typography gutterBottom component="p">
            Choose who you would like to get access to.
          </Typography>
        </Container>

        <Card>
          <CardContent>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography gutterBottom variant="overline" component="h2">
                Audience Type
              </Typography>

              <Tooltip
                title="Campaign will only be visible to selected audiences."
                placement="left"
              >
                <IconButton aria-label="more information">
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </Box>

            <Typography gutterBottom variant="body2" component="p">
              {CAMPAIGN_CREATION_HELPER.audience}
            </Typography>

            <Box mt={2}>
              <FormControl>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="secondary"
                        checked={values.audience.influencer}
                        onChange={handleAudienceChange}
                        name="influencer"
                      />
                    }
                    label="Influencer"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        color="secondary"
                        checked={values.audience.journalist}
                        onChange={handleAudienceChange}
                        name="journalist"
                      />
                    }
                    label="Journalist"
                  />
                </FormGroup>
              </FormControl>
            </Box>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="overline" component="h2">
                Audience Tier
              </Typography>

              <Tooltip
                title={
                  <div>
                    Every influencer accepted onto the Pream platform is vetted
                    and has a meaningful reach. The largest and most well-known
                    influencers are classified as Tier 1; smaller nano
                    influencers are classified as Tier 4.
                    <br />
                    <br />
                    Every journalist accepted onto the Pream platform is vetted
                    and has a meaningful reach. National editors with frequent
                    bylines are classified as Tier 1; smaller local writers are
                    classified as Tier 4.
                  </div>
                }
                placement="left"
              >
                <IconButton aria-label="more information">
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </Box>

            <Box pl={1} pr={1}>
              <Slider
                color="secondary"
                value={values.tier}
                getAriaValueText={sliderValueText}
                aria-labelledby="tiers"
                valueLabelDisplay="auto"
                step={1}
                min={1}
                max={4}
                marks={INFLUENCER_TIERS}
                onChange={handleTierChange}
              />
            </Box>

            <FormHelperText>A wide range is recommended</FormHelperText>
          </CardContent>
        </Card>
      </form>
    </Container>
  );
}

CampaignAudience.propTypes = {
  onUpdate: PropTypes.func,
  campaignDraft: PropTypes.shape({
    switchState: PropTypes.bool,
  }),
};

CampaignAudience.defaultProps = {
  onUpdate: () => {},
  campaignDraft: {},
};
