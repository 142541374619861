import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  getTaskAction,
  updateTaskAction,
  updateTaskJournalistAction,
  rateExperienceAction,
} from 'redux/taskDuck';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';

// Components
import {
  CardHeader,
  Avatar,
  Typography,
  Button,
  Container,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Chip,
  Checkbox,
  Box,
  CircularProgress,
  Snackbar,
  TextField,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import NavBar from 'components/nav/NavBar';
import DataTable from 'components/lists/DataTable';
import ActionModal from 'components/feedback/ActionModal';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Rate from 'components/forms/Rating';
import Alert from '@material-ui/lab/Alert';

// Analytics
import firebase from 'firebase/app';
import 'firebase/analytics';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

function TaskDetail({
  taskData,
  sessionData,
  getTask,
  updateTask,
  updateTaskJournalist,
  rateExperience,
}) {
  const analytics = firebase.analytics();
  const classes = useStyles();
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const [rating, setRating] = useState(-1);
  const [complete, setComplete] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [propertyOption, setPropertyOption] = useState(null);

  const postsUrls = [];
  const [postsUrlsState, setPostsUrlsState] = useState([]);

  const [storiesComplete, setStoriesComplete] = useState(0);

  const { user } = sessionData || {};
  const isInfluencer = user?.profile?.type !== 'brands';
  const influencerId = isInfluencer ? user?.id : null;

  const params = useParams();
  const { activeTask } = taskData;
  const taskDetails = activeTask.task || {};
  const locationDetails = activeTask.location || {};
  const campaignDetails = activeTask.campaign || {};

  taskDetails.id = params.id;

  const isJournalist = user.profile.type === 'journalist';

  const handleBackButton = () => {
    history.goBack();
  };

  const validatePostsUrls = () => {
    const postsUrlsFields = document.querySelectorAll('input[name="post_url"');
    const fieldErrorMessages = document.querySelectorAll('.post-url-error');

    if (fieldErrorMessages.length > 0) {
      fieldErrorMessages[0].parentNode.removeChild(fieldErrorMessages[0]);
    }

    const errorMessage = document.createElement('p');
    errorMessage.innerHTML =
      'Please enter a valid URL <i>(e.g. https://www.instagram.com/p/ABC123DEF123/)</i>';
    errorMessage.classList.add('post-url-error');

    for (let i = 0; i < postsUrlsFields.length; i += 1) {
      let isValidUrl = false;

      try {
        Boolean(new URL(postsUrlsFields[i].value));
        isValidUrl = true;
      } catch (e) {
        isValidUrl = false;
      }

      if (postsUrlsFields[i].value === '' || !isValidUrl) {
        postsUrlsFields[i].focus();
        postsUrlsFields[i].parentElement.parentElement.appendChild(
          errorMessage
        );

        return false;
      }

      postsUrls.push(postsUrlsFields[i].value);
    }

    setPostsUrlsState(postsUrls);

    return true;
  };

  const validatePostJournalist = () => {
    return rating && propertyOption;
  };

  // Form handlers
  const handleSubmit = () => {
    const isValidData = isJournalist
      ? validatePostJournalist()
      : validatePostsUrls();

    if (isValidData) {
      setModalOpen(true);
    }
  };

  const handleCheckboxChange = (event) => {
    setComplete(event.target.checked ? 1 : 0);
  };

  const handleStoriesConfirmChange = (event) => {
    setStoriesComplete(event.target.checked ? 1 : 0);
  };

  // Modal handlers
  const handleRejectAction = () => {
    setModalOpen(false);
  };

  const handleConfirmAction = () => {
    let index = 0;

    const updatedTask = {};
    taskDetails.completed = 1;
    taskDetails.geolocalized = 1;
    taskDetails.hashtagged = 1;
    taskDetails.posted = 1;
    taskDetails.tagged = 1;

    if (isJournalist) {
      taskDetails.rating = rating;
      taskDetails.propertyOption = propertyOption;
    }

    if (taskDetails.posts && taskDetails.posts.length > 0) {
      taskDetails.posts.forEach((post) => {
        post.link = 'http://placeholder.com';

        if (post.type === 'post') {
          post.link = postsUrlsState[index]
            ? postsUrlsState[index]
            : 'http://placeholder.com';
          index += 1;
        }
      });
    }

    Object.assign(updatedTask, taskDetails);
    updatedTask.posts = JSON.stringify(updatedTask.posts); // Update fails if we don't stringify this array.
    if (isJournalist === true) {
      updateTaskJournalist(updatedTask, influencerId, taskDetails.id);
    } else {
      updateTask(updatedTask, influencerId, taskDetails.id);
    }

    if (!isJournalist) {
      const bookingRating = user.profile.type === 'journalist' ? 5 : rating;

      const ratingObj = {
        rate_vibe: bookingRating,
        rate_food: bookingRating,
        rate_service: bookingRating,
        rate_general: bookingRating,
      };
      rateExperience(ratingObj, activeTask.id);

      analytics.logEvent('task_completion', {
        rating: `${ratingObj.rate_general} stars`,
      });
    }
    setModalOpen(false);
  };

  useEffect(() => {
    getTask(influencerId, taskDetails.id);
  }, [taskDetails.id, influencerId, getTask, taskDetails.rating]);

  useEffect(() => {
    if (taskData.updated === 'SUCCESS') history.push('/tasks');
    if (taskData.updated === 'ERROR') setSnackbarOpen(true);
  }, [taskData.updated, history]);

  let requirements = [];
  if (Object.prototype.hasOwnProperty.call(taskDetails, 'posts')) {
    requirements = [
      {
        head: 'Profile tagging:',
        value: <CheckCircleIcon />,
      },
      {
        head: 'Location tagging:',
        value: <CheckCircleIcon />,
      },
      {
        head: 'Number of posts:',
        value: taskDetails.posts.filter((item) => {
          return item.type === 'post';
        }).length,
      },
      {
        head: 'Number of stories:',
        value: taskDetails.posts.filter((item) => {
          return item.type === 'story';
        }).length,
      },
      {
        head: 'Use Hashtags:',
        value: campaignDetails.influencer_instructions?.hashtags?.map(
          (hashtag) => <Chip size="small" label={hashtag} />
        ),
      },
    ];
  }

  return (
    <>
      <NavBar screenTitle="Task Details" handleIconClick={handleBackButton}>
        <ArrowBackIcon />
      </NavBar>

      <Container maxWidth="sm">
        {taskData.fetching && (
          <Box mt={2} display="flex" justifyContent="center">
            <CircularProgress color="secondary" size={24} />
          </Box>
        )}

        {Object.prototype.hasOwnProperty.call(taskDetails, 'posts') && (
          <>
            <CardHeader
              avatar={
                <Avatar
                  alt={locationDetails.name}
                  src={campaignDetails.brand.logo}
                />
              }
              title={campaignDetails.brand.name}
              subheader={locationDetails.name}
            />

            {user.profile.type === 'journalist' ? (
              <Container className={classes.formContainer}>
                <Rate onRatingChange={setRating} />
                <Box mb={3} mt={3} pl={2}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      Please select from the following options:
                    </FormLabel>
                    <RadioGroup
                      name="test"
                      defaultValue=""
                      onChange={(event) =>
                        setPropertyOption(event.target.value)
                      }
                    >
                      <FormControlLabel
                        value="I’m planing to include this property in upcoming media coverage."
                        control={<Radio />}
                        label="I’m planing to include this property in upcoming media coverage."
                      />
                      <FormControlLabel
                        value="I’ll be keeping this property in mind for future coverage, but don’t have an immediate fit for it."
                        control={<Radio />}
                        label="I’ll be keeping this property in mind for future coverage, but don’t have an immediate fit for it."
                      />
                      <FormControlLabel
                        value="I don’t plan to cover this property in the near future."
                        control={<Radio />}
                        label="I don’t plan to cover this property in the near future."
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>

                <Button
                  variant="contained"
                  size="large"
                  color="secondary"
                  disableElevation
                  fullWidth
                  disabled={rating === -1 || rating === null || !propertyOption}
                  onClick={handleSubmit}
                >
                  Submit Now
                </Button>
              </Container>
            ) : (
              <>
                <Container className={classes.formContainer}>
                  <Typography gutterBottom variant="h5" component="h1">
                    {campaignDetails.name}
                  </Typography>
                </Container>
                <DataTable tableTitle="Requirements" rows={requirements} />

                <Container className={classes.formContainer}>
                  {requirements[2].value > 0 ? (
                    <Typography gutterBottom variant="h6" component="h2">
                      Posts URLs
                    </Typography>
                  ) : (
                    ''
                  )}

                  {requirements[2].value > 0
                    ? taskDetails.posts.map((post) =>
                        post.type === 'post' ? (
                          <TextField
                            required
                            label="Post URL"
                            type="url"
                            autoComplete="off"
                            variant="filled"
                            color="secondary"
                            name="post_url"
                          />
                        ) : (
                          ''
                        )
                      )
                    : ''}
                </Container>

                <Container className={classes.formContainer}>
                  {requirements[3].value > 0 ? (
                    <Typography gutterBottom variant="h6" component="h2">
                      Stories
                    </Typography>
                  ) : (
                    ''
                  )}

                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        onChange={handleStoriesConfirmChange}
                        name="completeTask"
                      />
                    }
                    label={`Yes, I’ve posted the ${
                      requirements[3].value
                    } required ${
                      requirements[3].value > 1 ? 'stories' : 'story'
                    }`}
                  />
                </Container>

                <Rate onRatingChange={setRating} />

                <FormControl className={classes.formContainer}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          onChange={handleCheckboxChange}
                          name="completeTask"
                        />
                      }
                      label="I have completed all requirements"
                    />

                    <Button
                      variant="contained"
                      size="large"
                      color="secondary"
                      disableElevation
                      fullWidth
                      onClick={handleSubmit}
                      disabled={
                        rating === -1 || complete === 0 || storiesComplete === 0
                      }
                    >
                      Submit Now
                    </Button>
                  </FormGroup>

                  <FormHelperText>
                    By tapping (submit now) you guarantee that you have created
                    the requested content.
                  </FormHelperText>
                </FormControl>
              </>
            )}
          </>
        )}
      </Container>

      <ActionModal
        open={modalOpen}
        onClose={handleRejectAction}
        decision
        title="Complete task"
        description={
          user.profile.type === 'influencer' || isJournalist
            ? 'You are notifying the brand that all requirements for this campaign are complete.'
            : null
        }
        label="All good"
        onClick={handleConfirmAction}
        onDangerClick={handleRejectAction}
      />

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        autoHideDuration={4000}
      >
        <Alert severity="error">Sorry, something went wrong.</Alert>
      </Snackbar>
    </>
  );
}

TaskDetail.propTypes = {
  taskData: PropTypes.shape({
    fetching: PropTypes.bool,
    errors: PropTypes.object,
    activeTask: PropTypes.object,
    updated: PropTypes.string,
  }),
  sessionData: PropTypes.shape({
    user: PropTypes.object,
  }),
  getTask: PropTypes.func,
  updateTask: PropTypes.func,
  updateTaskJournalist: PropTypes.func,
  rateExperience: PropTypes.func,
};

TaskDetail.defaultProps = {
  taskData: {},
  sessionData: {},
  getTask: () => {},
  updateTask: () => {},
  updateTaskJournalist: () => {},
  rateExperience: () => {},
};

const mapStateToProps = (state) => ({
  taskData: state.taskData,
  sessionData: state.sessionData,
});

const mapDispatchToProps = (dispatch) => ({
  getTask: (influencerId, taskId) => {
    dispatch(getTaskAction(influencerId, taskId));
  },
  updateTask: (task, influencerId, taskId) => {
    dispatch(updateTaskAction(task, influencerId, taskId));
  },
  updateTaskJournalist: (task, journalistId, taskId) => {
    dispatch(updateTaskJournalistAction(task, journalistId, taskId));
  },
  rateExperience: (rating, bookingId) => {
    dispatch(rateExperienceAction(rating, bookingId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskDetail);
