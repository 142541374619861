import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { Card, CardContent, Typography, Box } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ToggleButton from '@material-ui/lab/ToggleButton';

import FeatureList from 'components/lists/FeatureList';

const useStyles = makeStyles((theme) => ({
  planContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  planTitle: {
    '& > *': {
      marginLeft: theme.spacing(1),
    },
    '& > *:first-child': {
      marginLeft: 6,
    },
  },
  planPrice: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(1),
    textDecorationLine: 'line-through',
  },
  planButton: {
    '&.Mui-selected': {
      '&:hover': {
        backgroundColor: '#c5cae9',
        cursor: 'auto',
      },
    },
  },
}));

export default function PricingCard({
  children,
  planName,
  planPrice,
  featureList,
  selectable,
  selected,
  onChange,
}) {
  const classes = useStyles();

  return (
    <Card>
      <CardContent className={classes.planContent}>
        <Box>
          <Box className={classes.planTitle} display="flex" alignItems="center">
            {children}

            <Typography variant="h5" component="h2">
              {planName}
            </Typography>
          </Box>

          <Typography variant="subtitle2">
            {`$${planPrice}/mo, then $149*/month`}
          </Typography>

          <Typography variant="subtitle2" />
        </Box>

        {featureList.length > 1 ? (
          <FeatureList featuresData={featureList} condensed />
        ) : null}

        {selectable ? (
          <ToggleButton
            value="check"
            selected={selected}
            onChange={onChange}
            className={classes.planButton}
          >
            <CheckIcon />
          </ToggleButton>
        ) : null}
      </CardContent>
    </Card>
  );
}

PricingCard.propTypes = {
  children: PropTypes.node,
  planName: PropTypes.string,
  planPrice: PropTypes.number,
  featureList: PropTypes.arrayOf(PropTypes.string),
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  onChange: PropTypes.func,
};

PricingCard.defaultProps = {
  children: <></>,
  planName: 'Plan 1',
  planPrice: 799,
  featureList: [''],
  selectable: false,
  selected: false,
  onChange: () => {},
};
