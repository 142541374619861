import React from 'react';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';

import CampaignGrid from 'components/grids/CampaignGrid';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  mainTabPanel: {
    marginTop: '1.5rem',
    [theme.breakpoints.down('xs')]: {
      marginTop: '1rem',
    },
  },
}));

function Campaigns({ sessionData }) {
  const { user } = sessionData;
  const userType = user?.profile?.type;
  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      {userType === 'brands' ? (
        <>
          <TabContext value={0}>
            <TabPanel value={0} className={classes.mainTabPanel}>
              <CampaignGrid
                status="approved,review,draft"
                userType={userType}
              />
            </TabPanel>

            <TabPanel value={1}>
              <CampaignGrid status="expired" userType={userType} />
            </TabPanel>
          </TabContext>
        </>
      ) : (
        <CampaignGrid status="approved" userType={userType} />
      )}
    </Container>
  );
}

Campaigns.propTypes = {
  sessionData: PropTypes.shape({
    user: PropTypes.objectOf(PropTypes.object),
  }),
};

Campaigns.defaultProps = {
  sessionData: {},
};

const mapStateToProps = (state) => ({
  sessionData: state.sessionData,
});

export default connect(mapStateToProps)(Campaigns);
