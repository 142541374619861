import {
  formatDate,
  capitalize,
  removeLeadingZero,
  removeTimeZone,
} from 'helpers/helpers';
import { CAMPAIGN_TYPES, WEEK_DAY_SLUGS, WEEK_DAYS } from 'constants/campaigns';

import dayjs from 'dayjs';
import { Box, Typography, IconButton, Tooltip } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import HelpIcon from '@material-ui/icons/Help';
import React from 'react';

// format date for API
export const convertDateForApi = (date) => {
  return dayjs(date).format('YYYY-MM-DD');
};

// format date for label
export const convertDateLabel = (date) => {
  return dayjs(removeTimeZone(date)).format('MM/DD/YYYY');
};

// format unavailable days/times for API
export const formatUnavailableDates = (days) => {
  const newObj = {};
  Object.keys(days).forEach((day) => {
    if (days[day].switch) {
      const dayIndex = WEEK_DAY_SLUGS.indexOf(capitalize(day)) + 1;
      const dayName = dayIndex === 7 ? 'Sunday' : WEEK_DAYS[dayIndex];
      newObj[dayName] = [
        {
          from: days[day].startTime,
          to: days[day].endTime,
        },
      ];
    }
  });
  return newObj;
};

// format range of tiers for API
const formatTier = (tier) => {
  if (!tier) return [];
  const [first, second] = tier;
  const tierArr = [];
  for (let i = first; i <= second; i += 1) {
    tierArr.push(i);
  }
  return tierArr;
};

// format campaign POST/PUT form-data
export const formatFormData = (data, step) => {
  const {
    name,
    description,
    launchDate,
    eventDate,
    openingDate,
    keyPlayers,
    keyPlayersData,
    startTime,
    endTime,
    endDate,
    featuredPhotos,
    brandId,
    categoryId,
    switchState,
    audience,
    tier,
    compensation,
    locations,
    maxBookings,
    unavailableDates,
    informationOnly,
    tip,
    posts,
    stories,
    hashtags,
  } = data;
  const formData = new FormData();
  formData.append('name', name);
  formData.append('description', description);
  formData.append('brand_id', brandId);
  formData.append('category_id', categoryId);
  if (launchDate) formData.append('launch_date', convertDateForApi(launchDate));
  if (eventDate) formData.append('event_date', convertDateForApi(eventDate));
  if (startTime) formData.append('from_time', startTime);
  if (endTime) formData.append('to_time', endTime);
  if (openingDate)
    formData.append('opening_date', convertDateForApi(openingDate));
  if (keyPlayers) {
    // Remove any key player without a name, then order items correctly
    const keyPlayersOrdered = keyPlayersData
      .filter((contact) => {
        return contact.name !== '' && contact.name !== undefined;
      })
      .map((contact) => ({
        type: contact.type || '',
        name: contact.name || '',
        bio: '',
      }));
    /* eslint-enable no-param-reassign */
    formData.append('key_players', JSON.stringify(keyPlayersOrdered));
  }
  if (endDate && switchState)
    formData.append('end_date', convertDateForApi(endDate));
  if (featuredPhotos && step === 0) {
    featuredPhotos.forEach((img) => {
      formData.append('featured_photos[]', img);
    });
  }
  if (locations) {
    const locationIds = locations.map((loc) => {
      return parseInt(loc, 10);
    });
    locationIds.forEach((loc) => {
      formData.append('locations[]', loc);
    });
  }
  const tierArray = formatTier(tier);
  if (tier && step >= 1) {
    formData.append('target', 'influencer,journalist');
    formData.append('tier_range_influencer', tierArray);
    formData.append('tier_range_journalist', tierArray);
  } else if (audience?.influencer) {
    formData.append('target', 'influencer');
    formData.append('tier_range_influencer', tierArray);
  } else {
    formData.append('target', 'journalist');
    formData.append('tier_range_journalist', tierArray);
  }
  if (step >= 2) {
    const booking = {
      information_only: informationOnly,
      compensation,
      tip,
      max_bookings_per_week: maxBookings,
    };

    // Only send unavailable dates if any exist
    const unavailableObj = formatUnavailableDates(unavailableDates);
    if (Object.keys(unavailableObj).length > 0)
      booking.unavailable_dates = unavailableObj;

    // Append final booking object
    formData.append('booking', JSON.stringify(booking));
  }
  if (step >= 3) {
    const influencerInstructions = JSON.stringify({
      tagging_required: true,
      geotag_required: true,
      posts_required: posts,
      stories_required: stories,
      hashtags,
    });
    formData.append('influencer_instructions', influencerInstructions);
  }
  return formData;
};

// get campaign cateogory name from category ID
export const getCampaignCategory = (categoryId) => {
  return Object.keys(CAMPAIGN_TYPES).find(
    (key) => CAMPAIGN_TYPES[key].name === categoryId
  );
};

// get campaign category name from slug
export const getCampaignCategoryName = (category) => {
  switch (category) {
    case 'singledayevent':
    case 'App\\Models\\Campaigns\\SingleEvent':
      return 'Single-Day Event';
    case 'opening':
    case 'App\\Models\\Campaigns\\Opening':
      return 'Opening';
    case 'generalupdates':
    case 'App\\Models\\Campaigns\\GeneralUpdate':
      return 'General Updates';
    default:
      return 'General Updates';
  }
};

/* eslint-disable camelcase */
// get opening date of any campaign type
const getOpeningDate = (attributes) => {
  const openingDate =
    attributes?.opening_date ||
    attributes?.event_date ||
    attributes?.start_date ||
    attributes?.launch_date ||
    null;
  return openingDate ? formatDate(openingDate) : '';
};

// return array of columns for campaign image grid
export const getImageGrid = (images) => {
  let cols = [];
  switch (images) {
    case 2:
      cols = [12, 12];
      break;
    case 3:
      cols = [12, 6, 6];
      break;
    case 4:
      cols = [12, 4, 4, 4];
      break;
    case 5:
      cols = [12, 6, 6, 6, 6];
      break;
    default:
      cols = [12];
  }
  return cols;
};

// map featured photos to new format
const getImageArray = (photos) => {
  if (!photos) return [];
  let gridPhotos = photos;
  // ensure no more than 5 photos displayed, need to limit this through validation
  if (photos.length > 5) gridPhotos = photos.slice(0, 5);
  const colsArray = getImageGrid(gridPhotos.length);
  const imgArray = gridPhotos.map((img, i) => ({
    src: img.path,
    cols: colsArray[i],
    title: img.name || '',
  }));

  return imgArray;
};

const mapKeyPlayers = (keyPlayers) => {
  return keyPlayers.map((contact) => ({
    head: `${contact.type ? capitalize(contact.type) : 'Contact'}:`,
    value: contact.name,
  }));
};

// format campaign general info for detail view
const formatGeneralInfo = (campaign) => {
  if (!campaign.attributes) return [];
  const { attributes, category_id, type, locations } = campaign;
  const openingDate = getOpeningDate(attributes);
  const endDate = attributes.end_date ? formatDate(attributes.end_date) : '';
  const fromTime = attributes.from_time
    ? removeLeadingZero(attributes.from_time)
    : '';
  const toTime = attributes.to_time
    ? removeLeadingZero(attributes.to_time)
    : '';
  const dateLabel = CAMPAIGN_TYPES[category_id].dateLabel || '';
  const keyPlayers = attributes.key_players
    ? JSON.parse(attributes.key_players)
    : [];
  const keyPlayersFormatted = mapKeyPlayers(keyPlayers);
  const comp = type?.booking?.compensation
    ? `$${type?.booking?.compensation}`
    : '';
  const locationsArr = locations.map((loc) => {
    return loc.name;
  });

  const info = [
    openingDate && {
      head: `${dateLabel}:`,
      value: openingDate,
    },
    endDate && {
      head: 'End Date:',
      value: endDate,
    },
    fromTime &&
      toTime && {
        head: 'Event Time:',
        value: `${fromTime} to ${toTime}`,
      },
    comp && {
      head: [
        <Box display="flex" alignItems="center">
          <Typography component="span" variant="body">
            Total Comp Amount:
          </Typography>

          <Tooltip
            title="The complementary total value included in your VIP reservation (without gratuity – please tip your server!). Should you exceed the limit, a check will be dropped to cover the difference."
            placement="right"
          >
            <IconButton aria-label="more information" size="small">
              <HelpIcon />
            </IconButton>
          </Tooltip>
        </Box>,
      ],
      value: comp,
    },
    locationsArr.length > 0 && {
      head: 'Locations(s):',
      value: locationsArr.join(', '),
    },
    ...keyPlayersFormatted,
  ];

  return info.filter((el) => {
    return el || null;
  });
};

// format influencer requirements for detail view
const formatRequirements = (instructions) => {
  const {
    posts_required,
    stories_required,
    hashtags,
    tagging_required,
    geotag_required,
  } = instructions;

  const reqs = [
    posts_required && {
      head: 'Number of posts:',
      value: posts_required || '',
    },
    stories_required && {
      head: 'Number of stories:',
      value: stories_required || '',
    },
    hashtags && {
      head: 'Use hashtags:',
      value: hashtags.join(', '),
    },
    tagging_required && {
      head: 'Tagging required?',
      value: tagging_required ? 'Yes' : 'No',
    },
    geotag_required && {
      head: 'Use hashtags',
      value: geotag_required ? 'Yes' : 'No',
    },
  ];

  return reqs.filter((el) => {
    return el || null;
  });
};

// format influencer requirements for summary view
const formatRequirementsForSummary = (instructions) => {
  const {
    hashtags,
    posts_required,
    stories_required,
    geotag_required,
    tagging_required,
  } = instructions;
  return [
    {
      head: 'Posts',
      value: posts_required,
    },
    {
      head: 'Stories',
      value: stories_required,
    },
    {
      head: 'Profile Tag',
      value: tagging_required ? 'Yes' : 'No',
    },
    {
      head: 'Location Tag',
      value: geotag_required ? 'Yes' : 'No',
    },
    hashtags?.length > 0 && {
      head: 'Hashtags',
      value: hashtags.map((tag) => {
        return <Chip size="small" label={tag} />;
      }),
    },
  ];
};

// extract file name without extension from full URL path
export const getNameFromLink = (path) => {
  const validRegex = new RegExp(/(\w+)(\.\w+)+(?!.*(\w+)(\.\w+)+)/);
  const validatedValue = path.match(validRegex)[1].split('');
  const nameArr = [];
  let i = 0;
  while (validatedValue[i] !== '.' && i < validatedValue.length) {
    nameArr.push(validatedValue[i]);
    i += 1;
  }
  return nameArr.join('');
};

// create object with brand location IDs and names
export const formatLocationData = (locations) => {
  const formatted = {};
  locations.forEach((loc) => {
    formatted[loc.id] = loc.name;
  });
  return formatted;
};

// format influencer tier range of campaign
const getTierRange = (campaign) => {
  const tierRange =
    campaign.tier_range_influencer || campaign.tier_range_journalist;
  if (!tierRange) return '';
  if (tierRange.length === 1) return tierRange;
  return tierRange
    ? `${tierRange.substr(0, 1)}-${tierRange.substr(-1, 1)}`
    : '';
};

// format campaign data for detail view
export const formatCampaignDetails = (campaign) => {
  if (!Object.keys(campaign).length > 0) return null;
  const campaignType = CAMPAIGN_TYPES[campaign.category_id].name;
  const imgArray = getImageArray(campaign.featuredphotos);
  const generalInfo = formatGeneralInfo(campaign);
  const requirements = formatRequirements(campaign.influencer_instructions);

  return {
    campaignType,
    imgArray,
    generalInfo,
    requirements,
  };
};

// format campaign data for summary view
export const formatCampaignSummary = (campaign) => {
  if (!campaign) return {};
  const {
    category_id,
    name,
    description,
    featuredPhotos,
    attributes,
    target,
    type,
    locations,
    influencer_instructions,
  } = campaign;
  const { end_date, from_time, to_time } = attributes || {};
  const endDate = end_date ? formatDate(end_date) : '';
  const fromTime = from_time ? removeLeadingZero(from_time) : '';
  const toTime = to_time ? removeLeadingZero(to_time) : '';
  const openingDate = attributes ? getOpeningDate(attributes) : '';
  const dateLabel = CAMPAIGN_TYPES[category_id]?.dateLabel || '';
  const imgArray = featuredPhotos ? getImageArray(featuredPhotos) : [];
  const requirements = influencer_instructions
    ? formatRequirementsForSummary(influencer_instructions)
    : [];
  const keyPlayers = attributes?.key_players
    ? mapKeyPlayers(JSON.parse(attributes.key_players))
    : [];
  let general = [
    {
      head: 'Headline',
      value: name,
    },
    {
      head: 'Description',
      value: description,
    },
    {
      head: dateLabel,
      value: openingDate,
    },
    fromTime &&
      toTime && {
        head: 'Event Time:',
        value: `${fromTime} to ${toTime}`,
      },
    endDate !== '' && {
      head: 'End Date',
      value: endDate,
    },
  ];
  const audience = [
    {
      head: 'Influencers',
      value: target?.includes('influencer') ? 'Yes' : 'No',
    },
    {
      head: 'Journalists',
      value: target?.includes('journalist') ? 'Yes' : 'No',
    },
    {
      head: 'Tier',
      value: getTierRange(campaign),
    },
  ];
  const bookings = [
    {
      head: 'Total Comp Amount',
      value: type?.booking?.compensation,
    },
    {
      head: 'Max Bookings/week',
      value: type?.booking?.max_bookings_per_week,
    },
    {
      head: 'Available at',
      value: locations?.map((loc) => {
        return <Chip size="small" label={loc.name} />;
      }),
    },
  ];

  // filter out null array items
  general = general.filter((el) => {
    return el || null;
  });

  return {
    imgArray,
    general,
    audience,
    bookings,
    requirements,
    keyPlayers,
  };
};
