import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles((theme) => ({
  dangerButton: {
    color: theme.palette.error.dark,
  },
}));

export default function ActionModal({
  open,
  decision,
  title,
  description,
  label,
  onDangerClick,
  onClick,
  dangerLabel,
  onClose,
}) {
  const classes = useStyles();
  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={onClose}
      aria-labelledby="action-modal-title"
      aria-describedby="action-modal-description"
    >
      <DialogTitle id="action-modal-title">{title}</DialogTitle>

      <DialogContent>
        <DialogContentText id="action-modal-description">
          {description}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        {decision ? (
          <Button className={classes.dangerButton} onClick={onDangerClick}>
            {dangerLabel}
          </Button>
        ) : null}

        <Button onClick={onClick}>{label}</Button>
      </DialogActions>
    </Dialog>
  );
}

ActionModal.propTypes = {
  open: PropTypes.bool,
  decision: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  label: PropTypes.string,
  onDangerClick: PropTypes.func,
  onClick: PropTypes.func,
  dangerLabel: PropTypes.string,
  onClose: PropTypes.func,
};

ActionModal.defaultProps = {
  open: false,
  decision: false,
  title: 'Important message',
  description: '',
  label: 'Accept',
  onDangerClick: () => {},
  onClick: () => {},
  dangerLabel: 'Cancel',
  onClose: () => {},
};
