import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';

import { getInfluencerBookingsAction } from 'redux/bookingDuck';

import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Container,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';

import UserProfileCard from 'components/cards/UserProfileCard';
import DataTable from 'components/lists/DataTable';
import GoTo from 'components/buttons/GoTo';
import BookingCard from 'components/cards/BookingCard';
import HelpIcon from 'images/help-icon.svg';

const useStyles = makeStyles((theme) => ({
  locationDataTable: {
    '& td': {
      borderBottom: '3px solid #E5E5E5',
      color: '#606060',
      fontSize: 18,
      padding: '1rem 0',
      '&:first-child': {
        paddingLeft: '2rem',
        paddingRight: '.8rem',
        width: '50%',
      },
      '&:last-child': {
        paddingRight: '2rem',
        paddingLeft: '.8rem',
      },
      '&.MuiTableCell-head': {
        fontWeight: 600,
      },
    },
  },
  upcomingBooking: {
    background: `url(${HelpIcon}) no-repeat center 0`,
    padding: '2.5rem 0 1.7rem',
  },
  rightCol: {
    top: '-6rem',
    position: 'relative',
    zIndex: 1100,
    [theme.breakpoints.down('xs')]: {
      top: '1rem',
    },
  },
}));

function InfluencerDashboard({ influencer, getBookings, bookingData }) {
  const status = 'accepted';
  const history = useHistory();
  const classes = useStyles();
  const bookings = bookingData.bookings[status].data || [];
  const contactData = [
    {
      head: 'Email',
      value: influencer.email,
    },
    {
      head: 'Location',
      value: influencer.location,
    },
  ];

  useEffect(() => {
    getBookings(influencer.id, status, 1, 1);
  }, [getBookings, influencer.id]);

  const upcomingBooking = bookings[0];

  return (
    <Container maxWidth="xl" style={{ marginTop: '2.5rem' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box style={{ marginBottom: '2rem' }}>
            <UserProfileCard
              isBrand={false}
              userName={influencer.name}
              userLogo={influencer.avatar}
              userDescription={influencer.bio}
              socialnetworks={influencer.socialNetworks}
            />
          </Box>

          <Card className={classes.root} style={{ padding: '.8rem 0' }}>
            <CardContent
              style={{ padding: 0 }}
              className={classes.locationDataTable}
            >
              <DataTable rows={contactData} condensed />
            </CardContent>
          </Card>
        </Grid>

        <Grid className={classes.rightCol} item xs={12} md={6}>
          <Box className={classes.upcomingBooking}>
            <Typography
              variant="h5"
              component="h2"
              align="center"
              color="primary"
            >
              UPCOMING EVENTS
            </Typography>
          </Box>

          <Box style={{ marginBottom: '2rem' }}>
            {upcomingBooking ? (
              <BookingCard // eslint-disable-line
                id={upcomingBooking.id}
                avatar={upcomingBooking.campaign?.brand?.logo}
                name={upcomingBooking.campaign?.brand?.name}
                subheader={upcomingBooking.location?.name}
                date={upcomingBooking.booking_date}
                status={upcomingBooking.status}
                guests={upcomingBooking.guests}
                influencer={upcomingBooking.influencer?.user?.id}
                // onMediaCancelClick={handleCancelClick}
                fullWidth={1}
              />
            ) : (
              <Typography
                variant="h5"
                component="h4"
                align="center"
                color="secondary"
              >
                No upcoming events.
              </Typography>
            )}
          </Box>

          <Box style={{ marginBottom: '1rem' }}>
            <GoTo
              label="See all bookings"
              btnAction={() => history.push('/bookings')}
            />
          </Box>

          <Box style={{ marginBottom: '1rem' }}>
            <GoTo
              label="Explore new campaigns"
              btnAction={() => history.push('/campaigns')}
            />
          </Box>

          <Box style={{ marginBottom: '1rem' }}>
            <GoTo
              label="Edit profile"
              btnAction={() => history.push('/profile')}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

InfluencerDashboard.propTypes = {
  influencer: PropTypes.arrayOf(PropTypes.object),
  getBookings: PropTypes.func,
  bookingData: PropTypes.shape({
    fetching: PropTypes.bool,
    bookings: PropTypes.shape({
      accepted: PropTypes.shape({
        bookings: PropTypes.array,
        fetching: PropTypes.bool,
        pages: PropTypes.number,
      }),
    }),
  }),
};

InfluencerDashboard.defaultProps = {
  influencer: {},
  getBookings: () => {},
  bookingData: {},
};

const mapStateToProps = (state) => ({
  bookingData: state.bookingData,
});

const mapDispatchToProps = (dispatch) => ({
  getBookings: (id, status, page, size) => {
    dispatch(getInfluencerBookingsAction(id, status, page, size));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InfluencerDashboard);
