import React, { useEffect, useState } from 'react';

import {
  Container,
  Box,
  Typography,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import HelpIcon from '@material-ui/icons/Help';

import DayHours from 'components/forms/DayHours';
import {
  WEEK_DAY_SLUGS,
  COMP_AMOUNTS,
  CAMPAIGN_CREATION_HELPER,
} from 'constants/campaigns';
import { formatLocationData } from 'helpers/campaigns';
import { getKeyByValue } from 'helpers/helpers';
import PropTypes from 'prop-types';

/* eslint-disable camelcase */
export default function CampaignBookings({
  brand,
  onUpdate,
  campaignDraft,
  errors,
}) {
  const { locations } = brand;
  const formLocations = locations ? formatLocationData(locations) : {};

  // initial values
  const [values, setValues] = useState({
    compensation: campaignDraft?.compensation || 25,
    locations: campaignDraft?.locations || Object.keys(formLocations),
    maxBookings: campaignDraft?.maxBookings || 1,
    unavailableDates: campaignDraft?.unavailableDates || {
      mon: {},
      tue: {},
      wed: {},
      thu: {},
      fri: {},
      sat: {},
      sun: {},
    },
    informationOnly: false,
    tip: false,
  });

  const handleLocationsChange = (event, value) => {
    const newArr = [];
    value.forEach((locName) => {
      newArr.push(getKeyByValue(formLocations, locName));
    });
    setValues({ ...values, locations: newArr });
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleDay = (day, times) => {
    const current = values.unavailableDates[day];
    if (
      times.switch === current.switch &&
      times.startTime === current.startTime &&
      times.endTime === current.endTime
    ) {
      return;
    }
    setValues({
      ...values,
      unavailableDates: { ...values.unavailableDates, [day]: times },
    });
  };

  const updateCallback = () => {
    onUpdate(values);
  };

  useEffect(updateCallback, [values]);

  return (
    <Container maxWidth="sm" style={{ marginTop: '2.5rem' }}>
      <form>
        <Container align="center">
          <Typography gutterBottom variant="h4" component="h2">
            Incentivize your Audience.
          </Typography>

          <Typography gutterBottom component="p">
            Offer a complimentary experience in exchange for media consideration
            by journalists or for social media promotion by influencers.
          </Typography>
        </Container>

        <Card>
          <CardContent>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography gutterBottom variant="overline" component="h2">
                Compensation
              </Typography>

              <Tooltip
                title="The total value of the complimentary reservation being offered. Should the visitor choose to spend more than the comp total, we recommend presenting them with an itemized bill for maximum transparency."
                placement="left"
              >
                <IconButton aria-label="more information">
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </Box>

            <Typography gutterBottom variant="body2" component="p">
              {CAMPAIGN_CREATION_HELPER.compensation}
            </Typography>

            <FormControl variant="filled">
              <InputLabel id="comp-amount">Comp Amount</InputLabel>
              <Select
                color="secondary"
                labelId="comp-amount"
                id="comp-amount"
                value={values.compensation}
                onChange={handleChange('compensation')}
                error={Boolean(errors?.compensation)}
              >
                {COMP_AMOUNTS.map((amt) => (
                  <MenuItem value={amt}>${amt}</MenuItem> // eslint-disable-line react/jsx-one-expression-per-line
                ))}
              </Select>

              <FormHelperText>{errors?.compensation || ''}</FormHelperText>
            </FormControl>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Typography gutterBottom variant="overline" component="h2">
              Restrictions
            </Typography>

            <TextField
              color="secondary"
              label="Max Bookings"
              id="max-bookings"
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">/ week</InputAdornment>
                ),
              }}
              variant="filled"
              value={values.maxBookings}
              onChange={handleChange('maxBookings')}
              error={Boolean(errors?.max_bookings_per_week)}
              helperText={errors?.max_bookings_per_week || 'Max of 50'}
            />

            <Autocomplete
              disableClearable
              multiple
              value={values.locations.map((id) => formLocations[id])}
              onChange={handleLocationsChange}
              id="available-locations"
              options={Object.values(formLocations) || {}}
              getOptionLabel={(option) => option}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    color="default"
                    label={option}
                    {...getTagProps({ index })}
                    disabled={values.locations.length === 1}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  color="secondary"
                  label="Available at"
                  placeholder="Location"
                  error={Boolean(errors?.available_at)} // this can be changed for errors?.location or whatever label is used on state
                  helperText={errors?.available_at}
                />
              )}
            />
            {values.locations.length === 1 ? (
              <FormHelperText>
                At least, one location is required.
              </FormHelperText>
            ) : null}
          </CardContent>
        </Card>

        {/* In future may add conditional here to show only for general update campaign type */}
        <Card>
          <CardContent>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography gutterBottom variant="overline" component="h2">
                Blocked hours
              </Typography>

              <Tooltip
                title="Blocked times that are unavailable for VIP booking requests."
                placement="left"
              >
                <IconButton aria-label="more information">
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </Box>

            <Typography gutterBottom variant="body2" display="block">
              Influencers and journalists will not be able to book at your place
              during these hours.
            </Typography>

            {WEEK_DAY_SLUGS.map((day) => (
              <DayHours
                key={day}
                day={day}
                onChange={handleDay}
                initialValues={values.unavailableDates[day.toLowerCase()]}
              />
            ))}
          </CardContent>
        </Card>
      </form>
    </Container>
  );
}

CampaignBookings.propTypes = {
  onUpdate: PropTypes.func,
  errors: PropTypes.objectOf(PropTypes.object),
  campaignDraft: PropTypes.shape({
    switchState: PropTypes.bool,
  }),
  brand: PropTypes.objectOf(PropTypes.object),
};

CampaignBookings.defaultProps = {
  onUpdate: () => {},
  campaignDraft: {},
  brand: {},
  errors: {},
};
