import React from 'react';
import CreateIcon from '@material-ui/icons/Create';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Chip from '@material-ui/core/Chip';

import { Box, Typography, IconButton, Tooltip } from '@material-ui/core';

import HelpIcon from '@material-ui/icons/Help';

export const CAMPAIGNS_LIST = [
  { icon: <CreateIcon />, name: 'General Updates' },
  { icon: <CreateIcon />, name: 'Single Day Event' },
  { icon: <CreateIcon />, name: 'Opening' },
];

export const KEY_PLAYER_POSITIONS = [
  'Chef',
  'Owner',
  'Beverage Director',
  'Interior Designer',
];

export const INFLUENCER_TIERS = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
];

export const WORKING_HOURS = [
  '00:00 AM',
  '00:30 AM',
  '01:00 AM',
  '01:30 AM',
  '02:00 AM',
  '02:30 AM',
  '03:00 AM',
  '03:30 AM',
  '04:00 AM',
  '04:30 AM',
  '05:00 AM',
  '05:30 AM',
  '06:00 AM',
  '06:30 AM',
  '07:00 AM',
  '07:30 AM',
  '08:00 AM',
  '08:30 AM',
  '09:00 AM',
  '09:30 AM',
  '10:00 AM',
  '10:30 AM',
  '11:00 AM',
  '11:30 AM',
  '12:00 PM',
  '12:30 PM',
  '01:00 PM',
  '01:30 PM',
  '02:00 PM',
  '02:30 PM',
  '03:00 PM',
  '03:30 PM',
  '04:00 PM',
  '04:30 PM',
  '05:00 PM',
  '05:30 PM',
  '06:00 PM',
  '06:30 PM',
  '07:00 PM',
  '07:30 PM',
  '08:00 PM',
  '08:30 PM',
  '09:00 PM',
  '09:30 PM',
  '10:00 PM',
  '10:30 PM',
  '11:00 PM',
  '11:30 PM',
];

export const WEEK_DAY_SLUGS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

// to retrieve using JS getDate() indices
export const WEEK_DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const DEFAULT_REQUIREMENTS = [
  {
    head: [
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        ml={2}
      >
        <Typography component="span" variant="body2">
          Profile Tagging
        </Typography>

        <Tooltip
          title="Brand social media handle to be tagged in the required post."
          placement="right"
        >
          <IconButton aria-label="more information">
            <HelpIcon />
          </IconButton>
        </Tooltip>
      </Box>,
    ],
    value: <CheckCircleIcon color="disabled" />,
  },
  {
    head: [
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        ml={2}
      >
        <Typography component="span" variant="body2">
          Location Tagging
        </Typography>

        <Tooltip
          title="Brand or property location tag to be included in the required post."
          placement="right"
        >
          <IconButton aria-label="more information">
            <HelpIcon />
          </IconButton>
        </Tooltip>
      </Box>,
    ],
    value: <CheckCircleIcon color="disabled" />,
  },
];

export const CAMPAIGN_TYPES = {
  1: {
    name: 'Item Spotlight',
    dateLabel: '',
  },
  2: {
    name: 'Menu Refresh',
    dateLabel: '',
  },
  3: {
    name: 'Promotion',
    dateLabel: '',
  },
  4: {
    name: 'General Updates',
    dateLabel: 'Launch Date',
  },
  5: {
    name: 'Opening',
    dateLabel: 'Opening Date',
  },
  6: {
    name: 'Single-Day Event',
    dateLabel: 'Event Date',
  },
};

export const OPENING_DATA = [
  {
    head: 'Headline',
    value: 'Al Pastor Chicken',
  },
  {
    head: 'Description',
    value:
      'Grilled chicken, bacon bits, onion, cilantro, chipotle sauce. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dapibus mattis tellus, eget ultricies neque bibendum vitae.',
  },
  {
    head: 'Opening Date',
    value: '08/17/20',
  },
];

export const OPENING_KEY_PLAYER_1 = [
  {
    head: 'Name',
    value: 'Jessy Stone',
  },
  {
    head: 'Position',
    value: 'Chef',
  },
];

export const OPENING_KEY_PLAYER_2 = [
  {
    head: 'Name',
    value: 'Abdur Rowan',
  },
  {
    head: 'Position',
    value: 'Owner',
  },
];

export const AUDIENCE = [
  {
    head: 'Indluencers',
    value: 'Yes',
  },
  {
    head: 'Journalists',
    value: 'Yes',
  },
  {
    head: 'Tier',
    value: '2-3',
  },
];

export const BOOKINGS = [
  {
    head: 'Total Comp Amount',
    value: '$50',
  },

  {
    head: 'Max Bookings/week',
    value: '3',
  },
  {
    head: 'Available at',
    value: [
      <Chip size="small" label="East Village" />,
      <Chip size="small" label="Nolita" />,
    ],
  },
];

export const REQUIREMENTS = [
  {
    head: 'Posts',
    value: 2,
  },
  {
    head: 'Stories',
    value: 2,
  },
  {
    head: 'Profile Tag',
    value: 'Yes',
  },
  {
    head: 'Location Tag',
    value: 'Yes',
  },
  {
    head: 'Hashtags',
    value: [
      <Chip size="small" label="#southOfStress" />,
      <Chip size="small" label="#tacoLyfe" />,
    ],
  },
];

export const GENERAL_DATA = {
  campaignName: 'Al Pastor Chicken',
  campaignType: 'Item Spotlight',
  price: 12,
  description:
    'Grilled chicken, bacon bits, onion, cilantro, chipotle sauce. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dapibus mattis tellus, eget ultricies neque bibendum vitae.',
};

export const COMP_AMOUNTS = [25, 50, 100, 150, 250];

export const CREATED_CAMPAIGN_MSG = {
  success: 'Your campaign has been sent successfully for review.',
  label: 'OK',
};

export const CAMPAIGN_CREATION_HELPER = {
  generalUpdates: 'Please add any general information.',
  audience: 'Select the type of audience.',
  compensation: 'Any related compensation information.',
  posting: 'Posts details',
};
