import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

import {
  Button,
  Card,
  CardContent,
  Typography,
  Box,
  Collapse,
  makeStyles,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import ListAction from 'components/lists/ListAction';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 24,
    color: '#606060',
  },
  card: {
    borderRadius: 29,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  cardContent: {
    padding: '.3rem 1.5rem',
    '&:last-child': {
      paddingBottom: '.3rem',
    },
  },
  expand: {
    border: '1px solid #606060',
    borderRadius: 50,
    marginRight: '.3rem',
    width: '2rem',
    height: '2rem',
    color: '#606060',
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  removeButton: {
    color: '#EF4B4E',
    marginBottom: '.5rem',
  },
}));

const MenusList = ({ menus, canEdit, title }) => {
  const history = useHistory();
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              variant="overline"
              className={classes.title}
              component="h2"
            >
              {title}
            </Typography>
            <ExpandMore
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
            />
          </Box>

          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <ListAction items={menus} type="launch" />
            {canEdit ? (
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Button
                  aria-label="edit-menus"
                  onClick={() => history.push('/profile/menus')}
                  className={classes.removeButton}
                >
                  Edit menus
                </Button>
              </Box>
            ) : null}
          </Collapse>
        </CardContent>
      </Card>
    </>
  );
};

MenusList.propTypes = {
  menus: PropTypes.arrayOf(PropTypes.object),
  canEdit: PropTypes.bool,
  title: PropTypes.string,
};

MenusList.defaultProps = {
  menus: [],
  canEdit: false,
  title: 'Menus',
};

export default MenusList;
