import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import { getSubscriptionPlansAction } from 'redux/subscriptionDuck';

import {
  Container,
  Grid,
  Typography,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Button,
} from '@material-ui/core';

import { SentimentVerySatisfiedOutlined } from '@material-ui/icons';

import NavBar from 'components/nav/NavBar';
import PricingCard from 'components/cards/PricingCard';
import formatSubscriptionFeatures from 'helpers/subscriptions';

const useStyles = makeStyles((theme) => ({
  pricingTableTitle: {
    paddingTop: 16,
    paddingLeft: 16,
  },
  planPrice: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(1),
    textDecorationLine: 'line-through',
  },
}));

/* eslint-disable camelcase */
function PlanSelect({ getPlan, subscriptionData, sessionData }) {
  const history = useHistory();
  const classes = useStyles();
  const { user } = sessionData;
  const plan = subscriptionData.plans?.data[0] || {};
  const planFormatted = formatSubscriptionFeatures(plan);

  useEffect(() => {
    getPlan();
  }, [getPlan]);

  const handleButtonClick = () => {
    history.push('/plans/payment');
  };

  const handleBackButton = () => {
    history.goBack();
  };

  return user?.current_subscription?.plan_id ? (
    <Redirect to="/profile" />
  ) : (
    <>
      <NavBar
        screenTitle="Select a plan"
        handleIconClick={handleBackButton}
        isMain
      />

      <Container maxWidth="sm">
        <Grid container spacing={2}>
          <Grid item sm={12} md={12}>
            <PricingCard
              selectable
              planName={plan.title}
              planPrice={plan.price}
              featureList={planFormatted.features}
              selected
            >
              <SentimentVerySatisfiedOutlined />
            </PricingCard>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="sm">
        <TableContainer component={Paper} elevation={0}>
          <Typography className={classes.pricingTableTitle} variant="h6">
            Summary
          </Typography>

          <Table>
            <TableHead>
              {planFormatted.description.map((item) => {
                return (
                  <TableRow>
                    <TableCell variant="head">{item.head}</TableCell>
                    <TableCell variant="body" align="right">
                      {item.head === 'Total' ? (
                        <Typography variant="subtitle2">
                          {`${item.value}, then $149*/month`}
                        </Typography>
                      ) : (
                        item.value
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableHead>
          </Table>
        </TableContainer>

        <Box display="flex" justifyContent="flex-end" mb={3}>
          <Button
            fullWidth
            disableElevation
            color="secondary"
            variant="outlined"
            size="large"
            onClick={handleButtonClick}
          >
            Checkout
          </Button>
        </Box>
      </Container>
    </>
  );
}
PlanSelect.propTypes = {
  getPlan: PropTypes.func,
  subscriptionData: PropTypes.objectOf(PropTypes.object),
  sessionData: PropTypes.objectOf(PropTypes.object),
};

PlanSelect.defaultProps = {
  getPlan: () => {},
  subscriptionData: {},
  sessionData: {},
};

const mapStateToProps = (state) => ({
  subscriptionData: state.subscriptionData,
  sessionData: state.sessionData,
});

const mapDispatchToProps = (dispatch) => ({
  getPlan: () => {
    dispatch(getSubscriptionPlansAction());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanSelect);
