import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  updateBrandAction,
  updateBrandLogoAction,
  clearUpdatedBrandProfileAction,
} from 'redux/userDuck';
import { capitalize, fileSizeMB } from 'helpers/helpers';
import {
  MAX_LENGTH_FIELDS,
  MAX_SIZE_IMG,
  ERROR_IMG_SIZE,
} from 'constants/settings';

import {
  Container,
  Typography,
  Card,
  CardContent,
  Button,
  TextField,
  InputAdornment,
  Snackbar,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import NavBar from 'components/nav/NavBar';
import AvatarUpload from 'components/forms/AvatarUpload';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  profileContainer: {
    marginBottom: theme.spacing(5),
    '& .MuiCard-root': {
      marginBottom: theme.spacing(2),
    },
  },
}));

/* eslint-disable react/jsx-no-duplicate-props */
function GeneralInfoEdit({
  sessionData,
  updateBrand,
  updateBrandLogo,
  clearBrandProfileFlag,
}) {
  const classes = useStyles();
  const history = useHistory();
  const [isSaving, setIsSaving] = useState(false);

  const { user } = sessionData || {};
  const isBrand = user?.profile?.type === 'brands';
  const [brand, setBrand] = useState(isBrand ? user?.profile?.brands[0] : {});
  const { errors, isBrandUpdated } = sessionData;
  const [errorPhoto, setErrorPhoto] = useState('');

  const [snackbarFields, setSnackbarFields] = useState({
    open: false,
    severity: 'success',
    message: '',
  });

  const [formErrors, setFormErrors] = useState({});
  const [values, setValues] = useState({
    name: brand.name,
    description: brand.description,
    address: brand.address,
    zip: brand.zip,
    phones: brand.phones,
  });

  const handleSnackbarClose = () => {
    setSnackbarFields({ ...snackbarFields, open: false });
    clearBrandProfileFlag();
  };

  const handleSubmit = () => {
    setIsSaving(true);
    updateBrand(brand, brand.id);

    if (
      brand.logo instanceof File &&
      fileSizeMB(brand.logo.size) <= MAX_SIZE_IMG
    ) {
      const formData = new FormData();
      formData.append('file', brand.logo);
      updateBrandLogo(formData, brand.id);
    }
  };

  const validateForm = () => {
    let formHasErrors = false;

    Object.keys(values).forEach((key) => {
      // check for empty and null fields
      if (values[key] === '' || values[key] === null) {
        formHasErrors = true;
        setFormErrors((prevState) => ({
          ...prevState,
          [key]: 'This field is required.',
        }));
        window.scrollTo(0, 0);
      }
    });

    if (!formHasErrors) handleSubmit();
  };

  const handleBackButton = () => {
    history.goBack();
  };

  const handleFieldChange = (prop) => (event) => {
    const newVal = event.target.value;
    if (newVal === '') {
      setFormErrors({
        ...formErrors,
        [prop]: 'This field is required.',
      });
    } else if (prop === 'zip' && !parseInt(newVal, 10)) {
      setFormErrors({
        ...formErrors,
        [prop]: 'Zip code must be a number.',
      });
    } else {
      setFormErrors({ ...formErrors, [prop]: '' });
    }
    setValues({ ...values, [prop]: newVal });

    setBrand({ ...brand, [event.target.name]: event.target.value });
  };

  const handleSocialFieldChange = (event) => {
    const index = brand.socialnetworks.findIndex(
      (n) => n.name === event.target.name
    );
    setBrand({
      ...brand,
      socialnetworks: Object.assign([], brand.socialnetworks, {
        [index]: {
          ...brand.socialnetworks[index],
          handle: event.target.value,
        },
      }),
    });
  };

  const handleAvatarChange = (newFile) => {
    setErrorPhoto('');
    setBrand({ ...brand, logo: newFile });
    if (fileSizeMB(newFile.size) > MAX_SIZE_IMG) {
      setErrorPhoto(ERROR_IMG_SIZE);
    }
  };

  useEffect(() => {
    setIsSaving(false);
    if (isBrandUpdated) {
      setSnackbarFields({
        open: true,
        severity: 'success',
        message: 'The brand was updated',
      });
    }
  }, [errors, isSaving, isBrandUpdated]);

  return (
    <>
      <NavBar screenTitle="General Info" handleIconClick={handleBackButton}>
        <CloseIcon />
      </NavBar>

      <Container
        className={classes.profileContainer}
        maxWidth="sm"
        style={{ marginTop: '2.5rem' }}
      >
        <form>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="overline" component="h2">
                General Info
              </Typography>

              <AvatarUpload
                avatar={brand.logo}
                alt={brand.name}
                onChange={handleAvatarChange}
                error={errorPhoto}
              />

              <TextField
                fullWidth
                color="secondary"
                id="name"
                label="Name"
                variant="filled"
                error={Boolean(formErrors.name)}
                helperText={formErrors.name}
                value={brand.name}
                name="name"
                inputProps={{
                  maxLength: MAX_LENGTH_FIELDS.name,
                }}
                onChange={handleFieldChange('name')}
                InputProps={{
                  endAdornment:
                    errors?.brand?.name === false ? <CheckCircleIcon /> : null,
                }}
              />

              <TextField
                fullWidth
                color="secondary"
                id="description"
                label="Short Bio"
                multiline
                rows={3}
                variant="filled"
                value={brand.description}
                inputProps={{
                  maxLength: MAX_LENGTH_FIELDS.description,
                }}
                name="description"
                onChange={handleFieldChange('description')}
                error={Boolean(formErrors.description)}
                helperText={formErrors.description}
                InputProps={{
                  endAdornment:
                    errors?.brand?.description === false ? (
                      <CheckCircleIcon />
                    ) : null,
                }}
              />

              <TextField
                fullWidth
                color="secondary"
                id="address"
                label="Street Address"
                variant="filled"
                inputProps={{
                  maxLength: MAX_LENGTH_FIELDS.address,
                }}
                value={brand.address}
                name="address"
                onChange={handleFieldChange('address')}
                error={Boolean(formErrors.address)}
                helperText={formErrors.address}
                InputProps={{
                  endAdornment:
                    errors?.brand?.address === false ? (
                      <CheckCircleIcon />
                    ) : null,
                }}
              />

              <TextField
                fullWidth
                color="secondary"
                id="zip"
                label="ZIP Code"
                variant="filled"
                value={brand.zip}
                inputProps={{
                  maxLength: MAX_LENGTH_FIELDS.zip,
                }}
                name="zip"
                onChange={handleFieldChange('zip')}
                error={Boolean(formErrors.zip)}
                helperText={formErrors.zip}
                InputProps={{
                  endAdornment:
                    errors?.brand?.zip === false ? <CheckCircleIcon /> : null,
                }}
              />

              <TextField
                fullWidth
                color="secondary"
                id="phones"
                label="Phone Number"
                variant="filled"
                value={brand.phones}
                name="phones"
                inputProps={{
                  maxLength: MAX_LENGTH_FIELDS.phone,
                }}
                onChange={handleFieldChange('phones')}
                error={Boolean(formErrors.phones)}
                helperText={formErrors.phones}
                InputProps={{
                  endAdornment:
                    errors?.brand?.phones === false ? (
                      <CheckCircleIcon />
                    ) : null,
                }}
              />
            </CardContent>
          </Card>

          <Card>
            <CardContent>
              <Typography gutterBottom variant="overline" component="h2">
                Social Media
              </Typography>

              {brand.socialnetworks.map((network) => (
                <TextField
                  autoComplete="off"
                  fullWidth
                  color="secondary"
                  label={`${capitalize(network.name)} handle`}
                  id={network.name}
                  inputProps={{
                    maxLength: MAX_LENGTH_FIELDS.socialNetwork,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {network.name === 'instagram' ||
                        network.name === 'twitter'
                          ? '@'
                          : '/'}
                      </InputAdornment>
                    ),
                    endAdornment:
                      errors.social &&
                      errors?.social[network.id]?.handle === false ? (
                        <CheckCircleIcon />
                      ) : null,
                  }}
                  variant="filled"
                  value={network.handle}
                  name={network.name}
                  onChange={handleSocialFieldChange}
                  helperText={
                    errors.social && errors?.social[network.id]?.handle
                  }
                />
              ))}
            </CardContent>
          </Card>

          <Button
            variant="outlined"
            size="large"
            color="secondary"
            disableElevation
            fullWidth
            onClick={validateForm}
            disabled={isSaving}
          >
            Save changes
          </Button>
        </form>
      </Container>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={snackbarFields.open}
        onClose={() => handleSnackbarClose()}
        autoHideDuration={4000}
      >
        <Alert severity={snackbarFields.severity}>
          {snackbarFields.message}
        </Alert>
      </Snackbar>
    </>
  );
}

GeneralInfoEdit.propTypes = {
  sessionData: PropTypes.shape({
    user: PropTypes.object,
    errors: PropTypes.shape({
      brand: PropTypes.object,
      social: PropTypes.object,
    }),
    isBrandUpdated: PropTypes.bool,
  }),
  updateBrand: PropTypes.func,
  updateBrandLogo: PropTypes.func,
  clearBrandProfileFlag: PropTypes.func,
};

GeneralInfoEdit.defaultProps = {
  sessionData: {},
  updateBrand: () => {},
  updateBrandLogo: () => {},
  clearBrandProfileFlag: () => {},
};

const mapStateToProps = (state) => ({
  sessionData: state.sessionData,
});

const mapDispatchToProps = (dispatch) => ({
  updateBrand: (fields, brandId) => {
    dispatch(updateBrandAction(fields, brandId));
  },
  updateBrandLogo: (file, brandId) => {
    dispatch(updateBrandLogoAction(file, brandId));
  },
  clearBrandProfileFlag: () => {
    dispatch(clearUpdatedBrandProfileAction());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfoEdit);
