import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Typography, Box } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';

import RATING_LABELS from 'constants/tasks';

export default function Rate({ onRatingChange }) {
  const [value, setValue] = useState(-1);
  const [hover, setHover] = useState(-1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onRatingChange(newValue);
  };

  const handleChangeActive = (event, newHover) => {
    setHover(newHover);
  };

  return (
    <Box mb={3} mt={3} pl={2}>
      <Typography variant="overline" component="h2">
        Rate your experience
      </Typography>

      <Box display="flex" alignItems="center">
        <Rating
          name="hover-feedback"
          value={value}
          precision={1}
          onChange={handleChange}
          onChangeActive={handleChangeActive}
          size="large"
        />
        {value !== null ? (
          <Box ml={2}>{RATING_LABELS[hover !== -1 ? hover : value]}</Box>
        ) : null}
      </Box>
    </Box>
  );
}

Rate.propTypes = {
  onRatingChange: PropTypes.func,
};

Rate.defaultProps = {
  onRatingChange: () => {},
};
