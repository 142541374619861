import React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

// Components
import {
  Grid,
  Card,
  Avatar,
  CardHeader,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  Box,
  IconButton,
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles((theme) => ({
  truncate: {
    display: '-webkit-box',
    boxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  campaignDesc: {
    lineClamp: 2,
  },

  campaignTitle: {
    lineClamp: 1,
    fontSize: 24,
    fontWeight: 700,
  },

  status: {
    color: theme.palette.error.main,
  },
  moreDetailsBtn: {
    color: '#031873',
  },
}));

export default function CampaignCard({
  avatar,
  brandName,
  brandType,
  image,
  title,
  description,
  link,
  brandLink,
  attributes,
  isBrand,
  status,
  onDiscardClick,
}) {
  /* eslint-disable camelcase */
  const classes = useStyles();
  const endDate = dayjs(attributes?.end_date).format('MMMM DD, YYYY');
  const launchDate = dayjs(attributes?.launch_date).format('MMMM DD, YYYY');

  return (
    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
      <Card>
        {!isBrand ? (
          <CardActionArea onClick={brandLink}>
            <CardHeader
              avatar={<Avatar alt={brandName} src={avatar} />}
              title={brandName}
              subheader={brandType}
              action={
                <IconButton aria-label="settings">
                  <ChevronRightIcon />
                </IconButton>
              }
            />
          </CardActionArea>
        ) : null}

        <CardActionArea onClick={link}>
          <CardMedia
            component="img"
            alt={title}
            height="180"
            image={image}
            title={title}
          />

          <CardContent>
            <Typography
              className={`${classes.campaignTitle} ${classes.truncate}`}
              variant="h6"
              component="h2"
              color="primary"
            >
              {title}
            </Typography>

            <Box className={classes.cardTags} mb={2} alignItems="center">
              <Typography variant="subtitle2" component="h3">
                {`${launchDate} - ${endDate}`}
              </Typography>

              {isBrand && status !== '' ? (
                <Typography
                  className={classes.status}
                  variant="subtitle2"
                  component="h3"
                >
                  {`${status}`}
                </Typography>
              ) : null}
            </Box>

            <Typography
              className={`${classes.campaignDesc} ${classes.truncate}`}
              variant="body2"
              color="textSecondary"
              component="p"
            >
              {description}
            </Typography>
          </CardContent>
        </CardActionArea>

        <CardActions>
          {isBrand ? (
            <>
              <Button size="small" color="primary" onClick={link}>
                {status === 'Draft' ? 'Edit' : 'More details'}
              </Button>

              {status === 'Draft' ? (
                <Button size="small" onClick={onDiscardClick}>
                  Discard
                </Button>
              ) : null}
            </>
          ) : (
            <Button
              size="small"
              onClick={link}
              className={classes.moreDetailsBtn}
            >
              More Details
            </Button>
          )}
        </CardActions>
      </Card>
    </Grid>
  );
}

CampaignCard.propTypes = {
  avatar: PropTypes.string,
  brandName: PropTypes.string,
  brandType: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.func,
  brandLink: PropTypes.func,
  isBrand: PropTypes.bool,
  attributes: PropTypes.objectOf(PropTypes.object),
  status: PropTypes.string,
  onDiscardClick: PropTypes.func,
};

CampaignCard.defaultProps = {
  avatar: 'B',
  brandName: 'Brand Name',
  brandType: 'Restaurant',
  image: '/placeholder-image.png',
  title: 'Campaign Title',
  description: 'Campaign description',
  link: () => null,
  brandLink: () => null,
  isBrand: false,
  attributes: {},
  status: '',
  onDiscardClick: () => {},
};
