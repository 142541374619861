import React from 'react';
import { useSelector } from 'react-redux';

import InfluencerProfileEdit from 'components/screens/InfluencerProfileEdit';
import BrandProfile from 'components/screens/BrandProfile';

export default function Profile() {
  const user = useSelector((state) => state.sessionData.user.profile);

  return (
    <>
      {user.type === 'influencer' || user.type === 'journalist' ? (
        <InfluencerProfileEdit />
      ) : (
        <BrandProfile />
      )}
    </>
  );
}
