import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import {
  Box,
  FormControl,
  FormControlLabel,
  Select,
  Typography,
  Switch,
} from '@material-ui/core';

import { WORKING_HOURS } from 'constants/campaigns';
import { getSlug } from 'helpers/helpers';

const useStyles = makeStyles((theme) => ({
  timeRange: {
    '& > *': {
      marginLeft: theme.spacing(2),
      marginBottom: 0,
    },
  },
  dayLabel: {
    justifyContent: 'space-between',
    width: '30%',
  },
}));

export default function DayHours({ day, onChange, initialValues }) {
  const classes = useStyles();
  const daySlug = getSlug(day);
  const daySet = Object.keys(initialValues ?? {}).length > 0;

  const [values, setValues] = useState({
    switch: daySet ? initialValues.switch : false,
    startTime: daySet ? initialValues.startTime : '00:00 AM',
    endTime: daySet ? initialValues.endTime : '11:30 PM',
  });

  const handleSwitchChange = (event) => {
    setValues({ ...values, switch: event.target.checked });
  };

  const handleTimeChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    onChange(daySlug, values);
  }, [values, onChange, daySlug]);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mb={1}
    >
      <FormControlLabel
        className={classes.dayLabel}
        control={
          <Switch
            checked={values.switch}
            onChange={handleSwitchChange}
            name="day"
          />
        }
        label={<Typography variant="subtitle2">{day}</Typography>}
        labelPlacement="start"
      />

      {values.switch ? (
        <Box className={classes.timeRange} display="flex" alignItems="center">
          <FormControl>
            <Select
              color="secondary"
              native
              value={values.startTime}
              onChange={handleTimeChange}
              inputProps={{
                name: 'startTime',
                id: 'start-time',
              }}
            >
              {WORKING_HOURS.filter((hour) => hour !== values.endTime).map(
                (hour) => (
                  <option value={hour} key={hour}>
                    {hour}
                  </option>
                )
              )}
            </Select>
          </FormControl>

          <Typography color="textSecondary">to</Typography>

          <FormControl>
            <Select
              color="secondary"
              native
              value={values.endTime}
              onChange={handleTimeChange}
              inputProps={{
                name: 'endTime',
                id: 'end-time',
              }}
            >
              {WORKING_HOURS.filter((hour) => hour !== values.startTime).map(
                (hour) => (
                  <option value={hour} key={hour}>
                    {hour}
                  </option>
                )
              )}
            </Select>
          </FormControl>
        </Box>
      ) : null}
    </Box>
  );
}

DayHours.propTypes = {
  onChange: PropTypes.func,
  day: PropTypes.string,
  initialValues: PropTypes.shape({
    switch: PropTypes.bool,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
  }),
};

DayHours.defaultProps = {
  onChange: () => {},
  day: 'Mon',
  initialValues: {},
};
