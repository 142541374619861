import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';

import {
  Card,
  Button,
  ButtonGroup,
  Typography,
  Divider,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import ActionModal from 'components/feedback/ActionModal';
import { CAMPAIGNS_LIST } from 'constants/campaigns';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 9998,
    pointerEvents: 'none',
  },
  campaignCreate: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 9999,
  },
  cardAddBtn: {
    backgroundColor: '#EBC8B3',
  },
  addCampaignIcon: {
    fontSize: 250,
    color: '#F7F0EA',
    margin: '2rem auto',
    display: 'block',
  },
  addCampaignTxt: {
    color: '#031873',
    fontSize: 24,
    fontWeight: 700,
    padding: '1rem 0',
    textAlign: 'center',
  },
  cancelCreateCampaign: {
    color: '#EF4B4E',
  },
  dividerAddCampaignIcon: {
    backgroundColor: '#F7F0EA',
    height: 3,
  },
}));

export default function CreateCampaign({ user }) {
  const classes = useStyles();
  const history = useHistory();
  const userBrand = user.profile.brands[0];
  const [alertLocationsModalOpen, setAlertLocationsModalOpen] = useState(false);
  const [
    alertCreateCampaignModalOpen,
    setAlertCreateCampaignModalOpen,
  ] = useState(false);

  const handleOpen = () => {
    if (userBrand.locations.length === 0) {
      setAlertLocationsModalOpen(true);
      return;
    }

    setAlertCreateCampaignModalOpen(true);
  };

  const handleLocationModalConfirm = () => {
    window.location = '/profile/location';
    setAlertLocationsModalOpen(false);
  };

  const createCampaignModalContent = () => {
    return (
      <ButtonGroup
        orientation="vertical"
        aria-label="vertical contained primary button group"
        variant="text"
      >
        {CAMPAIGNS_LIST.map((campaign) => (
          <Button
            size="large"
            onClick={() =>
              history.push(
                `/create/${campaign.name.replace(/ /g, '').toLowerCase()}`
              )
            }
          >
            {campaign.name}
          </Button>
        ))}
      </ButtonGroup>
    );
  };

  return (
    <>
      <ActionModal
        decision
        open={alertLocationsModalOpen}
        title="Missing locations"
        description="Whoops! Looks like you haven't added a location. Please go to your Profile and add a location first."
        label="Add a location"
        dangerLabel="Cancel"
        onDangerClick={() => setAlertLocationsModalOpen(false)}
        onClose={() => setAlertLocationsModalOpen(false)}
        onClick={() => handleLocationModalConfirm()}
      />

      <ActionModal
        title="Create a campaign"
        description={createCampaignModalContent()}
        open={alertCreateCampaignModalOpen}
        label={
          <Typography
            variant="span"
            component="span"
            className={classes.cancelCreateCampaign}
          >
            Cancel
          </Typography>
        }
        onClose={() => setAlertCreateCampaignModalOpen(false)}
        onClick={() => setAlertCreateCampaignModalOpen(false)}
      />

      <Card className={classes.cardAddBtn} onClick={() => handleOpen()}>
        <AddIcon fontSize="large" className={classes.addCampaignIcon} />
        <Divider className={classes.dividerAddCampaignIcon} />
        <Typography
          variant="span"
          component="div"
          className={classes.addCampaignTxt}
        >
          CREATE A CAMPAIGN
        </Typography>
      </Card>
    </>
  );
}

CreateCampaign.propTypes = {
  user: PropTypes.objectOf(PropTypes.object),
};

CreateCampaign.defaultProps = {
  user: {},
};
