import React from 'react';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import {
  Container,
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  GridList,
  GridListTile,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import NavBar from 'components/nav/NavBar';

import LocationCard from 'components/cards/LocationCard';
import MenusList from 'components/cards/MenusList';
import UserProfileCard from 'components/cards/UserProfileCard';

const useStyles = makeStyles((theme) => ({
  imagesGrid: {
    display: 'flex',
    justifyContent: 'center',
    '& .MuiGridListTile-tile': {
      borderRadius: 0,
    },
  },
  pdfContainer: {
    borderRadius: 6,
    overflow: 'hidden',
  },
  cardLink: {
    color: 'inherit',
    textDecoration: 'none',
  },
  profileAvatar: {
    width: theme.spacing(13),
    height: theme.spacing(13),
  },
}));

function BrandProfileView() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { state: brand } = location;

  const handleBackButton = () => {
    history.goBack();
  };

  return (
    <>
      {brand ? (
        <Container maxWidth="xl" style={{ marginTop: '2.5rem' }}>
          <NavBar
            screenTitle="Back to bookings"
            handleIconClick={handleBackButton}
          >
            <ArrowBack />
          </NavBar>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <UserProfileCard
                isBrand={false}
                userName={brand.name}
                userLogo={brand.logo}
                userDescription={brand.description}
                socialnetworks={brand.socialnetworks}
              />
              {brand.locations.map((loc) => (
                <LocationCard
                  key={`loc-${loc.id}`}
                  location={loc}
                  hideContactEmail
                />
              ))}

              <MenusList menus={brand.menus} />
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid container spacing={1} style={{ height: '100%' }}>
                <Card style={{ padding: '1rem' }}>
                  <CardContent>
                    <Typography
                      style={{
                        color: '#606060',
                        fontSize: 24,
                        fontWeight: 700,
                      }}
                    >
                      Featured Images
                    </Typography>

                    <Box className={classes.imagesGrid} mt={2}>
                      <GridList cellHeight={180} cols={2}>
                        {brand.photos.map((image) => (
                          <GridListTile
                            key={`${image.id}-${image.name}`}
                            cols={1}
                          >
                            <img src={image.path} alt={image.name} />
                          </GridListTile>
                        ))}
                      </GridList>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      ) : (
        <Redirect to="/campaigns" />
      )}
    </>
  );
}

export default BrandProfileView;
