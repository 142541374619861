import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { Box, Typography, Icon } from '@material-ui/core';
import InstagramIcon from 'images/icons/instagram.svg';

const useStyles = makeStyles((theme) => ({
  customIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function IconWithLabel({ icon, label }) {
  const classes = useStyles();

  return (
    <Box mb={1} display="flex" alignItems="center">
      <Icon className={classes.customIcon}>
        <img src={icon} alt="Instagram" width={25} height={24} />
      </Icon>

      <Typography
        variant="subtitle2"
        style={{
          color: '#403845',
          fontSize: 24,
          fontWeight: 600,
        }}
      >
        {label}
      </Typography>
    </Box>
  );
}

IconWithLabel.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
};

IconWithLabel.defaultProps = {
  icon: InstagramIcon,
  label: '…',
};
