import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  CircularProgress,
} from '@material-ui/core';

import Rating from 'components/forms/Rating';

export default function RateInfluencer({
  open,
  onClose,
  onCancelClick,
  onSubmitClick,
  influencerName,
  error,
  fetching,
}) {
  const [rating, setRating] = useState(-1);
  const [review, setReview] = useState('');

  const onRatingChange = (val) => {
    setRating(val);
  };

  const onReviewChange = (event) => {
    setReview(event.target.value);
  };

  const handleSubmit = () => {
    onSubmitClick({ rate: rating, review });
    setRating(-1);
    setReview('');
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {influencerName !== ''
          ? `How was ${influencerName}'s visit?`
          : 'How was this visit?'}
      </DialogTitle>

      <DialogContent>
        <Rating onRatingChange={onRatingChange} />

        <TextField
          value={review}
          onChange={onReviewChange}
          id="comments"
          label="Comments"
          color="secondary"
          variant="filled"
          multiline
          rowsMax={3}
          helperText="optional"
        />

        <FormHelperText error>{error}</FormHelperText>
      </DialogContent>

      <DialogActions>
        <Button onClick={onCancelClick} color="primary" disabled={fetching}>
          Cancel
        </Button>

        <Button
          onClick={handleSubmit}
          color="inherit"
          disabled={fetching}
          endIcon={fetching ? <CircularProgress size={18} /> : null}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

RateInfluencer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onCancelClick: PropTypes.func,
  onSubmitClick: PropTypes.func,
  influencerName: PropTypes.string,
  error: PropTypes.string,
  fetching: PropTypes.bool,
};

RateInfluencer.defaultProps = {
  open: false,
  onClose: () => {},
  onCancelClick: () => {},
  onSubmitClick: () => {},
  influencerName: '',
  error: '',
  fetching: false,
};
