import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Campaigns from 'components/screens/Campaigns';
import Bookings from 'components/screens/Bookings';
import TasksGrid from 'components/grids/TasksGrid';
import PrivateRoute from 'PrivateRoute';

import Profile from 'components/screens/Profile';
import GeneralInfoEdit from 'components/screens/BrandProfile/GeneralInfoEdit';
import LocationEdit from 'components/screens/BrandProfile/LocationEdit';
import MenusEdit from 'components/screens/BrandProfile/MenusEdit';
import BrandProfileView from 'components/screens/BrandProfile/BrandProfileView';

import CampaignDetail from 'components/details/CampaignDetail';
import TaskDetail from 'components/details/TaskDetail';

import Signup from 'components/screens/Signup';
import Login from 'components/screens/Login';
import PassReset from 'components/screens/PassReset';

import BookingRequest from 'components/forms/BookingRequest';
import PlanSelect from 'components/screens/PlanSelect';
import Payment from 'components/screens/PlanSelect/Payment';
import CampaignCreate from 'components/forms/CampaignCreate';
import InfluencerProfile from 'components/screens/InfluencerProfile';
import AccountSettings from 'components/screens/AccountSettings';
import PasswordChange from 'components/screens/PassChange';
import Dashboard from 'components/screens/Dashboard';

const Routes = () => (
  <Switch>
    <PrivateRoute exact path="/campaigns/" component={() => <Campaigns />} />
    <PrivateRoute exact path="/bookings/" component={() => <Bookings />} />
    <PrivateRoute exact path="/tasks/" component={() => <TasksGrid />} />
    <PrivateRoute exact path="/profile" component={() => <Profile />} />
    <PrivateRoute exact path="/dashboard" component={() => <Dashboard />} />
    <PrivateRoute
      exact
      path="/profile/edit"
      component={() => <GeneralInfoEdit />}
    />
    <PrivateRoute
      exact
      path="/profile/location"
      component={() => <LocationEdit />}
    />
    <PrivateRoute exact path="/profile/menus" component={() => <MenusEdit />} />
    <PrivateRoute
      exact
      path="/profile/location/:id"
      component={() => <LocationEdit />}
    />
    <PrivateRoute
      exact
      path="/campaigns/:id"
      component={() => <CampaignDetail />}
    />
    <PrivateRoute
      exact
      path="/campaigns/:id/book"
      component={() => <BookingRequest />}
    />
    <PrivateRoute
      exact
      path="/brands/:id"
      component={() => <BrandProfileView />}
    />
    <PrivateRoute exact path="/tasks/:id" component={() => <TaskDetail />} />
    <Route exact path="/signup" component={() => <Signup />} />
    <Route exact path="/login" component={() => <Login />} />
    <Route exact path="/password-reset" component={() => <PassReset />} />
    <PrivateRoute exact path="/plans" component={() => <PlanSelect />} />
    <PrivateRoute exact path="/plans/payment" component={() => <Payment />} />
    <PrivateRoute
      exact
      path="/bookings/:status"
      component={() => <Bookings />}
    />
    <PrivateRoute
      exact
      path="/create/:type"
      component={() => <CampaignCreate />}
    />
    <PrivateRoute
      exact
      path="/edit/campaign/:campaignId"
      component={() => <CampaignCreate isEditing />}
    />
    <PrivateRoute
      exact
      path="/settings"
      component={() => <AccountSettings />}
    />

    <PrivateRoute
      exact
      path="/settings/password-change"
      component={() => <PasswordChange />}
    />

    <Route exact path="/profile/:id" component={() => <InfluencerProfile />} />
    {/* This will redirect any unmatched route, including '/' to login */}
    <Route component={() => <Redirect to="/login" />} />
  </Switch>
);

export default Routes;
