import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import DRAWER_WIDTH from 'constants/navigation';

import {
  AppBar,
  Button,
  Grid,
  Hidden,
  Toolbar,
  IconButton,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  appBar: {
    padding: '1rem 0',
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      marginLeft: DRAWER_WIDTH,
    },
  },
  appBarTitle: {
    color: '#606060',
    flexGrow: 1,
    fontSize: 24,
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  backToBtn: {
    color: '#606060',
    fontSize: 24,
    marginTop: '.6rem',
  },
  bookingsBtn: {
    backgroundColor: '#031873',
    borderRadius: 29,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    color: '#fff',
    fontSize: 18,
    margin: '.2rem .3rem 0 0',
    padding: '1.4rem',
    '&:hover': {
      backgroundColor: '#EF4B4E',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
  },
}));

export default function NavBar({
  handleIconClick,
  screenTitle,
  children,
  isMain,
}) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const handleBackButton = () => {
    return history.goBack();
  };

  const allowedBackToBtn = [
    '/campaigns',
    '/bookings',
    '/profile',
    '/bookings/upcoming',
    '/bookings/past',
    '/dashboard',
  ];

  const backToLabel = () => {
    return ` to Previous Page`;
  };

  return (
    <AppBar
      position="fixed"
      color="default"
      elevation={0}
      className={classes.appBar}
    >
      <Toolbar style={{ width: '100%', padding: '0 2.5rem' }}>
        <Hidden xsDown>
          <Grid container spacing={2} justify="space-between">
            <Grid item xs={12} md={6} style={{ flex: 1 }}>
              {allowedBackToBtn.includes(location.pathname) ? (
                <>
                  <Button
                    className={classes.backToBtn}
                    size="small"
                    onClick={() => handleBackButton()}
                  >
                    <ArrowBackIcon style={{ fontSize: 28 }} />
                    Back
                    {backToLabel()}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    className={classes.backToBtn}
                    size="small"
                    onClick={() => handleBackButton()}
                  >
                    <ArrowBackIcon style={{ fontSize: 28 }} />
                    {screenTitle}
                  </Button>
                </>
              )}
            </Grid>

            {location.pathname === '/campaigns' ? (
              <Grid item xs={12} md={6} style={{ textAlign: 'right' }}>
                <Button
                  className={classes.bookingsBtn}
                  variant="contained"
                  size="large"
                  disableElevation
                  onClick={() => history.push('/bookings')}
                >
                  Pending Requests
                </Button>
              </Grid>
            ) : null}
          </Grid>
        </Hidden>

        <IconButton
          aria-label="open drawer"
          edge="start"
          onClick={handleIconClick}
          className={isMain ? classes.menuButton : ''}
        >
          {children}
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}

NavBar.propTypes = {
  handleIconClick: PropTypes.func,
  screenTitle: PropTypes.string,
  children: PropTypes.node,
  isMain: PropTypes.bool,
};

NavBar.defaultProps = {
  handleIconClick: () => null,
  screenTitle: '',
  children: <></>,
  isMain: false,
};
