import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import { Provider } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import firebase from 'firebase/app';
import 'firebase/analytics';
import firebaseConfig from 'firebaseConfig';

import App from './App';
import * as serviceWorker from './serviceWorker';
import generateStore from './redux/store';

const { REACT_APP_STRIPE_KEY } = process.env;

firebase.initializeApp(firebaseConfig);
firebase.analytics();

// Connect redux with app
const store = generateStore();
const stripePromise = loadStripe(REACT_APP_STRIPE_KEY);

const WithRouter = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

const WithStore = () => (
  <Provider store={store}>
    <WithRouter />
  </Provider>
);

const WithStripe = () => (
  <Elements stripe={stripePromise}>
    <WithStore />
  </Elements>
);

ReactDOM.render(<WithStripe />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
