import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme) => ({
  featureListItem: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingLeft: 0,
    marginBottom: 0,

    '& .MuiListItemIcon-root': {
      minWidth: 32,
      paddingTop: 4,
    },
  },
  condensed: {
    fontSize: '0.875rem',
  },
}));

export default function FeatureList({ featuresData, condensed }) {
  const classes = useStyles();

  return (
    <Box mt={2}>
      {!condensed ? (
        <Typography gutterBottom variant="h5" component="h3" align="center">
          The benefits title
        </Typography>
      ) : null}

      <List aria-label="main mailbox folders">
        {featuresData.map((feature) => (
          <ListItem className={classes.featureListItem} key={feature.text}>
            <ListItemIcon>
              <CheckCircleIcon
                color="disabled"
                fontSize={condensed ? 'small' : 'default'}
              />
            </ListItemIcon>
            <ListItemText
              primary={feature}
              primaryTypographyProps={
                !condensed
                  ? ''
                  : {
                      variant: 'body2',
                    }
              }
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

FeatureList.propTypes = {
  featuresData: PropTypes.arrayOf(PropTypes.string),
  condensed: PropTypes.bool,
};

FeatureList.defaultProps = {
  featuresData: [
    'Great support',
    'Easy to use platform',
    'More features coming soon',
  ],
  condensed: false,
};
