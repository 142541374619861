import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

// Components
import {
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  condensedTableContainer: {
    marginBottom: 0,
  },
  tableTitle: {
    marginLeft: theme.spacing(2),
  },
  condensedRow: {
    '&:last-child td': {
      borderBottom: 0,
    },
  },
  condensedCell: {
    padding: '6px 0',
  },
}));

export default function DataTable({ rows, tableTitle, condensed }) {
  const classes = useStyles();

  return (
    <TableContainer
      className={condensed ? classes.condensedTableContainer : ''}
    >
      <Typography
        className={classes.tableTitle}
        variant="overline"
        component="h3"
      >
        {tableTitle}
      </Typography>

      <Table>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              className={condensed ? classes.condensedRow : ''}
              key={row.head}
            >
              <TableCell
                className={condensed ? classes.condensedCell : ''}
                variant="head"
              >
                {row.head}
              </TableCell>

              <TableCell
                className={condensed ? classes.condensedCell : ''}
                align="right"
              >
                {row.value}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

DataTable.propTypes = {
  tableTitle: PropTypes.string,
  rows: PropTypes.arrayOf(PropTypes.object),
  condensed: PropTypes.bool,
};

DataTable.defaultProps = {
  tableTitle: '',
  rows: [
    {
      head: '',
      value: '',
    },
  ],
  condensed: false,
};
