// routes closed to specified user types
const protectedRoutes = {
  influencer: [
    '/myclients/',
    '/mybrands/',
    '/create/',
    '/plans',
    '/plans/payment',
  ],
  journalist: [
    '/myclients/',
    '/mybrands/',
    '/create/',
    '/plans',
    '/plans/payment',
  ],
  brands: ['/tasks/'],
};

export default protectedRoutes;
