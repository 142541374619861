/* eslint-disable camelcase */

// Some plan copy hardcoded and subject to change on client review
const formatSubscriptionFeatures = (plan) => {
  const { title, price, allowed_locations, allowed_brands } = plan;
  return {
    description: [
      {
        head: 'Plan',
        value: title,
      },
      {
        head: 'Brands',
        value: allowed_brands,
      },
      {
        head: 'Total',
        value: `$${price}/mo`,
      },
    ],
    features: [
      `${allowed_brands} Hospitality Brand`,
      `${allowed_locations} Unique Locations`,
      'Unlimited Campaign Creation',
      'Unlimited Bookings',
      'Onboarding Support',
    ],
  };
};
/* eslint-enablecamelcase */

export default formatSubscriptionFeatures;
