import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Container,
  Card,
  CardContent,
  Box,
  Typography,
  Button,
  TextField,
  Snackbar,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import { updateProfileAction } from 'redux/userDuck';
import { MAX_LENGTH_FIELDS } from 'constants/settings';

function AccountSettings({ sessionData, updateProfile }) {
  const { user, errors, updated } = sessionData || {};
  const apiErrors = errors.profile?.data?.errors || {};
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [values, setValues] = useState({
    name: user.name || '',
    email: user.email || '',
  });

  const history = useHistory();

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const handleSubmit = () => {
    updateProfile({ ...values });
  };

  useEffect(() => {
    if (updated) {
      setOpenSnackbar(true);
    } else {
      setOpenSnackbar(false);
    }
  }, [updated, setOpenSnackbar]);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      <Container maxWidth="sm" style={{ marginTop: '2.5rem' }}>
        <form>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="overline" display="block">
                General Settings
              </Typography>

              <TextField
                fullWidth
                color="secondary"
                id="name"
                name="name"
                label="Name"
                inputProps={{
                  maxLength: MAX_LENGTH_FIELDS.name,
                }}
                type="text"
                variant="filled"
                value={values.name}
                error={!!apiErrors.name}
                helperText={apiErrors.name}
                onChange={handleChange}
              />

              <TextField
                fullWidth
                color="secondary"
                id="email"
                name="email"
                inputProps={{
                  maxLength: MAX_LENGTH_FIELDS.email,
                }}
                label="Account Email"
                type="email"
                variant="filled"
                value={values.email}
                error={!!apiErrors.email}
                helperText={apiErrors.email}
                onChange={handleChange}
              />
            </CardContent>
          </Card>

          <Button
            variant="outlined"
            size="large"
            color="secondary"
            disableElevation
            fullWidth
            onClick={handleSubmit}
          >
            Save changes
          </Button>
        </form>
        <Button
          variant="outlined"
          size="large"
          color="secondary"
          disableElevation
          fullWidth
          onClick={() => history.push('/settings/password-change')}
        >
          Change Password
        </Button>
      </Container>

      <Container maxWidth="sm">
        <Typography variant="h6">Get Support</Typography>

        <Box
          mt={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle2">Email:</Typography>
          <Typography
            variant="body2"
            component="a"
            href="mailto:humans@getpream.com"
            color="secondary"
          >
            humans@getpream.com
          </Typography>
        </Box>
      </Container>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        message="Settings updated!"
      />
    </>
  );
}

AccountSettings.propTypes = {
  sessionData: PropTypes.shape({
    user: PropTypes.object,
    errors: PropTypes.objectOf(PropTypes.object),
  }),
  updateProfile: PropTypes.func,
};

AccountSettings.defaultProps = {
  sessionData: {},
  updateProfile: () => {},
};

const mapStateToProps = (state) => ({
  sessionData: state.sessionData,
});

const mapDispatchToProps = (dispatch) => ({
  updateProfile: (data) => {
    dispatch(updateProfileAction(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings);
