import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import 'App.css';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from 'theme';
import { setAppUserAction } from 'redux/userDuck';

// Components
import Routes from 'Routes';
import MainNav from 'components/nav/MainNav';
import ConditionalWrapper from 'components/utility/ConditionalWrapper';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';

function App({ setAppUser, sessionData }) {
  const { user, loggedIn } = sessionData;
  const location = useLocation();
  const [userRefreshed, setUserRefreshed] = useState(false);

  useEffect(() => {
    // populate local storage with user on app load
    if (user && loggedIn && !userRefreshed) {
      setAppUser();
      setUserRefreshed(true);
    }
  }, [user, loggedIn, userRefreshed, setAppUser, setUserRefreshed]);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <CssBaseline />

        <ConditionalWrapper
          condition={
            location.pathname !== '/login' &&
            location.pathname !== '/signup' &&
            location.pathname !== '/password-reset'
          }
          wrapper={(children) => <MainNav>{children}</MainNav>}
        >
          <>
            <Routes />
          </>
        </ConditionalWrapper>
      </div>
    </ThemeProvider>
  );
}

App.propTypes = {
  setAppUser: PropTypes.func,
  sessionData: PropTypes.objectOf(PropTypes.object),
};

App.defaultProps = {
  setAppUser: () => {},
  sessionData: {},
};

const mapDispatchToProps = (dispatch) => ({
  setAppUser: () => {
    dispatch(setAppUserAction());
  },
});

const mapStateToProps = (state) => ({
  sessionData: state.sessionData,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
