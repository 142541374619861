import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import { capitalize } from 'helpers/helpers';
import { Link } from 'react-router-dom';

// Components
import {
  Grid,
  Divider,
  Card,
  Avatar,
  CardHeader,
  CardContent,
  Typography,
  Box,
  CardActions,
  Button,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  accentText: {
    color: theme.palette.secondary.main,
  },
  dangerButton: {
    color: theme.palette.error.main,
  },
  cardHeaderLink: {
    color: 'inherit',
    textDecoration: 'none',
  },
}));

export default function BookingCard({
  id,
  avatar,
  name,
  subheader,
  date,
  status,
  guests,
  influencer,
  tier,
  isBrand,
  action,
  onAcceptClick,
  onRejectClick,
  onMediaCancelClick,
  onRateClick,
  allowRating,
  campaignName,
  location,
  compensation,
  fullWidth,
}) {
  const classes = useStyles();
  let statusFormatted = capitalize(status);
  if (statusFormatted === 'Finished') {
    statusFormatted = 'Completed';
  }

  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        md={fullWidth ? 12 : 6}
        lg={fullWidth ? 12 : 4}
      >
        <Card>
          {(name && isBrand) || !isBrand ? (
            <>
              {isBrand ? (
                <CardHeader
                  className={classes.cardHeaderLink}
                  component={Link}
                  to={`/profile/${influencer}`}
                  avatar={<Avatar alt={name} src={avatar} />}
                  title={name}
                  subheader={subheader}
                />
              ) : (
                <CardHeader
                  className={classes.cardHeaderLink}
                  avatar={<Avatar alt={name} src={avatar} />}
                  title={name}
                  subheader={subheader}
                />
              )}

              <Divider />
            </>
          ) : null}

          <CardContent>
            <Typography variant="h6" component="h2">
              {dayjs(date).format('MM/DD/YY')}
              <span className={classes.accentText}> @ </span>
              {dayjs(date).format('h:mmA')}
            </Typography>

            <Box
              display="flex"
              flexDirection={status === 'pending' || !status ? 'column' : ''}
            >
              <Typography variant="subtitle2" component="h3" gutterBottom>
                {`${guests} guest${guests > 1 ? 's' : ''}`}
                {tier ? ` / Tier ${tier}` : ''}
                {compensation ? ` / $ ${compensation}` : ''}
              </Typography>

              {campaignName ? (
                <Typography variant="subtitle1" component="h2">
                  {campaignName || ''}
                </Typography>
              ) : null}

              <Typography variant="subtitle2" component="h3">
                {location || ''}
              </Typography>

              {statusFormatted !== '' && status !== 'pending' ? (
                <Typography
                  variant="subtitle2"
                  component="h3"
                  color={
                    statusFormatted === 'Declined' ||
                    statusFormatted === 'Cancelled'
                      ? 'error'
                      : 'inherit'
                  }
                >
                  &nbsp;
                  {`/ ${statusFormatted}`}
                </Typography>
              ) : null}
            </Box>
          </CardContent>

          {!isBrand && (status === 'accepted' || status === 'pending') ? (
            <CardActions>
              <Button
                className={classes.dangerButton}
                size="small"
                onClick={() => onMediaCancelClick(id, date)}
              >
                Cancel
              </Button>
            </CardActions>
          ) : null}

          {isBrand &&
            {
              accept: (
                <CardActions>
                  <Button size="small" onClick={() => onAcceptClick(id)}>
                    Accept
                  </Button>

                  <Button
                    className={classes.dangerButton}
                    size="small"
                    onClick={() => onRejectClick(id)}
                  >
                    Reject
                  </Button>
                </CardActions>
              ),

              rate: allowRating ? (
                <CardActions>
                  <Button size="small" onClick={onRateClick}>
                    Rate Visit
                  </Button>
                </CardActions>
              ) : null,
            }[action]}
        </Card>
      </Grid>
    </>
  );
}

BookingCard.propTypes = {
  id: PropTypes.number,
  avatar: PropTypes.string,
  name: PropTypes.string,
  subheader: PropTypes.string,
  date: PropTypes.string,
  status: PropTypes.string,
  guests: PropTypes.number,
  tier: PropTypes.number,
  influencer: PropTypes.objectOf(PropTypes.object),
  isBrand: PropTypes.bool,
  action: PropTypes.string,
  allowRating: PropTypes.bool,
  onAcceptClick: PropTypes.func,
  onRejectClick: PropTypes.func,
  onMediaCancelClick: PropTypes.func,
  onRateClick: PropTypes.func,
  campaignName: PropTypes.string,
  location: PropTypes.string,
  compensation: PropTypes.number,
  fullWidth: PropTypes.bool,
};

BookingCard.defaultProps = {
  id: 0,
  avatar: 'P',
  name: '',
  subheader: '',
  date: '',
  status: '',
  guests: 1,
  tier: null,
  influencer: {},
  isBrand: false,
  action: '',
  allowRating: true,
  campaignName: null,
  location: null,
  compensation: null,
  fullWidth: false,
  onAcceptClick: () => {},
  onRejectClick: () => {},
  onMediaCancelClick: () => {},
  onRateClick: () => {},
};
