import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

// Components
import { GridList, GridListTile, Box } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  gridList: {
    '@media (max-width: 500px)': {
      width: 350,
    },
    '@media (max-width: 320px)': {
      width: 296,
    },
  },
  // important is needed to override inline styles generated by MUI
  gridListTile: {
    height: '94px !important',
  },
  gridListTileFeatured: {
    height: '174px !important',
  },
});

export default function ImageGrid({ images }) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <GridList className={classes.gridList} cols={12} spacing={8}>
        {images.map((image) => (
          <GridListTile
            key={image.title}
            cols={image.cols || 1}
            classes={classes.gridListTileFeatured}
          >
            <img src={image.src} alt={image.title} />
          </GridListTile>
        ))}
      </GridList>
    </Box>
  );
}

ImageGrid.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object),
};

ImageGrid.defaultProps = {
  images: [
    {
      img: '/placeholder-image.png',
      cols: 3,
      title: 'Image',
      featured: true,
    },
  ],
};
