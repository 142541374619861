import React, { useEffect, useState } from 'react';

import {
  Container,
  Card,
  CardContent,
  FormHelperText,
  Typography,
  Button,
  Snackbar,
  FormControl,
  InputLabel,
  FilledInput,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import { updateProfileAction } from 'redux/userDuck';
import { MAX_LENGTH_FIELDS } from 'constants/settings';

function PassChange({ sessionData, updateProfile }) {
  const { user, updated } = sessionData || {};
  const [formErrors, setFormErrors] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [passwords, setPasswords] = useState({
    new: {
      val: '',
      show: false,
    },
    confirm: {
      val: '',
      show: false,
    },
  });

  const handlePasswordChange = (event) => {
    const formField = event.target.name;
    const newVal = event.target.value;
    // If new pw deleted remove all pw errors
    if (!newVal) setFormErrors({ ...formErrors, new: '', confirm: '' });
    // If new pw has more than 6 chars remove all errors
    if (formField === 'new' && newVal.length >= 6)
      setFormErrors({ ...formErrors, new: '' });
    setPasswords({
      ...passwords,
      [formField]: { ...passwords[formField], val: newVal },
    });
  };

  const handleClickShowPassword = (event) => {
    setPasswords({
      ...passwords,
      [event.currentTarget.name]: {
        ...passwords[event.currentTarget.name],
        show: !passwords[event.currentTarget.name].show,
      },
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validatePassFields = () => {
    const pwErrors = {};
    // If only one pw entered, show error on the other
    if (passwords.new.val && !passwords.confirm.val) {
      pwErrors.confirm = 'This field is required';
    } else if (passwords.confirm.val && !passwords.new.val) {
      pwErrors.new = 'This field is required';
    } else if (!passwords.confirm.val && !passwords.new.val) {
      pwErrors.confirm = 'This field is required';
      pwErrors.new = 'This field is required';
    }
    // If confirm pw does not match new pw, show error
    if (passwords.confirm.val && passwords.confirm.val !== passwords.new.val) {
      pwErrors.confirm = 'Passwords must match.';
    }
    // If new pw more than 1 char and fewer than 6, show char length error
    if (passwords.new.val.length > 0 && passwords.new.val.length < 6) {
      pwErrors.new = 'The password must be at least six characters.';
    }
    setFormErrors({ ...formErrors, ...pwErrors });
    return Object.keys(pwErrors).length === 0;
  };

  const handleSubmit = () => {
    setFormErrors({});
    if (validatePassFields()) {
      const { email } = user;
      const newPass = passwords.new.val;
      updateProfile({ password: newPass, email });
    }
  };

  useEffect(() => {
    if (updated) {
      setOpenSnackbar(true);
    } else {
      setOpenSnackbar(false);
    }
  }, [updated, setOpenSnackbar]);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      <Container maxWidth="sm" style={{ marginTop: '2.5rem' }}>
        <form>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="overline" display="block">
                Change password
              </Typography>

              <FormControl variant="filled" color="secondary">
                <InputLabel htmlFor="new-pass">New Password</InputLabel>

                <FilledInput
                  id="new-password"
                  name="new"
                  type={passwords.new.show ? 'text' : 'password'}
                  value={passwords.new.val}
                  onChange={handlePasswordChange}
                  inputProps={{
                    maxLength: MAX_LENGTH_FIELDS.password,
                  }}
                  error={formErrors.new}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        name="new"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {passwords.new.show ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText>
                  {formErrors.new ||
                    'The password must be at least six characters.'}
                </FormHelperText>
              </FormControl>

              <FormControl variant="filled" color="secondary">
                <InputLabel htmlFor="new-pass">Confirm Password</InputLabel>

                <FilledInput
                  id="confirm-password"
                  name="confirm"
                  type={passwords.confirm.show ? 'text' : 'password'}
                  value={passwords.confirm.val}
                  onChange={handlePasswordChange}
                  error={!!formErrors.confirm}
                  inputProps={{
                    maxLength: MAX_LENGTH_FIELDS.password,
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        name="confirm"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {passwords.confirm.show ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText>{formErrors.confirm}</FormHelperText>
              </FormControl>
            </CardContent>
          </Card>

          <Button
            variant="outlined"
            size="large"
            color="secondary"
            disableElevation
            fullWidth
            onClick={handleSubmit}
          >
            Change Password
          </Button>
        </form>
      </Container>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        message="Settings updated!"
      />
    </>
  );
}

PassChange.propTypes = {
  sessionData: PropTypes.shape({
    user: PropTypes.object,
    errors: PropTypes.objectOf(PropTypes.object),
  }),
  updateProfile: PropTypes.func,
};

PassChange.defaultProps = {
  sessionData: {},
  updateProfile: () => {},
};

const mapStateToProps = (state) => ({
  sessionData: state.sessionData,
});

const mapDispatchToProps = (dispatch) => ({
  updateProfile: (data) => {
    dispatch(updateProfileAction(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PassChange);
