import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { Box, Avatar, Button, FormHelperText } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  fileUpload: {
    display: 'none',
  },
  fileUploadButton: {
    marginLeft: theme.spacing(1),
  },
}));

export default function AvatarUpload({ avatar, onChange, alt, error }) {
  const classes = useStyles();
  const [file, setFile] = useState(avatar);

  const handleFileChange = (event) => {
    setFile(URL.createObjectURL(event.target.files[0]));
    onChange(event.target.files[0]);
  };

  return (
    <Box display="flex" mb={3}>
      <Avatar src={file} alt={alt} />

      <label htmlFor="file-upload">
        <input
          accept="image/png, image/jpeg, image/svg+xml"
          className={classes.fileUpload}
          id="file-upload"
          type="file"
          onChange={handleFileChange}
        />
        <Button className={classes.fileUploadButton} component="span">
          Upload Profile Photo
        </Button>
      </label>
      <FormHelperText error>{error !== '' ? error : null}</FormHelperText>
    </Box>
  );
}

AvatarUpload.propTypes = {
  avatar: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  alt: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string,
};

AvatarUpload.defaultProps = {
  avatar: '',
  alt: '',
  onChange: () => {},
  error: '',
};
