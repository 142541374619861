import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  getCampaignDetailsAction,
  sendMessageToBrandAction,
} from 'redux/campaignDuck';
import { formatCampaignDetails } from 'helpers/campaigns';

// Components
import {
  Grid,
  Avatar,
  CardHeader,
  Typography,
  Button,
  Container,
  IconButton,
  CardActionArea,
  Box,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import NavBar from 'components/nav/NavBar';
import ImageGrid from 'components/lists/ImageGrid';
import DataTable from 'components/lists/DataTable';
import ContactBrand from 'components/forms/ContactBrand/ContactBrand';

import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';

// Analytics
import firebase from 'firebase/app';
import 'firebase/analytics';

const useStyles = makeStyles((theme) => ({
  infoContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

/* eslint-disable camelcase */
function CampaignDetail({
  sessionData,
  campaignData,
  getCampaignDetails,
  sendMessageToBrand,
}) {
  const analytics = firebase.analytics();
  const { id } = useParams();
  const { user } = sessionData || {};
  const userType = user.profile?.type;
  const { campaignDetails } = campaignData;
  const { brand } = campaignDetails || {};
  const formattedData = campaignDetails
    ? formatCampaignDetails(campaignDetails)
    : {};

  const [modalOpen, setModalOpen] = useState(false);

  const handleClickOpen = () => {
    setModalOpen(!modalOpen);
  };

  const userId = user.id;
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    getCampaignDetails(userId, id, userType);
  }, [getCampaignDetails, userId, id, userType]);

  useEffect(() => {
    analytics.logEvent('campaign_view', {
      campaign_name: campaignDetails?.name,
      campaign_type: formattedData?.campaignType,
    });
  }, [analytics, campaignDetails, formattedData]);

  const handleBackButton = () => {
    history.goBack();
  };

  const handleClick = () => {
    const nextPath =
      userType === 'brands' ? '/bookings' : `/campaigns/${id}/book`;
    history.push(nextPath);
  };

  const sendMessage = (message) => {
    sendMessageToBrand(userId, campaignDetails?.brand_id, message);
    handleClickOpen();
  };

  const handleHeaderClick = () => history.push(`/brands/${brand.id}`, brand);

  return (
    <>
      <NavBar
        screenTitle={<Typography variant="h5">BACK TO CAMPAIGNS</Typography>}
        handleIconClick={handleBackButton}
      >
        <ArrowBack fontSize="small" />
      </NavBar>

      <Container maxWidth="lg" style={{ marginTop: '2.5rem' }}>
        {campaignDetails && formattedData ? (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <CardActionArea onClick={handleHeaderClick}>
                <CardHeader
                  avatar={<Avatar alt={brand.name} src={brand.logo} />}
                  title={brand.name}
                  subheader={brand.brandType}
                  action={
                    <IconButton aria-label="settings">
                      <ChevronRightIcon />
                    </IconButton>
                  }
                />
              </CardActionArea>

              <ImageGrid images={formattedData.imgArray} />

              <Box mt={1}>
                <Typography variant="caption">
                  Right-click to download and save image
                </Typography>
              </Box>

              <Container className={classes.infoContainer}>
                <Typography gutterBottom variant="h5" component="h1">
                  {campaignDetails.name}
                </Typography>

                <Typography variant="subtitle2" component="h2">
                  {formattedData.campaignType}
                </Typography>

                <Typography variant="body1" color="textPrimary" component="p">
                  {campaignDetails.description}
                </Typography>
              </Container>
            </Grid>

            <Grid item xs={12} md={6}>
              {formattedData.generalInfo.length > 0 ? (
                <DataTable
                  tableTitle="General"
                  rows={formattedData.generalInfo}
                />
              ) : null}
              {userType !== 'journalist' &&
              formattedData.requirements.length > 0 ? (
                <DataTable
                  tableTitle="Requirements"
                  rows={formattedData.requirements}
                />
              ) : null}

              <Button
                variant="contained"
                size="large"
                color="secondary"
                disableElevation
                fullWidth
                onClick={handleClick}
              >
                {userType === 'brands' ? 'See Bookings' : 'Request a booking'}
              </Button>

              {userType === 'journalist' ? (
                <Button
                  size="large"
                  color="secondary"
                  disableElevation
                  fullWidth
                  variant="outlined"
                  onClick={handleClickOpen}
                >
                  Contact Brand
                </Button>
              ) : null}
              <ContactBrand
                open={modalOpen}
                handleClose={handleClickOpen}
                sendMessage={sendMessage}
              />
            </Grid>
          </Grid>
        ) : null}
      </Container>
    </>
  );
}

CampaignDetail.propTypes = {
  getCampaignDetails: PropTypes.func,
  campaignData: PropTypes.shape({
    fetching: PropTypes.bool,
    campaignDetails: PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
    }),
  }),
  sessionData: PropTypes.shape({ loggedIn: PropTypes.bool }),
  sendMessageToBrand: PropTypes.func,
};

CampaignDetail.defaultProps = {
  getCampaignDetails: () => {},
  sendMessageToBrand: () => {},
  campaignData: {},
  sessionData: {},
};

const mapStateToProps = (state) => ({
  campaignData: state.campaignData,
  sessionData: state.sessionData,
});

const mapDispatchToProps = (dispatch) => ({
  getCampaignDetails: (userId, campaignId, userType) => {
    dispatch(getCampaignDetailsAction(userId, campaignId, userType));
  },
  sendMessageToBrand: (userId, brandId, message) => {
    dispatch(sendMessageToBrandAction(userId, brandId, message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignDetail);
