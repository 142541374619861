import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import connect from 'react-redux/es/connect/connect';
import { Redirect } from 'react-router-dom';

import {
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Box,
  FormHelperText,
} from '@material-ui/core';

import {
  Visibility,
  VisibilityOff,
  SentimentVerySatisfiedOutlined,
} from '@material-ui/icons';
import PricingCard from 'components/cards/PricingCard';

import PropTypes from 'prop-types';
import { signupBrandAction } from 'redux/userDuck';
import { getSubscriptionPlansAction } from 'redux/subscriptionDuck';
import { MAX_LENGTH_FIELDS } from 'constants/settings';

// Analytics
import firebase from 'firebase/app';
import 'firebase/analytics';

const useStyles = makeStyles({
  brandForm: {
    marginTop: 40,
    marginBottom: 40,
  },
});

/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/no-unescaped-entities */
function BrandSignup({ sessionData, onSubmit, subscriptionData, getPlan }) {
  const analytics = firebase.analytics();
  const { loggedIn, user, errors } = sessionData;
  const classes = useStyles();
  const plan =
    subscriptionData?.plans?.data?.length > 0
      ? subscriptionData.plans.data[0]
      : {};
  const [formErrors, setFormErrors] = useState({});
  const [values, setValues] = useState({
    name: '',
    email: '',
    address: '',
    phone: '',
    zip: '',
    password: '',
    showPassword: false,
  });

  useEffect(() => {
    getPlan();
  }, [getPlan]);

  const handleChange = (prop) => (event) => {
    const newVal = event.target.value;
    if (newVal === '') {
      setFormErrors({
        ...formErrors,
        [prop]: 'This field is required.',
      });
    } else if (prop === 'zip' && !parseInt(newVal, 10)) {
      setFormErrors({
        ...formErrors,
        [prop]: 'Zip code must be a number.',
      });
    } else {
      setFormErrors({ ...formErrors, [prop]: '' });
    }
    setValues({ ...values, [prop]: newVal });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleSubmit = () => {
    setFormErrors({});
    onSubmit(values);

    analytics.logEvent('brand_sign_up');
  };

  const validateFields = () => {
    let formHasErrors = false;
    Object.keys(values).forEach((key) => {
      // check for empty fields
      if (values[key] === '') {
        formHasErrors = true;
        setFormErrors((prevState) => ({
          ...prevState,
          [key]: 'This field is required.',
        }));
      }
    });
    if (!formHasErrors) handleSubmit();
  };

  return (
    <>
      {loggedIn && user.profile?.type === 'brands' ? (
        <Redirect to="/profile" />
      ) : null}
      <Box pb={2}>
        <Typography gutterBottom variant="h4" component="h2" align="center">
          SIGN UP AS A BRAND
        </Typography>

        <Typography gutterBottom variant="body1" component="h3" align="center">
          Great to have you here!
        </Typography>

        <form className={classes.brandForm}>
          <Typography gutterBottom variant="h6" component="h3" align="center">
            Brand Information
          </Typography>

          <TextField
            onChange={handleChange('name')}
            inputProps={{
              maxLength: MAX_LENGTH_FIELDS.name,
            }}
            autoComplete="off"
            fullWidth
            color="secondary"
            id="name"
            label="Brand Name"
            variant="filled"
            error={Boolean(formErrors.name) || Boolean(errors.name)}
            helperText={formErrors.name || errors.name}
          />

          <TextField
            onChange={handleChange('address')}
            autoComplete="off"
            fullWidth
            color="secondary"
            inputProps={{
              maxLength: MAX_LENGTH_FIELDS.address,
            }}
            id="address"
            label="Street Address"
            variant="filled"
            error={Boolean(formErrors.address) || Boolean(errors.address)}
            helperText={formErrors.address || errors.address}
          />

          <TextField
            onChange={handleChange('zip')}
            autoComplete="off"
            fullWidth
            color="secondary"
            id="zipcode"
            label="Zip Code"
            variant="filled"
            inputProps={{
              maxLength: MAX_LENGTH_FIELDS.zip,
            }}
            error={Boolean(formErrors.zip) || Boolean(errors.zip)}
            helperText={formErrors.zip || errors.zip}
          />

          <TextField
            onChange={handleChange('phone')}
            autoComplete="off"
            fullWidth
            color="secondary"
            inputProps={{
              maxLength: MAX_LENGTH_FIELDS.phone,
            }}
            id="number"
            label="Phone Number"
            variant="filled"
            error={Boolean(formErrors.phone) || Boolean(errors.phone)}
            helperText={formErrors.phone || errors.phone}
          />

          <Typography gutterBottom variant="h6" component="h3" align="center">
            Account Information
          </Typography>

          <TextField
            onChange={handleChange('email')}
            autoComplete="off"
            fullWidth
            color="secondary"
            id="email"
            inputProps={{
              maxLength: MAX_LENGTH_FIELDS.email,
            }}
            label="Your Email"
            variant="filled"
            type="email"
            error={Boolean(formErrors.email) || Boolean(errors.email)}
            helperText={formErrors.email || errors.email}
          />

          <TextField
            fullWidth
            autoComplete="off"
            color="secondary"
            label="Password"
            id="password"
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            onChange={handleChange('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {' '}
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            variant="filled"
            inputProps={{
              maxLength: MAX_LENGTH_FIELDS.password,
            }}
            error={Boolean(formErrors.password) || Boolean(errors.password)}
            helperText={formErrors.password || errors.password}
          />

          <Button
            onClick={validateFields}
            variant="contained"
            size="large"
            color="secondary"
            disableElevation
            fullWidth
          >
            Create Account
          </Button>

          <FormHelperText>
            By tapping “Create Account”, you agree to Pream’s Terms of Services
            and Privacy Policy
          </FormHelperText>
        </form>

        <Typography gutterBottom variant="h5" component="h3" align="center">
          Simple Pricing For All
        </Typography>

        <Typography gutterBottom variant="body1" component="h4" align="center">
          We won't ask for your billing information until you create your first
          campaign.
        </Typography>

        <PricingCard planName={plan.title} planPrice={plan.price}>
          <SentimentVerySatisfiedOutlined />
        </PricingCard>
      </Box>
    </>
  );
}

BrandSignup.propTypes = {
  onSubmit: PropTypes.func,
  getPlan: PropTypes.func,
  sessionData: PropTypes.objectOf(PropTypes.object),
  subscriptionData: PropTypes.objectOf(PropTypes.object),
};

BrandSignup.defaultProps = {
  onSubmit: () => {},
  getPlan: () => {},
  sessionData: {},
  subscriptionData: {},
};

// map redux state to props of component
const mapStateToProps = (state) => ({
  sessionData: state.sessionData,
  subscriptionData: state.subscriptionData,
});

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (values) => {
    dispatch(signupBrandAction(values));
  },
  getPlan: () => {
    dispatch(getSubscriptionPlansAction());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BrandSignup);
