// A duck is a Redux Reducer Bundle (info: https://github.com/erikras/ducks-modular-redux)
import API from 'api/Api';
import { setAppUserAction } from './userDuck';

// constants
const initialData = {
  fetching: false,
  plans: {
    data: {},
    errors: {},
  },
  subscription: {
    data: {},
    errors: {},
  },
};

const GET_PLANS = 'GET_PLANS';
const GET_PLANS_SUCCESS = 'GET_PLANS_SUCCESS';
const GET_PLANS_ERROR = 'GET_PLANS_ERROR';

const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION';
const CREATE_SUBSCRIPTION_SUCCESS = 'CREATE_SUBSCRIPTION_SUCCESS';
const CREATE_SUBSCRIPTION_ERROR = 'CREATE_SUBSCRIPTION_ERROR';

// reducer
export default function reducer(state = initialData, action) {
  switch (action.type) {
    case GET_PLANS:
      return { ...state, fetching: true };
    case GET_PLANS_SUCCESS:
      return {
        ...state,
        fetching: false,
        plans: {
          data: action.payload.products,
        },
      };
    case GET_PLANS_ERROR:
      return {
        ...state,
        fetching: false,
        plans: {
          errors: action.payload,
        },
      };
    case CREATE_SUBSCRIPTION:
      return {
        ...state,
        fetching: true,
        subscription: { ...state.subscription, errors: {} },
      };
    case CREATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        fetching: false,
        subscription: {
          data: action.payload,
        },
      };
    case CREATE_SUBSCRIPTION_ERROR:
      return {
        ...state,
        fetching: false,
        subscription: {
          errors: action.payload,
        },
      };
    default:
      return state;
  }
}

// action creators
export const createSubscriptionAction = (purchase) => (dispatch) => {
  dispatch({
    type: CREATE_SUBSCRIPTION,
  });
  API.BRANDS.createSubscription(purchase)
    .then((res) => {
      dispatch({
        type: CREATE_SUBSCRIPTION_SUCCESS,
        payload: res.data,
      });
      dispatch(setAppUserAction());
    })
    .catch((e) => {
      dispatch({
        type: CREATE_SUBSCRIPTION_ERROR,
        payload: e.response,
      });
    });
};

export const getSubscriptionPlansAction = () => (dispatch) => {
  dispatch({
    type: GET_PLANS,
  });
  API.BRANDS.getSubscriptionPlans()
    .then((res) => {
      dispatch({
        type: GET_PLANS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: GET_PLANS_ERROR,
        payload: e.response,
      });
    });
};
