import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { getUserAction } from 'redux/userDuck';
import { capitalize, formatSocialNetwork } from 'helpers/helpers';

import {
  Container,
  Box,
  Typography,
  Card,
  CardContent,
  Avatar,
  Chip,
  CardHeader,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import NavBar from 'components/nav/NavBar';
import IconWithLabel from 'components/lists/IconWithLabel';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  cardLink: {
    color: 'inherit',
    textDecoration: 'none',
  },
}));

/* eslint-disable camelcase */
function InfluencerProfile({ getProfile, sessionData }) {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const profileData = sessionData?.profileUser?.data;

  useEffect(() => {
    if (id && !profileData.name) getProfile(id);
  }, [getProfile, id, profileData.name]);

  const handleBackButton = () => {
    history.goBack();
  };
  return (
    <>
      <NavBar screenTitle="Profile" handleIconClick={handleBackButton}>
        <CloseIcon />
      </NavBar>

      <Container maxWidth="sm">
        <Card>
          <CardContent>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={1}
              pr={2}
            >
              <CardHeader
                avatar={
                  <Avatar
                    alt={profileData.name || ''}
                    src={profileData.profile_picture || ''}
                  />
                }
                title={profileData.name || ''}
                subheader={capitalize(profileData.profile?.type)}
              />
              <Chip
                label={`Tier ${profileData.profile?.tier_range}` || ''}
                variant="outline"
                size="small"
                color="primary"
              />
            </Box>

            <Typography gutterBottom variant="body2">
              {profileData.profile?.bio || ''}
            </Typography>
            {profileData.profile?.socialnetworks.map((network) => {
              const formatted = formatSocialNetwork(network);
              if (!network.handle) return null;
              return (
                <a href={formatted.link} className={classes.cardLink}>
                  <IconWithLabel
                    icon={formatted.icon || ''}
                    label={formatted.handle || ''}
                  />
                </a>
              );
            })}
          </CardContent>
        </Card>
        {profileData.profile?.location ? (
          <Card>
            <CardContent>
              <Typography gutterBottom variant="overline">
                Location
              </Typography>

              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mt={2}
                mb={1}
              >
                <Typography variant="subtitle2">City</Typography>
                <Typography variant="body2">
                  {profileData.profile?.location}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        ) : null}
      </Container>
    </>
  );
}

InfluencerProfile.propTypes = {
  getProfile: PropTypes.func,
  sessionData: PropTypes.shape({
    profileUser: PropTypes.objectOf(PropTypes.object),
  }),
};

InfluencerProfile.defaultProps = {
  getProfile: () => {},
  sessionData: {},
};

const mapStateToProps = (state) => ({
  sessionData: state.sessionData,
});

const mapDispatchToProps = (dispatch) => ({
  getProfile: (id) => {
    dispatch(getUserAction(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InfluencerProfile);
