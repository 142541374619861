import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';

import {
  Container,
  Grid,
  Box,
  Typography,
  TextField,
  Button,
} from '@material-ui/core';

import { ArrowBack } from '@material-ui/icons';
import MarketingNav from 'components/nav/MarketingNav';
import {
  forgotPasswordAction,
  clearForgotPasswordAction,
} from 'redux/userDuck';
import ActionModal from 'components/feedback/ActionModal';
import { MAX_LENGTH_FIELDS } from 'constants/settings';

const useStyles = makeStyles({
  passResetForm: {
    height: '90vh',
  },
});

function PassReset({ onSubmit, sessionData, clearMessage }) {
  const classes = useStyles();
  const history = useHistory();

  const handleBackButton = () => {
    history.push('/login');
  };

  const [email, setEmail] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  const resetPassword = () => {
    onSubmit(email);
  };

  const handleConfirmAction = () => {
    setModalOpen(!modalOpen);
    clearMessage();
  };

  useEffect(() => {
    if (sessionData?.user?.message) {
      setModalOpen(true);
    }
  }, [sessionData]);

  return (
    <>
      <MarketingNav screenTitle="Login" handleClick={handleBackButton}>
        <ArrowBack />
      </MarketingNav>

      <Box
        className={classes.passResetForm}
        display="flex"
        alignItems="center"
        alignContent="center"
      >
        <Container maxWidth="xs">
          <Typography gutterBottom variant="h4" component="h2" align="center">
            Forgot your password?
          </Typography>

          <Typography
            gutterBottom
            variant="body1"
            component="h3"
            align="center"
          >
            We’ll help you reset it.
          </Typography>

          <Box mt={4}>
            <form>
              <TextField
                autoComplete="off"
                inputProps={{
                  maxLength: MAX_LENGTH_FIELDS.email,
                }}
                fullWidth
                color="secondary"
                id="email"
                label="Email"
                variant="filled"
                type="email"
                onChange={(event) => setEmail(event.target.value)}
                error={Boolean(sessionData?.errors?.email)}
                helperText={
                  sessionData?.errors?.email
                    ? sessionData?.errors?.email[0]
                    : ''
                }
              />

              <Button
                variant="contained"
                size="large"
                color="secondary"
                disableElevation
                fullWidth
                onClick={resetPassword}
                disabled={email === ''}
              >
                Reset my password
              </Button>
            </form>
          </Box>

          <ActionModal
            open={modalOpen}
            title="Success!"
            description="We sent you an email with a temporary password."
            label="OK"
            onClick={handleConfirmAction}
          />

          <Grid container justify="center">
            <Button size="small" color="secondary" href="/login">
              Back to log in
            </Button>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

PassReset.propTypes = {
  onSubmit: PropTypes.func,
  clearMessage: PropTypes.func,
  sessionData: PropTypes.objectOf(PropTypes.object),
};

PassReset.defaultProps = {
  onSubmit: () => {},
  clearMessage: () => {},
  sessionData: {},
};

// map redux state to props of component
const mapStateToProps = (state) => ({
  sessionData: state.sessionData,
});

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (email) => {
    dispatch(forgotPasswordAction(email));
  },
  clearMessage: () => {
    dispatch(clearForgotPasswordAction());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PassReset);
