import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { Box, IconButton, Typography } from '@material-ui/core';

import Divider from '@material-ui/core/Divider';
import LaunchIcon from '@material-ui/icons/Launch';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(() => ({
  condensed: {
    fontSize: '0.875rem',
  },
  large: {
    fontSize: '1.2rem',
  },
}));

const ListAction = ({ items, type, handleDelete }) => {
  const classes = useStyles();
  const handleOpen = (menu) => window.open(menu, '_blank');

  return (
    <>
      {items.map((item, index, array) => (
        <div key={item.name}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography className={classes.condensed}>{item.name}</Typography>
            <IconButton
              aria-label="edit-menus"
              onClick={
                type === 'launch'
                  ? () => handleOpen(item.path)
                  : () => handleDelete(item.path)
              }
            >
              {type === 'launch' ? (
                <LaunchIcon className={classes.condensed} />
              ) : (
                <DeleteIcon color="primary" className={classes.large} />
              )}
            </IconButton>
          </Box>
          {array[index + 1] ? <Divider /> : null}
        </div>
      ))}
    </>
  );
};

ListAction.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  type: PropTypes.string,
  handleDelete: PropTypes.func,
};

ListAction.defaultProps = {
  items: [],
  type: '',
  handleDelete: () => {},
};

export default ListAction;
