import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dangerButton: {
    color: theme.palette.error.dark,
  },
}));

export default function ContactBrand({ open, handleClose, sendMessage }) {
  const classes = useStyles();
  const [message, setMessage] = useState('');

  const handleChange = (event) => setMessage(event.target.value);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        aria-describedby="form-dialog-description"
      >
        <DialogTitle id="form-dialog-title">Contact</DialogTitle>
        <DialogContent>
          <DialogContentText id="form-modal-description">
            Please enter your message to the brand. They will contact you as
            soon as they can.
          </DialogContentText>

          <TextField
            fullWidth
            color="secondary"
            id="message"
            label="Message"
            multiline
            rows={3}
            variant="filled"
            name="message"
            required
            error={message === ''}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button className={classes.dangerButton} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={() => sendMessage(message)}
            disabled={message === ''}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ContactBrand.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  sendMessage: PropTypes.func.isRequired,
};
