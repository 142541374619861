// A duck is a Redux Reducer Bundle (info: https://github.com/erikras/ducks-modular-redux)
import API from 'api/Api';

// constants
const initialData = {
  fetching: false,
  updated: '',
  ratingUpdated: '',
  influencerRatingUpdated: '',
  errors: {},
  activeTask: {},
  tasks: {
    incomplete: {
      data: [],
      pages: 0,
      errors: {},
    },
  },
};

const GET_INFLUENCER_TASK = 'GET_INFLUENCER_TASK';
const GET_INFLUENCER_TASK_SUCCESS = 'GET_INFLUENCER_TASK_SUCCESS';
const GET_INFLUENCER_TASK_ERROR = 'GET_INFLUENCER_TASK_ERROR';

const UPDATE_INFLUENCER_TASK = 'UPDATE_INFLUENCER_TASK';
const UPDATE_INFLUENCER_TASK_SUCCESS = 'UPDATE_INFLUENCER_TASK_SUCCESS';
const UPDATE_INFLUENCER_TASK_ERROR = 'UPDATE_INFLUENCER_TASK_ERROR';

const GET_INFLUENCER_TASKS = 'GET_INFLUENCER_TASKS';
const GET_INFLUENCER_TASKS_SUCCESS = 'GET_INFLUENCER_TASKS_SUCCESS';
const GET_INFLUENCER_TASKS_ERROR = 'GET_INFLUENCER_TASKS_ERROR';

const RATE_EXPERIENCE = 'RATE_EXPERIENCE';
const RATE_EXPERIENCE_SUCCESS = 'RATE_EXPERIENCE_SUCCESS';
const RATE_EXPERIENCE_ERROR = 'RATE_EXPERIENCE_ERROR';

const RATE_INFLUENCER = 'RATE_INFLUENCER';
const RATE_INFLUENCER_SUCCESS = 'RATE_INFLUENCER_SUCCESS';
const RATE_INFLUENCER_ERROR = 'RATE_INFLUENCER_ERROR';

const CLEAR_TASK_DATA = 'CLEAR_TASK_DATA';

// reducer
export default function reducer(state = initialData, action) {
  let tasks;
  let newTasks;
  let updatedTask;

  switch (action.type) {
    case GET_INFLUENCER_TASK:
      return {
        ...state,
        fetching: true,
        errors: {},
      };

    case GET_INFLUENCER_TASK_SUCCESS:
      return {
        ...state,
        fetching: false,
        activeTask: action.payload,
      };

    case GET_INFLUENCER_TASK_ERROR:
      return {
        ...state,
        fetching: false,
        errors: action.payload,
      };

    case UPDATE_INFLUENCER_TASK:
      return { ...state, updated: '' };

    case UPDATE_INFLUENCER_TASK_SUCCESS:
      tasks = state.tasks;
      updatedTask = action.payload;

      // Remove it from incomplete status if it is now complete
      if (updatedTask.booking.task.completed === 1) {
        const i = tasks.incomplete.data.findIndex(
          (t) => t.booking.task.id === updatedTask.booking.task.id
        );
        if (i >= 0) {
          tasks.incomplete.data.splice(i, 1);
        }
      }

      return {
        ...state,
        updated: 'SUCCESS',
        tasks,
      };
    case UPDATE_INFLUENCER_TASK_ERROR:
      return { ...state, updated: 'ERROR' };

    case GET_INFLUENCER_TASKS:
      return {
        ...state,
        fetching: true,
        errors: {},
      };

    case GET_INFLUENCER_TASKS_SUCCESS:
      tasks = state.tasks;
      newTasks = action.payload.result.data;

      // Remove any new tasks which already exist (can happen if the Infiinite scroll reloads the same tasks).
      tasks[action.payload.status].data.forEach((task) => {
        const i = newTasks.findIndex((t) => t.id === task.id);
        if (i >= 0) {
          newTasks.splice(i, 1);
        }
      });

      tasks[action.payload.status] = {
        data: [...state.tasks[action.payload.status].data, ...newTasks],
        pages: action.payload.result.last_page,
        errors: {},
      };

      return {
        ...state,
        fetching: false,
        tasks,
      };

    case GET_INFLUENCER_TASKS_ERROR:
      tasks = state.tasks;
      tasks[action.payload.status] = {
        data: state.tasks[action.payload.status].data,
        errors: action.payload.errors,
      };

      return {
        ...state,
        fetching: false,
        tasks,
      };

    case RATE_EXPERIENCE:
      return { ...state, ratingUpdated: '' };

    case RATE_EXPERIENCE_SUCCESS:
      return { ...state, ratingUpdated: 'SUCCESS' };

    case RATE_EXPERIENCE_ERROR:
      return { ...state, ratingUpdated: 'ERROR' };

    case RATE_INFLUENCER:
      return { ...state, fetching: true, influencerRatingUpdated: '' };

    case RATE_INFLUENCER_SUCCESS:
      return { ...state, fetching: false, influencerRatingUpdated: 'SUCCESS' };

    case RATE_INFLUENCER_ERROR:
      return {
        ...state,
        fetching: false,
        influencerRatingUpdated: action.payload?.data?.errors?.rate?.[0],
      };

    case CLEAR_TASK_DATA:
      return initialData;

    default:
      return state;
  }
}

// action creators
export const getTaskAction = (influencerId, taskId) => (dispatch) => {
  dispatch({
    type: GET_INFLUENCER_TASK,
  });
  API.INFLUENCERS.getTask(influencerId, taskId)
    .then((res) => {
      dispatch({
        type: GET_INFLUENCER_TASK_SUCCESS,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: GET_INFLUENCER_TASK_ERROR,
        payload: e.response,
      });
    });
};

export const updateTaskAction = (task, influencerId, taskId) => (dispatch) => {
  dispatch({
    type: UPDATE_INFLUENCER_TASK,
  });
  API.INFLUENCERS.updateTask(task, influencerId, taskId)
    .then((res) => {
      dispatch({
        type: UPDATE_INFLUENCER_TASK_SUCCESS,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: UPDATE_INFLUENCER_TASK_ERROR,
        payload: e.response,
      });
    });
};

export const updateTaskJournalistAction = (task, journalistId, taskId) => (
  dispatch
) => {
  dispatch({
    type: UPDATE_INFLUENCER_TASK,
  });
  API.JOURNALIST.updateTask(task, journalistId, taskId)
    .then((res) => {
      dispatch({
        type: UPDATE_INFLUENCER_TASK_SUCCESS,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: UPDATE_INFLUENCER_TASK_ERROR,
        payload: e.response,
      });
    });
};

export const getTasksAction = (influencerId, completed, page, size) => (
  dispatch
) => {
  dispatch({
    type: GET_INFLUENCER_TASKS,
  });
  API.INFLUENCERS.getTasks(influencerId, completed, page, size)
    .then((res) => {
      dispatch({
        type: GET_INFLUENCER_TASKS_SUCCESS,
        payload: {
          status: completed === 1 ? 'completed' : 'incomplete',
          result: res.data,
        },
      });
    })
    .catch((e) => {
      dispatch({
        type: GET_INFLUENCER_TASKS_ERROR,
        payload: {
          status: completed === 1 ? 'completed' : 'incomplete',
          errors: e.response,
        },
      });
    });
};

export const rateExperienceAction = (rating, bookingId) => (dispatch) => {
  dispatch({
    type: RATE_EXPERIENCE,
  });
  API.INFLUENCERS.rateExperience(rating, bookingId)
    .then((res) => {
      dispatch({
        type: RATE_EXPERIENCE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: RATE_EXPERIENCE_ERROR,
        payload: e.response,
      });
    });
};

export const rateInfluencerAction = (bookingId, rating) => (dispatch) => {
  dispatch({
    type: RATE_INFLUENCER,
  });
  API.BRANDS.rateInfluencer(bookingId, rating)
    .then((res) => {
      dispatch({
        type: RATE_INFLUENCER_SUCCESS,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: RATE_INFLUENCER_ERROR,
        payload: e.response,
      });
    });
};

export const clearTaskDataReduxAction = () => ({
  type: CLEAR_TASK_DATA,
});
