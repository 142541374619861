import React from 'react';

import { Grid } from '@material-ui/core';

import PropTypes from 'prop-types';
import CreateCampaign from 'components/buttons/CreateCampaign';

export default function FabWithOptions({ user }) {
  return (
    <>
      <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
        <CreateCampaign user={user} />
      </Grid>
    </>
  );
}

FabWithOptions.propTypes = {
  user: PropTypes.objectOf(PropTypes.object),
};

FabWithOptions.defaultProps = {
  user: {},
};
