import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import {
  createCampaignAction,
  updateCampaignAction,
  updateStepAction,
  deleteCampaignPhotoAction,
  clearErrorsAction,
  getCampaignDetailsAction,
} from 'redux/campaignDuck';
import {
  getCampaignCategoryName,
  getCampaignCategory,
  formatFormData,
  getNameFromLink,
} from 'helpers/campaigns';

import {
  Container,
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  CircularProgress,
  Snackbar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { Close } from '@material-ui/icons';
import NavBar from 'components/nav/NavBar';
import SingleDayEvent from 'components/forms/CampaignCreate/SingleDayEvent';
import Opening from 'components/forms/CampaignCreate/Opening';
import GeneralUpdates from 'components/forms/CampaignCreate/GeneralUpdates';
import CampaignAudience from 'components/forms/CampaignCreate/CampaignAudience';
import CampaignBookings from 'components/forms/CampaignCreate/CampaignBookings';
import CampaignRequirements from 'components/forms/CampaignCreate/CampaignRequirements';
import CampaignReview from 'components/forms/CampaignCreate/CampaignReview';
import ActionModal from 'components/feedback/ActionModal';
import { CREATED_CAMPAIGN_MSG } from 'constants/campaigns';

// Analytics
import firebase from 'firebase/app';
import 'firebase/analytics';

const getFirstStep = (campaignType, formProps) => {
  switch (campaignType) {
    case 'singledayevent':
    case 'App\\Models\\Campaigns\\SingleEvent':
      return <SingleDayEvent {...formProps} />;
    case 'opening':
    case 'App\\Models\\Campaigns\\Opening':
      return <Opening {...formProps} />;
    case 'generalupdates':
    case 'App\\Models\\Campaigns\\GeneralUpdate':
      return <GeneralUpdates {...formProps} />;
    default:
      return <GeneralUpdates {...formProps} />;
  }
};

const createSteps = (
  campaignType,
  brand,
  onUpdate,
  errors,
  campaignDraft,
  onClearPhotos,
  createdCampaign
) => {
  const formProps = {
    brand,
    onUpdate,
    errors,
    campaignDraft,
    onClearPhotos,
    createdCampaign,
  };
  return [
    {
      name: getCampaignCategoryName(campaignType),
      component: getFirstStep(campaignType, formProps),
    },
    {
      name: 'Audience',
      component: <CampaignAudience {...formProps} />,
    },
    {
      name: 'Bookings',
      component: <CampaignBookings {...formProps} />,
    },
    {
      name: 'Requirements',
      component: <CampaignRequirements {...formProps} />,
    },
    {
      name: 'Review',
      component: <CampaignReview {...formProps} />,
    },
  ];
};

function CampaignCreate({
  getCampaignDetails,
  onPost,
  onUpdate,
  onNavigate,
  onSubmit,
  campaignData,
  sessionData,
  deletePhotos,
  clearErrors,
}) {
  const analytics = firebase.analytics();
  const { user } = sessionData;
  const { errors, fetching } = campaignData;

  const campaignDetails = {
    ...campaignData.campaignDetails,
    featuredPhotos:
      campaignData?.campaignDetails?.featuredPhotos ||
      campaignData?.campaignDetails?.featuredphotos,
  };

  const featuredPhotos = campaignDetails?.featuredPhotos || [];

  const history = useHistory();
  const params = useParams();
  const { campaignId } = params;
  const campaignCat = getCampaignCategory(getCampaignCategoryName(params.type));

  const [hasSubmitted, setHasSubmitted] = useState(false);

  let id = campaignId;
  let status = 'draft';
  const activeStep =
    campaignData?.activeStep > 4 ? 4 : campaignData?.activeStep;

  if (!id || hasSubmitted) {
    id = campaignDetails?.id;
    status = campaignDetails?.status;
  }

  const [values, setValues] = useState({
    brandId: user.profile?.brands[0]?.id,
    categoryId: parseInt(campaignCat, 10),
    name: campaignDetails?.name || '',
    description: campaignDetails?.description || '',
    // eslint-disable-next-line
    launchDate: campaignDetails?.attributes?.launch_date || new Date(),
    featuredPhotos: campaignDetails?.featuredPhotos,
  });

  const [open, setOpen] = useState(false);

  const requiredFields = ['name', 'description', 'featuredPhotos'];

  const [snackbarFields, setSnackbarFields] = useState({
    open: false,
    severity: 'success',
    message: '',
  });

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleSnackbarClose = () => {
    setSnackbarFields({ ...snackbarFields, open: false });
    clearErrors();
  };

  const updateValues = (newValues) => {
    setValues({ ...values, ...newValues });
  };

  const onClearPhotos = () => {
    // Remove files from backend
    if (featuredPhotos && featuredPhotos.length > 0) {
      featuredPhotos.forEach((photo) => {
        deletePhotos(photo.campaign_id, getNameFromLink(photo.path));
      });
    }
  };

  const campaignSteps = createSteps(
    // eslint-disable-next-line
    campaignDetails?.attributes_type || params.type,
    user.profile?.brands[0],
    updateValues,
    errors,
    values,
    onClearPhotos,
    campaignDetails
  );

  const validateRequireFields = () => {
    return requiredFields.every((item) => {
      if (['name', 'description'].includes(item)) {
        return values[item] !== '';
      }
      if (item === 'featuredPhotos') {
        return values.featuredPhotos?.length > 0;
      }
      return false;
    });
  };

  const handleNextStep = () => {
    // Remove hashtags entry if they are null
    if (!values.hashtags) {
      delete values.hashtags;
    }

    // Either add or update, check required fields
    if (validateRequireFields()) {
      // PUT if campaign already started, else POST
      if (id) {
        onUpdate(values, id, activeStep);
      } else {
        // Save campaign only if the required fields are filled
        onPost(values);
      }
    }
  };

  const handleBackStep = () => {
    onNavigate(activeStep - 1);
  };

  const handleSubmit = () => {
    onSubmit(id);
    setHasSubmitted(true);

    analytics.logEvent('campaign_create', {
      brand_id: values.brandId,
      campaign_type: campaignCat,
    });
  };

  const handleBackButton = () => {
    history.goBack();
  };

  const handleConfirm = () => {
    history.push('/campaigns');
  };

  useEffect(() => {
    if (campaignData.errors && typeof campaignData.errors !== 'object') {
      setSnackbarFields({
        open: true,
        severity: 'error',
        message: 'Sorry, something went wrong',
      });
    }
  }, [campaignData.errors]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [activeStep]);

  useEffect(() => {
    getCampaignDetails(user.id, id, user.profile?.type);
  }, [getCampaignDetails, user.id, id, user.profile]);

  return (
    <>
      {status === 'review' ? (
        <ActionModal
          title=""
          description={CREATED_CAMPAIGN_MSG.success}
          open={handleOpen}
          label={CREATED_CAMPAIGN_MSG.label}
          onClose={handleConfirm}
          onClick={handleConfirm}
        />
      ) : null}

      <NavBar
        screenTitle={campaignSteps[activeStep].name}
        handleIconClick={handleBackButton}
      >
        <Close />
      </NavBar>

      {campaignSteps[activeStep].component}

      <Container maxWidth="sm">
        <Stepper activeStep={activeStep} alternativeLabel>
          {campaignSteps.map((step) => (
            <Step key={step.name}>
              <StepLabel />
            </Step>
          ))}
        </Stepper>

        <div>
          <Box
            mt={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Button
              variant="outlined"
              size="large"
              disabled={activeStep === 0}
              onClick={handleBackStep}
            >
              Back
            </Button>

            <Button
              disableElevation
              variant={
                activeStep < campaignSteps.length - 1 ? 'outlined' : 'contained'
              }
              color="secondary"
              size="large"
              endIcon={fetching ? <CircularProgress size={18} /> : null}
              disabled={fetching || !validateRequireFields()}
              onClick={
                activeStep < campaignSteps.length - 1
                  ? handleNextStep
                  : handleSubmit
              }
            >
              {activeStep < campaignSteps.length - 1 ? 'Next' : 'Submit'}
            </Button>
          </Box>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={snackbarFields.open}
            onClose={() => handleSnackbarClose()}
            autoHideDuration={4000}
          >
            <Alert severity="error">
              Please, try uploading pictures smaller than 5MB
            </Alert>
          </Snackbar>
        </div>
      </Container>
    </>
  );
}

CampaignCreate.propTypes = {
  onPost: PropTypes.func,
  onUpdate: PropTypes.func,
  deletePhotos: PropTypes.func,
  onNavigate: PropTypes.func,
  onSubmit: PropTypes.func,
  clearErrors: PropTypes.func,
  campaignData: PropTypes.objectOf(PropTypes.object),
  sessionData: PropTypes.objectOf(PropTypes.object),
  getCampaignDetails: PropTypes.func,
};

CampaignCreate.defaultProps = {
  getCampaignDetails: () => null,
  onPost: () => {},
  onUpdate: () => {},
  deletePhotos: () => {},
  onNavigate: () => {},
  onSubmit: () => {},
  clearErrors: () => {},
  campaignData: {},
  sessionData: {},
};

// map redux state to props of component
const mapStateToProps = (state) => ({
  campaignData: state.campaignData,
  sessionData: state.sessionData,
});

const mapDispatchToProps = (dispatch) => ({
  onNavigate: (step) => {
    dispatch(updateStepAction(step));
  },
  onPost: (campaign) => {
    const formData = formatFormData(campaign, 0);
    dispatch(createCampaignAction(formData));
  },
  onUpdate: (campaign, id, step) => {
    const formData = formatFormData(campaign, step);
    dispatch(updateCampaignAction(formData, id));
  },
  onSubmit: (id) => {
    const formData = new FormData();
    // update campaign status on submit
    formData.append('status', 'review');
    dispatch(updateCampaignAction(formData, id));
  },
  deletePhotos: (id, path) => {
    dispatch(deleteCampaignPhotoAction(id, path));
  },
  clearErrors: () => {
    dispatch(clearErrorsAction());
  },
  getCampaignDetails: (userId, campaignId, userType) => {
    dispatch(getCampaignDetailsAction(userId, campaignId, userType));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignCreate);
