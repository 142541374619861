import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import userReducer from './userDuck';
import campaignReducer from './campaignDuck';
import bookingReducer from './bookingDuck';
import subscriptionReducer from './subscriptionDuck';
import taskReducer from './taskDuck';

const appReducer = combineReducers({
  sessionData: userReducer,
  campaignData: campaignReducer,
  bookingData: bookingReducer,
  subscriptionData: subscriptionReducer,
  taskData: taskReducer,
});

/* eslint-disable no-param-reassign */
const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
  }
  return appReducer(state, action);
};
/* eslint-enable no-param-reassign */

// NEED NEW GENERATE FUNC W/O COMPOSEENHANCERS FOR PROD
export default function generateStore() {
  const store = createStore(rootReducer, applyMiddleware(thunk));
  return store;
}
