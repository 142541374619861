/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import PropTypes from 'prop-types';

import {
  Container,
  Box,
  Typography,
  Card,
  CardContent,
  TextField,
  Switch,
  FormHelperText,
} from '@material-ui/core';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import KeyPlayer from 'components/forms/CampaignCreate/KeyPlayer';
import FilesUpload from 'components/forms/FilesUpload';
import { CAMPAIGN_CREATION_HELPER } from 'constants/campaigns';
import { MAX_LENGTH_FIELDS } from 'constants/settings';
import { HELP_TEXT } from 'constants/dropzone';

import CampaignHeader from './CampaignHeader';

export default function Opening({
  onUpdate,
  errors,
  campaignDraft,
  onClearPhotos,
}) {
  // set initial values
  const today = new Date();
  const [values, setValues] = useState({
    name: campaignDraft?.name || '',
    description: campaignDraft?.description || '',
    openingDate: campaignDraft?.openingDate || today,
    keyPlayers: campaignDraft?.keyPlayers || false,
    keyPlayersData: campaignDraft?.keyPlayersData || new Array(4).fill({}),
    menu: [],
  });

  // Handlers
  const handleKeyPlayerChange = (prop, num) => (event) => {
    for (let i = 0; i < 4; i += 1) {
      if (i === num) {
        // Replace key players array item without directly mutating state
        setValues({
          ...values,
          keyPlayersData: [
            ...values.keyPlayersData.slice(0, i),
            { ...values.keyPlayersData[i], [prop]: event.target.value },
            ...values.keyPlayersData.slice(i + 1),
          ],
        });
      }
    }
  };

  const handleChange = (prop) => (event) => {
    if (prop === 'openingDate') {
      setValues({ ...values, [prop]: event });
    } else if (prop === 'keyPlayers') {
      setValues({ ...values, [prop]: !values.keyPlayers });
    } else {
      setValues({ ...values, [prop]: event.target.value });
    }
  };

  const updateCallback = () => {
    onUpdate(values);
  };

  useEffect(updateCallback, [values]);

  return (
    <Container maxWidth="sm" style={{ marginTop: '2.5rem' }}>
      <form>
        <CampaignHeader />

        <Card>
          <CardContent>
            <Typography gutterBottom variant="overline" component="h2">
              General Information
            </Typography>

            <Typography gutterBottom variant="body2" component="p">
              {CAMPAIGN_CREATION_HELPER.generalUpdates}
            </Typography>

            <TextField
              fullWidth
              value={values.name}
              inputProps={{
                maxLength: MAX_LENGTH_FIELDS.name,
              }}
              color="secondary"
              id="headline"
              label="Headline"
              variant="filled"
              error={values.name === '' || Boolean(errors?.name)}
              helperText={
                errors?.name || `${MAX_LENGTH_FIELDS.name} characters max`
              }
              onChange={handleChange('name')}
              required
            />

            <TextField
              fullWidth
              value={values.description}
              color="secondary"
              inputProps={{
                maxLength: MAX_LENGTH_FIELDS.description,
              }}
              id="description"
              label="Description"
              multiline
              rows={3}
              variant="filled"
              error={values.description === '' || Boolean(errors?.description)}
              helperText={
                errors?.description ||
                `${MAX_LENGTH_FIELDS.description} characters max`
              }
              onChange={handleChange('description')}
              required
            />

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                fullWidth
                disableToolbar
                margin="normal"
                variant="inline"
                format="MM/dd/yyyy"
                id="opening-date"
                label="Opening Date"
                value={values.openingDate}
                onChange={handleChange('openingDate')}
                KeyboardButtonProps={{
                  'aria-label': 'opening date',
                }}
                error={Boolean(errors?.opening_date)}
                helperText={errors?.opening_date}
              />
            </MuiPickersUtilsProvider>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="overline" component="h2">
                Key Players
              </Typography>

              <Switch
                checked={values.keyPlayers}
                onChange={handleChange('keyPlayers')}
                name="addKeyPlayers"
                inputProps={{ 'aria-label': 'add key players' }}
              />
            </Box>

            {values.keyPlayers
              ? values.keyPlayersData.map((person, i) => {
                  return (
                    <KeyPlayer
                      key={i} // eslint-disable-line react/no-array-index-key
                      number={i}
                      nameValue={person.name || ''}
                      positionValue={person.type || ''}
                      onKeyPlayerChange={handleKeyPlayerChange}
                    />
                  );
                })
              : null}

            {errors?.key_players ? (
              <FormHelperText error>
                Please fill out all fields for key players
              </FormHelperText>
            ) : null}
          </CardContent>
        </Card>

        <FilesUpload
          handleChange={onUpdate}
          draftFiles={campaignDraft?.featuredPhotos}
          errors={errors?.featuredPhotos}
          onClearPhotos={onClearPhotos}
          helpText={HELP_TEXT}
        />
      </form>
    </Container>
  );
}

Opening.propTypes = {
  onUpdate: PropTypes.func,
  onClearPhotos: PropTypes.func,
  errors: PropTypes.objectOf(PropTypes.object),
  campaignDraft: PropTypes.shape({
    switchState: PropTypes.bool,
  }),
};

Opening.defaultProps = {
  onUpdate: () => {},
  onClearPhotos: () => {},
  errors: {},
  campaignDraft: {},
};
