import axios from 'axios';

const { REACT_APP_API_URL: URI } = process.env;

const ApiInstance = axios.create({
  timeout: 10000,
  headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
});

export const setToken = (token) => {
  ApiInstance.defaults.headers.Authorization = `Bearer ${token}`;
};

ApiInstance.interceptors.response.use(undefined, (e) => {
  const res = e?.response;
  // If 401 response received, log out user.
  if (res?.status === 401) {
    window.location = '/login';
    localStorage.removeItem('sessionData');
  }
  return Promise.reject(e);
});

const multipartFormDataConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

const API = {
  signupBrand: (user) => {
    return ApiInstance.post(`${URI}registerbrand`, user);
  },
  login: (user) => {
    return ApiInstance.post(`${URI}login`, user);
  },
  logout: (user) => {
    return ApiInstance.get(`${URI}logout`, user);
  },
  resetPassword: (email) => {
    return ApiInstance.post(`${URI}resetpassword`, email);
  },
  getProfile: () => {
    return ApiInstance.get(`${URI}profile`);
  },
  updateProfile: (data) => {
    return ApiInstance.put(`${URI}profile`, data);
  },
  createCampaign: (campaign) => {
    return ApiInstance.post(
      `${URI}campaigns`,
      campaign,
      multipartFormDataConfig
    );
  },
  updateCampaign: (campaign, id) => {
    // use POST instead of PUT in order to pass form-data
    return ApiInstance.post(
      `${URI}campaigns/${id}`,
      campaign,
      multipartFormDataConfig
    );
  },
  deleteCampaign: (id) => {
    return ApiInstance.delete(`${URI}campaigns/${id}`);
  },
  deleteCampaignPhoto: (campaignId, photoPath) => {
    return ApiInstance.delete(
      `${URI}campaigns/${campaignId}/photos/${photoPath}`
    );
  },
  createBooking: (booking) => {
    return ApiInstance.post(`${URI}bookings`, booking);
  },
  getLocation: (locationId) => {
    return ApiInstance.get(`${URI}locations/${locationId}`);
  },
  addLocation: (location) => {
    return ApiInstance.post(`${URI}locations`, location);
  },
  updateLocation: (location) => {
    return ApiInstance.put(`${URI}locations/${location.id}`, location);
  },
  deleteLocation: (locationId) => {
    return ApiInstance.delete(`${URI}locations/${locationId}`);
  },
  INFLUENCERS: {
    getInfluencer: (id) => {
      return ApiInstance.get(`${URI}influencers/${id}`);
    },
    signupInfluencer: (user) => {
      return ApiInstance.post(`${URI}influencers`, user);
    },
    updateInfluencer: (id, user) => {
      return ApiInstance.put(`${URI}influencers/${id}`, user);
    },
    updatePicture: (file, influencerId) => {
      return ApiInstance.post(
        `${URI}influencers/${influencerId}/picture`,
        file,
        multipartFormDataConfig
      );
    },
    getCampaigns: (userId, page, qty) => {
      return ApiInstance.get(
        `${URI}influencers/${userId}/campaigns?page[number]=${page}&page[size]=${qty}`
      );
    },
    getCampaignDetails: (userId, campaignId, userType) => {
      let userPath = `influencers/${userId}/campaigns`;
      if (userType === 'brands') {
        userPath = 'campaigns';
      }
      return ApiInstance.get(`${URI}${userPath}/${campaignId}`);
    },
    getAvailableHours: (userId, campaignId, location) => {
      return ApiInstance.post(
        `${URI}influencers/${userId}/campaigns/${campaignId}/availablehours`,
        location
      );
    },
    getTask: (influencerId, taskId) => {
      return ApiInstance.get(
        `${URI}influencers/${influencerId}/tasks/${taskId}`
      );
    },
    updateTask: (task, influencerId, taskId) => {
      return ApiInstance.put(
        `${URI}influencers/${influencerId}/tasks/${taskId}`,
        task
      );
    },
    getTasks: (influencerId, completed, page, size) => {
      return ApiInstance.get(
        `${URI}influencers/${influencerId}/tasks?page[number]=${page}&page[size]=${size}&filter[completed]=${completed}`
      );
    },
    getBookings: (influencerId, status, page, size) => {
      return ApiInstance.get(
        `${URI}influencers/${influencerId}/bookings?page[number]=${page}&page[size]=${size}&filter[status]=${status}`
      );
    },
    updateBooking: (booking, influencerId, bookingId) => {
      return ApiInstance.put(
        `${URI}influencers/${influencerId}/bookings/${bookingId}`,
        booking
      );
    },
    rateExperience: (rating, bookingId) => {
      return ApiInstance.post(
        `${URI}bookings/${bookingId}/rate_experience`,
        rating
      );
    },
    contactBrand: (influencerId, brandId, message) => {
      return ApiInstance.post(
        `${URI}influencers/${influencerId}/contactbrand`,
        {
          message,
          brand_id: brandId,
        }
      );
    },
  },
  BRANDS: {
    rateInfluencer: (bookingId, rating) => {
      return ApiInstance.post(
        `${URI}bookings/${bookingId}/rate_influencer`,
        rating
      );
    },
    getCampaign: (id) => {
      return ApiInstance.get(`${URI}campaigns/${id}`);
    },
    createSubscription: (purchase) => {
      return ApiInstance.post(`${URI}subscriptions`, purchase);
    },
    getSubscriptionPlans: () => {
      return ApiInstance.get(`${URI}subscriptions/products`);
    },
    getBookings: (brandId, status, page, size) => {
      return ApiInstance.get(
        `${URI}brands/${brandId}/bookings?page[number]=${page}&page[size]=${size}&filter[status]=${status}`
      );
    },
    updateBooking: (booking, brandId, bookingId) => {
      return ApiInstance.put(
        `${URI}brands/${brandId}/bookings/${bookingId}`,
        booking
      );
    },
    getCampaigns: (brandId, status, page, size) => {
      if (!status) return null;
      return ApiInstance.get(
        `${URI}brands/${brandId}/campaigns?page[number]=${page}&page[size]=${size}&filter[status]=${status}`
      );
    },
    updateProfileField: (field, brandId) => {
      return ApiInstance.put(`${URI}brands/${brandId}/field`, field);
    },
    update: (fields, brandId) => {
      return ApiInstance.patch(`${URI}brands/${brandId}`, fields);
    },
    deleteProfileField: (field, brandId) => {
      return ApiInstance.delete(`${URI}brands/${brandId}/field`, {
        data: field,
      });
    },
    updateSocialNetwork: (field, socialNetworkId) => {
      return ApiInstance.put(
        `${URI}brands/socialnetworks/${socialNetworkId}`,
        field
      );
    },
    updateLogo: (file, brandId) => {
      return ApiInstance.post(
        `${URI}brands/${brandId}/logo`,
        file,
        multipartFormDataConfig
      );
    },
    addPhoto: (photo) => {
      return ApiInstance.post(`${URI}photos`, photo, multipartFormDataConfig);
    },
    deletePhoto: (photoId) => {
      return ApiInstance.delete(`${URI}photos/${photoId}`);
    },
    uploadMenu: (formData, brandId) => {
      return ApiInstance.post(
        `${URI}brands/${brandId}/menu`,
        formData,
        multipartFormDataConfig
      );
    },
    deleteMenu: (path, brandId) => {
      return ApiInstance.delete(`${URI}brands/${brandId}/menu`, {
        data: {
          path,
        },
      });
    },
  },
  JOURNALIST: {
    updateTask: (task, journalistId, taskId) => {
      return ApiInstance.put(
        `${URI}journalist/${journalistId}/tasks/${taskId}`,
        task
      );
    },
  },
  SUBSCRIPTIONS: {
    getSubscriptionStatus: () => {
      return ApiInstance.get(`${URI}subscriptions/status`);
    },
  },
};

export default API;
