import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  Box,
} from '@material-ui/core';

import indigo from '@material-ui/core/colors/indigo';
import logo from 'images/logo-white.svg';

const useStyles = makeStyles((theme) => ({
  marketingAppBar: {
    backgroundColor: indigo[900],
  },
  marketingToolbar: {
    justifyContent: 'space-between',
  },
  toolbar: theme.mixins.toolbar,
}));

export default function MarketingNav({ handleClick, screenTitle, children }) {
  const classes = useStyles();

  return (
    <>
      <AppBar
        position="fixed"
        elevation={0}
        className={classes.marketingAppBar}
      >
        <Toolbar className={classes.marketingToolbar}>
          <Box display="flex" alignItems="center">
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleClick}
              color="inherit"
            >
              {children}
            </IconButton>

            <Typography variant="h6" noWrap>
              {screenTitle}
            </Typography>
          </Box>

          <img src={logo} alt="Pream" />

          {/* Necessary to push logo to center */}
          <Box pl={12} />
        </Toolbar>
      </AppBar>

      <div className={classes.toolbar} />
    </>
  );
}

MarketingNav.propTypes = {
  handleClick: PropTypes.func,
  screenTitle: PropTypes.string,
  children: PropTypes.node,
};

MarketingNav.defaultProps = {
  handleClick: () => null,
  screenTitle: '',
  children: <></>,
};
