import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import { Button, Grid } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  btnWitIcon: {
    backgroundColor: '#031873',
    borderRadius: 29,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    color: '#fff',
    fontSize: 24,
    padding: '1.4rem 2rem',
    '&:hover': {
      backgroundColor: '#EF4B4E',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
  },
  iconBorder: {
    border: '1px solid white',
    borderRadius: 50,
    padding: '.35rem',
    width: '2rem',
    height: '2rem',
  },
}));

export default function GoTo({ label, btnAction, disabled }) {
  const classes = useStyles();

  return (
    <Button
      className={classes.btnWitIcon}
      style={{ marginTop: '0' }}
      variant="contained"
      size="large"
      disabled={disabled}
      fullWidth
      disableElevation
      onClick={btnAction}
      endIcon={<ArrowForwardIosIcon className={classes.iconBorder} />}
    >
      <Grid container spacing={2}>
        {label}
      </Grid>
    </Button>
  );
}

GoTo.propTypes = {
  label: PropTypes.string,
  btnAction: PropTypes.func,
  disabled: PropTypes.bool,
};

GoTo.defaultProps = {
  label: '',
  btnAction: () => null,
  disabled: false,
};
