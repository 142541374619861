import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getUserDefaultRoute, isTypeAuthorized } from 'helpers/helpers';
import { clearDraftAction } from 'redux/campaignDuck';
import { clearBookingAction } from 'redux/bookingDuck';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import PlanSelect from 'components/screens/PlanSelect';

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
function PrivateRoute({ clearState, sessionData, path, component, ...rest }) {
  const { user } = sessionData;

  if (user) {
    const isUnsubscribedBrand =
      user.profile?.type === 'brands' &&
      (user?.current_subscription?.plan_id === null ||
        user?.current_subscription === null);
    if (
      isUnsubscribedBrand &&
      path !== '/plans' &&
      path !== '/plans/payment' &&
      path !== '/settings'
    ) {
      // Brands with no subscription can only view the Plan Select component
      return <Route path="/plans" component={() => <PlanSelect />} {...rest} />;
    }
    if (!isTypeAuthorized(user, path)) {
      // Redirect users to their default route
      const newPath = getUserDefaultRoute(user);
      return <Redirect to={newPath} {...rest} />;
    }
    if (path === '/create/:type' || path === '/campaigns/') {
      clearState();
    }
    return <Route path={path} component={component} {...rest} />;
  }

  return <Redirect to="/login" {...rest} />;
}

PrivateRoute.propTypes = {
  path: PropTypes.string,
  sessionData: PropTypes.shape({
    user: PropTypes.object,
    loggedIn: PropTypes.bool,
  }),
  component: PropTypes.func,
  store: PropTypes.func,
  clearState: PropTypes.func,
};

PrivateRoute.defaultProps = {
  path: '',
  sessionData: {},
  component: () => {},
  store: () => {},
  clearState: () => {},
};

const mapStateToProps = (state) => ({
  sessionData: state.sessionData,
});

const mapDispatchToProps = (dispatch) => ({
  clearState: () => {
    dispatch(clearDraftAction());
    dispatch(clearBookingAction());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
