import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  addBrandPhotoAction,
  deleteBrandPhotoAction,
  clearActiveLocationAction,
} from 'redux/userDuck';

import {
  Box,
  Button,
  Container,
  Card,
  CardContent,
  Grid,
  GridList,
  GridListTile,
  Snackbar,
  Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import LocationCard from 'components/cards/LocationCard';
import FilesUpload from 'components/forms/FilesUpload';
import MenusList from 'components/cards/MenusList';
import UserProfileCard from 'components/cards/UserProfileCard';
import GoTo from 'components/buttons/GoTo';
import CreateCampaign from 'components/buttons/CreateCampaign';
import { HELP_TEXT } from 'constants/dropzone';

const useStyles = makeStyles((theme) => ({
  fileUpload: {
    display: 'none',
  },
  fileUploadButton: {
    marginLeft: theme.spacing(1),
  },
  removeButton: {
    marginRight: 2,
    color: theme.palette.error.main,
  },
  pdfContainer: {
    borderRadius: 6,
    overflow: 'hidden',
  },
  btnBox: {
    marginTop: '1rem',
  },
}));

function BrandProfile({
  sessionData,
  addPhoto,
  deletePhoto,
  clearActiveLocation,
}) {
  const history = useHistory();
  const classes = useStyles();
  const mobile = useMediaQuery('(max-width:800px)');

  const { user } = sessionData || {};
  const isBrand = user?.profile?.type === 'brands';
  const brand = isBrand ? user?.profile?.brands[0] : {};
  const [pdfPreview, setPdfPreview] = useState(brand.menu);
  const hasMaxLocations = brand.locations.length >= 3;
  const [snackbarFields, setSnackbarFields] = useState({
    open: false,
    message: '',
  });

  const errorMenu = sessionData?.errors?.brand?.menu;
  const errorPhotos = sessionData?.errors?.brand?.photos;

  const location = useLocation();

  // Clear in profile brand after a location was closed
  if (sessionData?.activeLocation?.data) {
    clearActiveLocation();
  }

  const handleFileUpdate = (allFiles) => {
    allFiles.featuredPhotos
      .filter((p) => p.status === 'NEW')
      .forEach((photo) => {
        const formData = new FormData();
        formData.append('brand_id', brand.id);
        formData.append('file', photo);
        addPhoto(formData, brand.id);
        photo.status = 'SAVED';
      });
  };

  const handleClearAllFiles = () => {
    brand.photos.forEach((photo) => {
      deletePhoto(photo.id, brand.id);
    });
  };

  const handleSnackbarClose = () => {
    setSnackbarFields({ ...snackbarFields, open: false });
  };

  useEffect(() => {
    setPdfPreview(brand.menu);
  }, [brand.menu]);

  useEffect(() => {
    if (typeof errorMenu === 'string') {
      setSnackbarFields({
        open: true,
        message: "Sorry, we couldn't save the menu",
      });
    }
  }, [errorMenu]);

  useEffect(() => {
    if (typeof errorPhotos === 'string') {
      setSnackbarFields({
        open: true,
        message: "Sorry, we couldn't save the photos",
      });
    }
  }, [errorPhotos]);

  return (
    <Container maxWidth="xl" style={{ marginTop: '2.5rem' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <UserProfileCard
            userName={brand.name}
            userLogo={brand.logo}
            userDescription={brand.description}
            socialnetworks={brand.socialnetworks}
          />

          <Grid container spacing={1}>
            {brand.locations.map((locationData) => (
              <LocationCard location={locationData} isBrand={isBrand} />
            ))}
          </Grid>

          {location.pathname === '/profile' ? (
            <MenusList menus={brand.menus} canEdit title="Featured menus" />
          ) : null}

          {isBrand ? null : (
            <>
              <Card>
                <CardContent>
                  <Typography variant="overline" component="h2">
                    Featured Images
                  </Typography>

                  <Box mt={2}>
                    <GridList cellHeight={160} cols={3}>
                      {brand.photos.map((image) => (
                        <GridListTile key={image.name} cols={1}>
                          <img src={image.path} alt={image.name} />
                        </GridListTile>
                      ))}
                    </GridList>
                  </Box>
                </CardContent>
              </Card>

              <Card>
                <CardContent>
                  <Typography variant="overline" component="h2">
                    Menu
                  </Typography>

                  <Box mt={2}>
                    {mobile ? (
                      <Button
                        size="small"
                        variant="outlined"
                        fullWidth
                        target="_blank"
                        href={pdfPreview}
                      >
                        Open PDF
                      </Button>
                    ) : (
                      <embed
                        className={classes.pdfContainer}
                        src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${pdfPreview}&embedded=true`}
                        width="100%"
                        height="400"
                      />
                    )}
                  </Box>
                </CardContent>
              </Card>
            </>
          )}

          {location.pathname === '/profile' ? (
            <>
              <Box style={{ marginTop: '2rem' }}>
                <GoTo
                  label="Add location"
                  btnAction={() => history.push('/profile/location')}
                  disabled={hasMaxLocations}
                />
              </Box>

              <Box className={classes.btnBox}>
                <GoTo
                  label="Manage bookings"
                  btnAction={() => history.push('/bookings')}
                />
              </Box>

              <Box className={classes.btnBox}>
                <GoTo
                  label="Manage campaigns"
                  btnAction={() => history.push('/campaigns')}
                />
              </Box>

              <Box className={classes.btnBox}>
                <GoTo
                  label="Settings"
                  btnAction={() => history.push('/settings')}
                />
              </Box>
            </>
          ) : null}
        </Grid>

        <Grid item xs={12} md={6}>
          {isBrand && location.pathname === '/profile' ? (
            <FilesUpload
              title="FEATURED IMAGES"
              draftFiles={brand.photos}
              handleChange={handleFileUpdate}
              onClearPhotos={handleClearAllFiles}
              errors={null}
              helpText={HELP_TEXT}
            />
          ) : null}

          {location.pathname === '/dashboard' ? (
            <>
              <Box>
                <GoTo
                  label="UPDATE FEATURED IMAGES"
                  btnAction={() => history.push('/profile')}
                  disabled={hasMaxLocations}
                />
              </Box>

              <Box style={{ marginTop: '.5rem' }}>
                <GoTo
                  label="Manage bookings"
                  btnAction={() => history.push('/bookings')}
                  disabled={hasMaxLocations}
                />
              </Box>

              <Box style={{ marginTop: '.5rem', marginBottom: '2rem' }}>
                <GoTo
                  label="Manage campaigns"
                  btnAction={() => history.push('/campaigns')}
                />
              </Box>

              <CreateCampaign user={user} />
            </>
          ) : null}
        </Grid>
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={snackbarFields.open}
        onClose={() => handleSnackbarClose()}
        autoHideDuration={4000}
      >
        <Alert severity="error">{snackbarFields.message}</Alert>
      </Snackbar>
    </Container>
  );
}

BrandProfile.propTypes = {
  sessionData: PropTypes.shape({
    user: PropTypes.object,
    errors: PropTypes.shape({
      brand: PropTypes.shape({
        menu: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        photos: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      }),
    }),
  }),
  addPhoto: PropTypes.func,
  deletePhoto: PropTypes.func,
  clearActiveLocation: PropTypes.func,
};

BrandProfile.defaultProps = {
  sessionData: {
    user: {},
    errors: {
      brand: {
        menu: null,
        photos: null,
      },
    },
  },
  addPhoto: () => {},
  deletePhoto: () => {},
  clearActiveLocation: () => {},
};

const mapStateToProps = (state) => ({
  sessionData: state.sessionData,
});

const mapDispatchToProps = (dispatch) => ({
  addPhoto: (file, brandId) => {
    dispatch(addBrandPhotoAction(file, brandId));
  },
  deletePhoto: (photoId, brandId) => {
    dispatch(deleteBrandPhotoAction(photoId, brandId));
  },
  clearActiveLocation: () => {
    dispatch(clearActiveLocationAction());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BrandProfile);
