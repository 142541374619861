import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import {
  getLocationAction,
  updateLocationAction,
  addLocationAction,
  deleteLocationAction,
  clearActiveLocationAction,
} from 'redux/userDuck';

import {
  Container,
  Typography,
  Card,
  CardContent,
  Button,
  TextField,
  Snackbar,
  capitalize,
} from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import NavBar from 'components/nav/NavBar';
import DayHours from 'components/forms/DayHours';
import { WEEK_DAY_SLUGS } from 'constants/campaigns';
import { getSlug, formatLocationHours } from 'helpers/helpers';
import { MAX_LENGTH_FIELDS } from 'constants/settings';

const useStyles = makeStyles((theme) => ({
  profileContainer: {
    marginBottom: theme.spacing(5),
    '& .MuiCard-root': {
      marginBottom: theme.spacing(2),
    },
  },
}));

function LocationEdit({
  sessionData,
  getLocation,
  updateLocation,
  addLocation,
  deleteLocation,
  clearActiveLocation,
}) {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();
  const [isDeleting, setIsDeleting] = useState(false);
  const [snackbarFields, setSnackbarFields] = useState({
    open: false,
    severity: 'success',
    message: '',
  });

  const { user, activeLocation, fetching } = sessionData || {};
  const { updated, deleted } = activeLocation;
  const isBrand = user?.profile?.type === 'brands';
  const brand = isBrand ? user?.profile?.brands[0] : {};
  const [location, setLocation] = useState(
    sessionData.activeLocation?.data || { hours: [] }
  );

  if (!location.brand_id) location.brand_id = brand.id;
  const errors = sessionData.errors.activeLocation;

  const hours = location.hours
    ? location.hours.map((d) => {
        return {
          ...d,
          startTime: d.start_time,
          endTime: d.end_time,
          switch: true,
        };
      })
    : null;

  const handleSubmit = () => {
    if (location.id) {
      const locationObj = { ...location }; // Remove the location.brand prop.
      delete locationObj.brand;
      updateLocation(locationObj);
    } else {
      addLocation(location);
    }
  };

  const handleDelete = () => {
    setIsDeleting(true);
    deleteLocation(location);
  };

  const handleBackButton = () => {
    history.goBack();
  };

  const handleSnackbarClose = () => {
    setSnackbarFields({ ...snackbarFields, open: false });
  };

  const handleFieldChange = (event) => {
    setLocation({ ...location, [event.target.name]: event.target.value });
  };

  const handleHoursChange = (daySlug, values) => {
    if (location.hours) {
      const hoursIndex = location.hours.findIndex(
        (h) => getSlug(h.name) === daySlug
      );

      if (values.switch) {
        location.hours[
          hoursIndex === -1 ? location.hours.length : hoursIndex
        ] = {
          name: capitalize(daySlug),
          start_time: values.startTime,
          end_time: values.endTime,
        };
      } else if (hoursIndex !== -1) {
        location.hours.splice(hoursIndex, 1);
      }
    }
  };

  useEffect(() => {
    if (fetching) return;

    if (Object.prototype.hasOwnProperty.call(params, 'id')) {
      const paramsInt = parseInt(params.id, 10);
      // If no location ID, fetch the location based on params
      if (!location.id && !activeLocation?.deleted) {
        getLocation(params.id);
        // else if location and params are mismatched, clear the location
      } else if (location.id !== paramsInt) {
        clearActiveLocation();
      }
    }
  }, [
    clearActiveLocation,
    fetching,
    getLocation,
    location,
    params,
    activeLocation,
  ]);

  useEffect(() => {
    if (fetching) return;
    // Manage snackbar state
    if (errors && Object.keys(errors).length !== 0) {
      setSnackbarFields({
        open: true,
        severity: 'error',
        message: isDeleting
          ? "Sorry, we couldn't delete the location"
          : "Sorry, we couldn't save the location",
      });
    }
    if (updated && errors === false) {
      setSnackbarFields({
        open: true,
        severity: 'success',
        message: 'The location was updated',
      });
    }
    if (deleted && errors === false) {
      setIsDeleting(false);
      history.push('/profile');
    }
  }, [errors, history, fetching, updated, deleted, isDeleting, setIsDeleting]);

  return (
    <>
      <NavBar
        screenTitle={`${location.id ? 'Edit' : 'New'} Location`}
        handleIconClick={handleBackButton}
      >
        <CloseIcon />
      </NavBar>

      <Container className={classes.profileContainer} maxWidth="sm">
        <form>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="overline" component="h2">
                Contact Info
              </Typography>

              <TextField
                fullWidth
                color="secondary"
                id="name"
                inputProps={{
                  maxLength: MAX_LENGTH_FIELDS.name,
                }}
                label="Location Name"
                variant="filled"
                name="name"
                value={location?.name ?? ''}
                onChange={handleFieldChange}
                helperText={errors?.name ? errors?.name[0] : ''}
              />

              <TextField
                fullWidth
                color="secondary"
                id="address"
                label="Street Address"
                inputProps={{
                  maxLength: MAX_LENGTH_FIELDS.address,
                }}
                variant="filled"
                placeholder="632 Myrtle Av."
                name="address"
                value={location?.address ?? ''}
                onChange={handleFieldChange}
                helperText={errors?.address ? errors?.address[0] : ''}
              />

              <TextField
                fullWidth
                color="secondary"
                id="email"
                label="Email"
                inputProps={{
                  maxLength: MAX_LENGTH_FIELDS.email,
                }}
                variant="filled"
                type="email"
                name="email"
                value={location?.email ?? ''}
                onChange={handleFieldChange}
                helperText={errors?.email ? errors?.email[0] : ''}
              />

              <TextField
                fullWidth
                color="secondary"
                id="phone"
                label="Phone Number"
                variant="filled"
                name="phone"
                inputProps={{
                  maxLength: MAX_LENGTH_FIELDS.phone,
                }}
                value={location?.phone ?? ''}
                onChange={handleFieldChange}
                helperText={errors?.phone ? errors?.phone[0] : ''}
              />

              <TextField
                fullWidth
                color="secondary"
                id="contact"
                label="Contact Person"
                inputProps={{
                  maxLength: MAX_LENGTH_FIELDS.contact,
                }}
                variant="filled"
                name="contact"
                value={location?.contact ?? ''}
                onChange={handleFieldChange}
                helperText={errors?.contact ? errors?.contact[0] : ''}
              />
            </CardContent>
          </Card>

          <Card>
            <CardContent>
              <Typography variant="overline" component="h2">
                Operation Hours
              </Typography>

              {/* eslint-disable */
              hours
                ? WEEK_DAY_SLUGS.map((day) => (
                  <DayHours
                    day={day}
                    onChange={handleHoursChange}
                    initialValues={hours.find(
                      (h) => h.name.substring(0, 3).toLowerCase() === day.toLowerCase()
                    )}
                  />
                ))
                : null
              /* eslint-enable */}
            </CardContent>
          </Card>

          <Button
            variant="contained"
            size="large"
            color="secondary"
            fullWidth
            disableElevation
            onClick={handleSubmit}
            disabled={
              !(
                location.name &&
                location.address &&
                location.email &&
                location.phone &&
                location.contact
              )
            }
          >
            Save Location
          </Button>

          {location.id ? (
            <Button
              variant="outlined"
              size="large"
              color="primary"
              fullWidth
              disableElevation
              onClick={handleDelete}
            >
              Delete
            </Button>
          ) : null}
        </form>
      </Container>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={snackbarFields.open}
        onClose={handleSnackbarClose}
        autoHideDuration={4000}
      >
        <Alert severity={snackbarFields.severity}>
          {snackbarFields.message}
        </Alert>
      </Snackbar>
    </>
  );
}

LocationEdit.propTypes = {
  sessionData: PropTypes.shape({
    activeLocation: PropTypes.object,
    errors: PropTypes.shape({
      activeLocation: PropTypes.object,
    }),
  }),
  getLocation: PropTypes.func,
  updateLocation: PropTypes.func,
  addLocation: PropTypes.func,
  deleteLocation: PropTypes.func,
  clearActiveLocation: PropTypes.func,
};

LocationEdit.defaultProps = {
  sessionData: {
    activeLocation: {},
    errors: {
      activeLocation: {},
    },
  },
  getLocation: () => {},
  updateLocation: () => {},
  addLocation: () => {},
  deleteLocation: () => {},
  clearActiveLocation: () => {},
};

const mapStateToProps = (state) => ({
  sessionData: state.sessionData,
});

const mapDispatchToProps = (dispatch) => ({
  getLocation: (locationId) => {
    dispatch(getLocationAction(locationId));
  },
  updateLocation: (location, locationId) => {
    location.hours = formatLocationHours(location.hours);
    dispatch(updateLocationAction(location, locationId));
  },
  addLocation: (location) => {
    location.hours = formatLocationHours(location.hours);
    dispatch(addLocationAction(location));
  },
  deleteLocation: (location) => {
    dispatch(deleteLocationAction(location));
  },
  clearActiveLocation: () => {
    dispatch(clearActiveLocationAction());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationEdit);
