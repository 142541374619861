import 'date-fns';
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core/styles';
import { createBookingAction } from 'redux/bookingDuck';
import {
  getCampaignDetailsAction,
  getCampaignHoursAction,
} from 'redux/campaignDuck';
import connect from 'react-redux/es/connect/connect';

// Refer to this documentation https://material-ui-pickers.dev/demo/datepicker
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import {
  Container,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  NativeSelect,
  FormHelperText,
  CircularProgress,
  TextField,
} from '@material-ui/core';
import NavBar from 'components/nav/NavBar';
import ActionModal from 'components/feedback/ActionModal';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

// Helpers
import {
  formatLocationData,
  convertDateForApi,
  convertDateLabel,
} from 'helpers/campaigns';
import {
  removeLeadingZero,
  getKeyByValue,
  startsWithNum,
  formatDate,
  formatDateNextMonth,
} from 'helpers/helpers';

// Constants
import { WEEK_DAYS } from 'constants/campaigns';
import { BOOKING_REQUEST_MODALS } from 'constants/bookings';

// Analytics
import firebase from 'firebase/app';
import 'firebase/analytics';

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
}));

/* eslint-disable camelcase */

const getCampaignDates = (category, attributes) => {
  let endDate;
  let startDate;

  switch (category) {
    case 4: // General Updates
      startDate = formatDate(attributes?.launch_date);
      if (attributes?.end_date) {
        endDate = formatDate(attributes?.end_date);
      } else {
        endDate = formatDateNextMonth(attributes?.launch_date);
      }
      break;
    case 5: // Openings
      endDate = formatDateNextMonth(attributes?.opening_date);
      startDate = formatDate(attributes?.opening_date);
      break;

    default:
      break;
  }
  return {
    endMaxDate: endDate,
    startDate,
  };
};

function BookingRequest({
  campaignData,
  onSubmit,
  onRender,
  onLocationSelect,
  sessionData,
  bookingData,
}) {
  const analytics = firebase.analytics();
  const classes = useStyles();
  const history = useHistory();

  // initialize values
  const { campaignDetails, campaignAvailability } = campaignData || {};
  const { fetching, newBooking } = bookingData;
  const { data, errors } = newBooking;
  const createdBooking = data;
  const { user } = sessionData || {};
  const userType = user.profile?.type;
  const { id } = useParams();
  const { locations } = campaignDetails || {};
  const formLocations =
    locations?.length > 0 ? formatLocationData(locations) : {};

  const [timeOptions, setTimeOptions] = useState([]);
  const [modalFields, setModalFields] = useState({
    open: false,
    title: '',
    description: '',
    label: '',
    dangerLabel: '',
  });
  const [values, setValues] = useState({
    guests: '',
    location: '',
    time: '',
    selectedDate: new Date(),
    disabledDates: [],
  });

  const { startDate, endMaxDate } = getCampaignDates(
    campaignDetails?.category_id,
    campaignDetails?.attributes
  );

  const handleDateChange = (date) => {
    setValues({ ...values, selectedDate: date });
  };

  const handleChange = (prop) => (event) => {
    if (prop === 'location') {
      // Get availability when location is updated
      const locationId = getKeyByValue(formLocations, event.target.value);
      onLocationSelect(user?.id, id, locationId);
    }
    setValues({ ...values, [prop]: event.target.value });
  };

  const disableDay = (date) => {
    // Check each date against availability
    return campaignAvailability
      ? !campaignAvailability[WEEK_DAYS[date.getDay()]].length > 0
      : false;
  };

  const handleCloseModal = () => {
    setModalFields({ ...modalFields, open: false });
    if (errors?.booking || createdBooking?.created) history.push('/campaigns');
  };

  const handleBackButton = () => {
    history.goBack();
  };

  useEffect(() => {
    // Retrieve campaign on render
    onRender(user.id, id, userType);
  }, [onRender, user.id, id, userType]);

  const updateTimesCallback = () => {
    // Set available time options
    const dayIndex = values.selectedDate.getDay();
    let options = campaignAvailability
      ? campaignAvailability[WEEK_DAYS[dayIndex]]
      : [];
    if (!options.length > 0) {
      // If no time options and no location set, prompt user for location
      options = values.location
        ? ['No availability. Try another day.']
        : ['First select a location above.'];
    }
    setTimeOptions(options);
  };

  useEffect(updateTimesCallback, [campaignAvailability, values.selectedDate]);

  const updateSetTimeCallback = () => {
    // When time options change update selected time
    if (timeOptions.length > 0) setValues({ ...values, time: timeOptions[0] });
  };

  useEffect(updateSetTimeCallback, [timeOptions]);

  useEffect(() => {
    // Set modal fields when booking or error is returned
    if (createdBooking?.created) {
      setModalFields(BOOKING_REQUEST_MODALS.success);
    } else if (errors?.booking) {
      setModalFields(BOOKING_REQUEST_MODALS.failure);
    } else if (
      errors?.location_id ||
      errors?.guests ||
      errors?.booking_date ||
      errors?.booking_time
    ) {
      let modalParams = BOOKING_REQUEST_MODALS.validation;

      if (errors.booking_date) {
        modalParams = {
          ...modalParams,
          description: errors.booking_date[0],
        };
      }
      setModalFields(modalParams);
    }
  }, [createdBooking, errors]);

  const handleSubmit = () => {
    const location = getKeyByValue(formLocations, values.location);
    const booking = {
      campaign_id: id,
      location_id: location,
      booking_date: convertDateForApi(values.selectedDate),
      booking_time: values.time,
      guests: values.guests,
    };
    onSubmit(booking);

    analytics.logEvent('booking_request', {
      campaign_name: campaignDetails?.name,
    });
  };

  return (
    <>
      <Container maxWidth="xs">
        <NavBar
          screenTitle="Request a booking"
          handleIconClick={handleBackButton}
        >
          <CloseIcon />
        </NavBar>

        <Box mb={4}>
          <FormControl variant="filled" className={classes.fullWidth}>
            <InputLabel id="guests-label">Number of guests</InputLabel>
            <Select
              color="secondary"
              labelId="guests-label"
              id="guests"
              value={values.guests}
              onChange={handleChange('guests')}
              error={Boolean(errors?.guests) && !values.guests}
            >
              {/* Guests are always either 1 or 2 */}
              <MenuItem value={1}>1 Guest</MenuItem>
              <MenuItem value={2}>2 Guests</MenuItem>
            </Select>
          </FormControl>
          <FormHelperText>{errors?.guests && !values.guests}</FormHelperText>

          <FormControl variant="filled" className={classes.fullWidth}>
            <InputLabel id="brandLocation-label">Book at:</InputLabel>
            <Select
              color="secondary"
              labelId="brandLocation-label"
              id="brandLocation"
              value={formLocations[values.location]}
              onChange={handleChange('location')}
              error={Boolean(errors?.location_id) && !values.location}
            >
              {/* Iterate over brand locations */}
              {Object.keys(formLocations).map((loc) => {
                return (
                  <MenuItem key={loc.id} value={formLocations[loc]}>
                    {formLocations[loc]}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText>
              {errors?.location_id && !values.location
                ? 'The location field is required.'
                : ''}
            </FormHelperText>
          </FormControl>

          <Box mt={4} mb={2}>
            {campaignData.campaignDetails?.category_id === 6 ? (
              <TextField
                label="Event Date"
                id="event-date"
                value={convertDateLabel(
                  campaignData.campaignDetails?.attributes.event_date
                )}
                color="secondary"
                inputProps={{ readOnly: true }}
              />
            ) : (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="static"
                  color="secondary"
                  margin="normal"
                  id="date-picker-dialog"
                  label="Pick a Date"
                  format="MM/dd/yyyy"
                  value={values.selectedDate}
                  onChange={handleDateChange}
                  minDate={
                    startDate && new Date(startDate) > new Date()
                      ? startDate
                      : new Date()
                  }
                  maxDate={endMaxDate ? new Date(endMaxDate) : null}
                  shouldDisableDate={disableDay}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            )}

            <FormControl className={classes.fullWidth}>
              <InputLabel htmlFor="times" id="times-label">
                Available times:
              </InputLabel>
              <NativeSelect
                color="secondary"
                value={values.time}
                onChange={handleChange('time')}
                inputProps={{
                  name: 'times',
                  id: 'times',
                }}
                error={
                  Boolean(errors?.booking_time) && !startsWithNum(values.time)
                }
              >
                {/* Iterate over available times according to date */}
                {timeOptions.map((time) => {
                  return (
                    <option value={time} key={time}>
                      {removeLeadingZero(time)}
                    </option>
                  );
                })}
              </NativeSelect>
              <FormHelperText>
                {errors?.booking_time && !startsWithNum(values.time)
                  ? 'Please select an available day and time.'
                  : ''}
              </FormHelperText>
            </FormControl>
          </Box>

          <Button
            variant="contained"
            size="large"
            color="secondary"
            disableElevation
            fullWidth
            onClick={handleSubmit}
            endIcon={fetching ? <CircularProgress size={18} /> : null}
            disabled={fetching}
          >
            Request now
          </Button>
        </Box>
      </Container>

      <ActionModal
        title={modalFields.title}
        description={modalFields.description}
        open={modalFields.open}
        label={modalFields.label}
        onClose={handleCloseModal}
        onClick={handleCloseModal}
      />
    </>
  );
}

BookingRequest.propTypes = {
  onSubmit: PropTypes.func,
  onRender: PropTypes.func,
  onLocationSelect: PropTypes.func,
  campaignData: PropTypes.shape({
    campaignDetails: PropTypes.objectOf(PropTypes.object),
  }),
  sessionData: PropTypes.objectOf(PropTypes.object),
  bookingData: PropTypes.shape({
    newBooking: PropTypes.objectOf(PropTypes.object),
    fetching: PropTypes.bool,
  }),
};

BookingRequest.defaultProps = {
  onRender: () => {},
  onSubmit: () => {},
  onLocationSelect: () => {},
  campaignData: {},
  sessionData: {},
  bookingData: {},
};

const mapStateToProps = (state) => ({
  campaignData: state.campaignData,
  sessionData: state.sessionData,
  bookingData: state.bookingData,
});

const mapDispatchToProps = (dispatch) => ({
  onRender: (userId, id, userType) => {
    dispatch(getCampaignDetailsAction(userId, id, userType));
  },
  onLocationSelect: (userId, id, location) => {
    const locObj = { location_id: location };
    dispatch(getCampaignHoursAction(userId, id, locObj));
  },
  onSubmit: (booking) => {
    dispatch(createBookingAction(booking));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingRequest);
