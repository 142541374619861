import React from 'react';
import PropTypes from 'prop-types';
import { formatCampaignSummary } from 'helpers/campaigns';

import {
  Container,
  Typography,
  GridList,
  GridListTile,
  Box,
} from '@material-ui/core';
import DataTable from 'components/lists/DataTable';

export default function CampaignReview({ createdCampaign }) {
  // Format campaign data
  const formattedData = createdCampaign
    ? formatCampaignSummary(createdCampaign)
    : {};

  return (
    <Container maxWidth="sm" style={{ marginTop: '2.5rem' }}>
      <DataTable
        tableTitle="General Information"
        rows={formattedData.general}
      />

      {formattedData.keyPlayers.length > 0 ? (
        <DataTable tableTitle="Key Players" rows={formattedData.keyPlayers} />
      ) : null}

      {formattedData.imgArray?.length > 0 ? (
        <Box mt={2} mb={2} pl={2} pr={2}>
          <Typography gutterBottom variant="overline" component="h2">
            Images
          </Typography>

          <GridList cellHeight={160} cols={4}>
            {formattedData.imgArray?.map((image) => (
              <GridListTile key={image.title} cols={2}>
                <img src={image.src} alt={image.title} />
              </GridListTile>
            ))}
          </GridList>
        </Box>
      ) : null}

      <DataTable tableTitle="Audience" rows={formattedData.audience} />
      <DataTable tableTitle="Bookings" rows={formattedData.bookings} />
      <DataTable tableTitle="Requirements" rows={formattedData.requirements} />
    </Container>
  );
}

CampaignReview.propTypes = {
  createdCampaign: PropTypes.objectOf(PropTypes.object),
};

CampaignReview.defaultProps = {
  createdCampaign: {},
};
