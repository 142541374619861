import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  getDiscoverCampaignsAction,
  getBrandCampaignsAction,
} from 'redux/campaignDuck';
import connect from 'react-redux/es/connect/connect';
import { CAMPAIGN_TYPES } from 'constants/campaigns';
import PropTypes from 'prop-types';

// Components
import { Grid, Box, CircularProgress } from '@material-ui/core';
import FabWithOptions from 'components/buttons/FabWithOptions';
import CampaignCard from 'components/cards/CampaignCard';
import CreateCampaign from 'components/buttons/CreateCampaign';
import InfiniteScroll from 'react-infinite-scroll-component';

import firebase from 'firebase/app';
import 'firebase/analytics';

function CampaignGrid({
  getCampaigns,
  campaignData,
  sessionData,
  status,
  userType,
}) {
  const analytics = firebase.analytics();
  const { user } = sessionData || {};
  const { fetching } = campaignData;
  const campaigns = campaignData.campaigns[status]?.data;
  const totalPages = campaignData.campaigns[status]?.pages;
  const errors = campaignData.campaigns[status]?.errors;

  const history = useHistory();
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const handleCardClick = (id) => {
    analytics.logEvent('select_content', {
      content_type: 'campaign',
      content_id: id,
    });

    history.push(`/campaigns/${id}`);
  };

  const handleHeaderClick = (brand) =>
    history.push(`/brands/${brand.id}`, brand);

  const getNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    } else {
      setHasMore(false);
    }
  };

  useEffect(() => {
    getCampaigns(user, page, 10, userType, status);
  }, [getCampaigns, user, page, userType, status]);

  return (
    <Box
      style={
        userType === 'brands' ? { marginTop: '2.5rem' } : { marginTop: '5rem' }
      }
    >
      {errors && errors.data && (
        <option>Error: Unable to retrieve campaigns.</option>
      )}

      {campaigns.length === 0 && fetching && (
        <Box mt={2} display="flex" justifyContent="center">
          <CircularProgress color="secondary" size={24} />
        </Box>
      )}

      {campaigns.length === 0 && !fetching && userType === 'brands' && (
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
          <CreateCampaign user={user} />
        </Grid>
      )}

      {campaigns.length > 0 ? (
        <Box mb={5}>
          <InfiniteScroll
            dataLength={campaigns.length} // This is important field to render the next data
            next={getNext}
            hasMore={hasMore}
            style={{ overflow: 'hidden' }} // Necessary for appropriate scroll behavior on mobile
            loader={
              fetching ? (
                <Box mt={2} display="flex" justifyContent="center">
                  <CircularProgress color="secondary" size={24} />
                </Box>
              ) : null
            }
          >
            <Grid container spacing={3}>
              {userType === 'brands' ? <FabWithOptions user={user} /> : null}
              {campaigns.length &&
                campaigns.map((campaign) => (
                  <CampaignCard
                    key={campaign.id}
                    brandId={campaign.brand.id}
                    avatar={campaign.brand.logo}
                    brandName={campaign.brand.name}
                    brandType={campaign.brand.brandType}
                    image={campaign.featuredphotos[0]?.path || ''}
                    title={campaign.name}
                    tag={CAMPAIGN_TYPES[campaign.category_id].name || ''}
                    description={campaign.description}
                    link={() => handleCardClick(campaign.id)}
                    brandLink={() => handleHeaderClick(campaign.brand)}
                    status={campaign.status}
                    attributes={campaign.attributes}
                    isBrand={userType === 'brands'}
                  />
                ))}
            </Grid>
          </InfiniteScroll>
        </Box>
      ) : null}
    </Box>
  );
}

CampaignGrid.propTypes = {
  getCampaigns: PropTypes.func,
  sessionData: PropTypes.shape({
    user: PropTypes.objectOf(PropTypes.object),
  }),
  campaignData: PropTypes.shape({
    discoverCampaigns: PropTypes.objectOf(PropTypes.object),
    campaigns: PropTypes.objectOf(PropTypes.object),
    fetching: PropTypes.bool,
    pages: PropTypes.number,
    errors: PropTypes.objectOf(PropTypes.object),
  }),
  status: PropTypes.string,
  userType: PropTypes.string,
};

CampaignGrid.defaultProps = {
  getCampaigns: () => {},
  campaignData: {
    discoverCampaigns: {},
  },
  sessionData: {},
  status: '',
  userType: '',
};

const mapStateToProps = (state) => ({
  campaignData: state.campaignData,
  sessionData: state.sessionData,
});

const mapDispatchToProps = (dispatch) => ({
  getCampaigns: (user, page, size, userType, status) => {
    if (userType === 'brands') {
      const id = user.profile?.brands[0]?.id;
      dispatch(getBrandCampaignsAction(id, status, page, size));
    } else {
      dispatch(getDiscoverCampaignsAction(page, size));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignGrid);
