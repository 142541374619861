import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { formatSocialNetwork } from 'helpers/helpers';

import IconWithLabel from 'components/lists/IconWithLabel';

import {
  Avatar,
  Box,
  Button,
  Divider,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  cardLink: {
    color: 'inherit',
    textDecoration: 'none',
  },
  profileAvatar: {
    width: theme.spacing(13),
    height: theme.spacing(13),
  },
}));

export default function UserProfileCard({
  isBrand,
  userName,
  userLogo,
  userDescription,
  socialnetworks,
}) {
  const history = useHistory();
  const classes = useStyles();

  const handleEditClick = () => {
    history.push('/profile/edit');
  };

  return (
    <>
      <Card>
        <CardContent style={{ padding: '1.8rem 0 0' }}>
          {isBrand ? (
            <Box
              display="flex"
              alignItems="flex-end"
              justifyContent="space-between"
              mb={0}
              px={4}
            >
              <Button
                color="secondary"
                aria-label="upload picture"
                component="div"
                onClick={handleEditClick}
                style={{
                  fontSize: 18,
                  margin: '0 0 .4rem auto',
                  padding: 0,
                  textTransform: 'capitalize',
                  textDecoration: 'underline',
                }}
              >
                Edit Profile
              </Button>
            </Box>
          ) : null}

          <Box px={4} pb={2}>
            <Grid container spacing={8}>
              <Grid item lg={3}>
                <Avatar
                  alt={userName}
                  src={userLogo}
                  className={classes.profileAvatar}
                />
              </Grid>
              <Grid item lg={9}>
                <Typography
                  style={{
                    color: '#031873',
                    fontSize: 36,
                    fontWeight: 600,
                    lineHeight: '2.5rem',
                    margin: '0 0 .6rem 0',
                    padding: 0,
                  }}
                >
                  {userName}
                </Typography>

                {socialnetworks.map((network) => {
                  const formatted = formatSocialNetwork(network);
                  if (!network.handle) return null;
                  return (
                    <a href={formatted.link} className={classes.cardLink}>
                      <IconWithLabel
                        icon={formatted.icon || ''}
                        label={formatted.handle || ''}
                      />
                    </a>
                  );
                })}
              </Grid>
            </Grid>
          </Box>

          <Divider style={{ height: 3, backgroundColor: '#E5E5E5' }} />

          <Box px={4} pt={2} pb={1}>
            <Typography
              style={{ fontSize: 24, color: '#606060' }}
              gutterBottom
              variant="body1"
              component="p"
            >
              {userDescription}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

UserProfileCard.propTypes = {
  isBrand: PropTypes.bool,
  userName: PropTypes.string,
  userLogo: PropTypes.string,
  userDescription: PropTypes.string,
  socialnetworks: PropTypes.func,
};

UserProfileCard.defaultProps = {
  isBrand: true,
  userName: '',
  userLogo: '',
  userDescription: '',
  socialnetworks: () => {},
};
