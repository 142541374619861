import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';

// Components
import {
  Grid,
  Divider,
  Card,
  Avatar,
  CardHeader,
  CardActionArea,
  CardContent,
  Typography,
  CardActions,
  Button,
} from '@material-ui/core';

// Component specific styles
const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 500,
    fontSize: 18,
  },
  tag: {
    fontWeight: 500,
  },
  accent: {
    color: theme.palette.secondary.main,
  },
  chip: {
    marginTop: 16,
  },

  flex: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

/* eslint-disable camelcase */
export default function BookingCard({ task }) {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = () => {
    history.push(`/tasks/${task.booking.task.id}`);
  };

  return (
    <Grid item xs={12} sm={12} md={6} lg={4}>
      <Card className={classes.root}>
        <CardHeader
          avatar={
            <Avatar
              alt={task.booking?.campaign?.brand?.name}
              src={task.booking?.campaign?.brand?.logo}
            />
          }
          title={task.booking?.campaign?.brand?.name}
          subheader={task.booking?.location?.name}
          classes={{
            title: classes.title,
          }}
        />

        <Divider />

        <CardActionArea onClick={() => handleClick()}>
          <CardContent>
            <Typography variant="h6" component="h2">
              {dayjs(task.booking?.booking_date).format('MM/DD/YY')}
              <span className={classes.accentText}> @ </span>
              {dayjs(task.booking?.booking_date).format('h:mmA')}
            </Typography>
          </CardContent>
        </CardActionArea>

        <CardActions>
          <Button size="small" color="primary" onClick={() => handleClick()}>
            Complete Task
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
}

BookingCard.propTypes = {
  task: PropTypes.objectOf(PropTypes.object),
};

BookingCard.defaultProps = {
  task: {},
};
