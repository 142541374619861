import React, { useEffect, useState } from 'react';
import 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import PropTypes from 'prop-types';
import { HELP_TEXT } from 'constants/dropzone';

import {
  Container,
  Box,
  Typography,
  Card,
  CardContent,
  TextField,
  FormControl,
  Select,
} from '@material-ui/core';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import FilesUpload from 'components/forms/FilesUpload';
import { WORKING_HOURS, CAMPAIGN_CREATION_HELPER } from 'constants/campaigns';
import { MAX_LENGTH_FIELDS } from 'constants/settings';

import CampaignHeader from './CampaignHeader';

const useStyles = makeStyles((theme) => ({
  timeRange: {
    '& > *': {
      marginBottom: 0,
    },
    '& > *:not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },
}));

export default function SingleDayEvent({
  onUpdate,
  errors,
  campaignDraft,
  onClearPhotos,
  createdCampaign,
}) {
  const classes = useStyles();
  const today = new Date();

  // initial values
  const [values, setValues] = useState({
    name: campaignDraft?.name || '',
    description: campaignDraft?.description || '',
    eventDate: campaignDraft?.eventDate || today,
    startTime: campaignDraft?.startTime || '6:00',
    endTime: campaignDraft?.endTime || '6:30',
  });

  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: prop === 'eventDate' ? event : event.target.value,
    });
  };

  const updateCallback = () => {
    onUpdate(values);
  };

  useEffect(updateCallback, [values]);

  return (
    <Container maxWidth="sm" style={{ marginTop: '2.5rem' }}>
      <form>
        <CampaignHeader />

        <Card>
          <CardContent>
            <Typography gutterBottom variant="overline" component="h2">
              General Information
            </Typography>

            <Typography component="p" gutterBottom variant="body2">
              {CAMPAIGN_CREATION_HELPER.generalUpdates}
            </Typography>

            <TextField
              fullWidth
              id="event-name"
              label="Event Name"
              value={values.name}
              color="secondary"
              variant="filled"
              required
              inputProps={{
                maxLength: MAX_LENGTH_FIELDS.name,
              }}
              onChange={handleChange('name')}
              error={values.name === '' || Boolean(errors?.name)}
              helperText={
                errors?.name || `${MAX_LENGTH_FIELDS.name} characters max`
              }
            />

            <TextField
              id="description"
              label="Description"
              fullWidth
              required
              value={values.description}
              inputProps={{
                maxLength: MAX_LENGTH_FIELDS.description,
              }}
              color="secondary"
              variant="filled"
              multiline
              rows={3}
              onChange={handleChange('description')}
              error={values.description === '' || Boolean(errors?.description)}
              helperText={
                errors?.description ||
                `${MAX_LENGTH_FIELDS.description} characters max`
              }
            />

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                color="secondary"
                fullWidth
                disableToolbar
                margin="normal"
                variant="inline"
                format="MM/dd/yyyy"
                id="event-date"
                label="Event Date"
                value={values.eventDate}
                onChange={handleChange('eventDate')}
                KeyboardButtonProps={{
                  'aria-label': 'event date',
                }}
              />
            </MuiPickersUtilsProvider>

            <Box
              mt={2}
              className={classes.timeRange}
              display="flex"
              alignItems="center"
            >
              <FormControl>
                <Select
                  color="secondary"
                  native
                  value={values.startTime}
                  onChange={handleChange('startTime')}
                  inputProps={{
                    name: 'startTime',
                    id: 'start-time',
                  }}
                >
                  {WORKING_HOURS.filter((hour) => hour !== values.endTime).map(
                    (hour) => (
                      <option value={hour} key={hour}>
                        {hour}
                      </option>
                    )
                  )}
                </Select>
              </FormControl>

              <Typography color="textSecondary">to</Typography>

              <FormControl>
                <Select
                  color="secondary"
                  native
                  value={values.endTime}
                  onChange={handleChange('endTime')}
                  inputProps={{
                    name: 'endTime',
                    id: 'end-time',
                  }}
                >
                  {WORKING_HOURS.filter(
                    (hour) => hour !== values.startTime
                  ).map((hour) => (
                    <option value={hour} key={hour}>
                      {hour}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </CardContent>
        </Card>

        <FilesUpload
          handleChange={onUpdate}
          draftFiles={createdCampaign?.featuredPhotos}
          errors={errors?.featuredphotos}
          onClearPhotos={onClearPhotos}
          helpText={HELP_TEXT}
        />
      </form>
    </Container>
  );
}

SingleDayEvent.propTypes = {
  onUpdate: PropTypes.func,
  onClearPhotos: PropTypes.func,
  errors: PropTypes.objectOf(PropTypes.object),
  campaignDraft: PropTypes.shape({
    switchState: PropTypes.bool,
  }),
  createdCampaign: PropTypes.objectOf(PropTypes.object),
};

SingleDayEvent.defaultProps = {
  onUpdate: () => {},
  onClearPhotos: () => {},
  errors: {},
  campaignDraft: {},
  createdCampaign: {},
};
