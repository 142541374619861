export const MODAL_DATA = {
  success:
    "Your profile information has been sent for review to the PREAM Team. This can take up to a few days, please wait for us to review this information and we'll be sending you an email with your login credentials. In the meantime feel free to browse through our site to know more about PREAM.",
  error: 'Something went wrong. Please contact support.',
};

export const MODAL_TITLE = {
  success: 'Exciting Times',
  error: 'Houston, we have a problem',
};
