import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getTasksAction } from 'redux/taskDuck';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';

// Components
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Box, CircularProgress } from '@material-ui/core';
import TaskCard from 'components/cards/TaskCard';
import InfiniteScroll from 'react-infinite-scroll-component';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
}));

function TasksGrid({ taskData, sessionData, getTasks }) {
  const classes = useStyles();

  const { user } = sessionData || {};
  const isInfluencer = user?.profile?.type !== 'brands';
  const influencerId = isInfluencer ? user?.id : null;

  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const totalPages = taskData.tasks.incomplete.pages;
  const tasks = taskData.tasks.incomplete.data;

  const getNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    } else {
      setHasMore(false);
    }
  };

  useEffect(() => {
    if (isInfluencer) getTasks(influencerId, 0, page, 10);
  }, [getTasks, page, isInfluencer, influencerId]);

  return (
    <Container maxWidth="lg" style={{ marginTop: '2.5rem' }}>
      {Object.keys(taskData.tasks.incomplete.errors).length !== 0 && (
        <option>Error: Unable to retrieve tasks.</option>
      )}

      {tasks.length === 0 &&
        !taskData.fetching &&
        Object.keys(taskData.tasks.incomplete.errors).length === 0 && (
          <option>No tasks here.</option>
        )}

      <InfiniteScroll
        dataLength={tasks.length} // This is important field to render the next data
        next={getNext}
        hasMore={hasMore}
        style={{ overflow: 'hidden' }} // Necessary for appropriate scroll behavior on mobile
        loader={
          taskData.fetching && (
            <Box mt={2} display="flex" justifyContent="center">
              <CircularProgress color="secondary" size={24} />
            </Box>
          )
        }
      >
        <div className={classes.root}>
          <Grid container spacing={3}>
            {tasks &&
              tasks.map((task) => (
                <TaskCard key={task.booking.task.id} task={task} />
              ))}
          </Grid>
        </div>
      </InfiniteScroll>
    </Container>
  );
}

TasksGrid.propTypes = {
  taskData: PropTypes.shape({
    fetching: PropTypes.bool,
    errors: PropTypes.objectOf(PropTypes.object),
    tasks: PropTypes.shape({
      incomplete: PropTypes.shape({
        pages: PropTypes.number,
        data: PropTypes.array,
        errors: PropTypes.objectOf(PropTypes.object),
      }),
    }),
  }),
  sessionData: PropTypes.shape({
    user: PropTypes.object,
  }),
  getTasks: PropTypes.func,
};

TasksGrid.defaultProps = {
  taskData: {},
  sessionData: {},
  getTasks: () => {},
};

const mapStateToProps = (state) => ({
  taskData: state.taskData,
  sessionData: state.sessionData,
});

const mapDispatchToProps = (dispatch) => ({
  getTasks: (influencerId, completed, page, size) => {
    dispatch(getTasksAction(influencerId, completed, page, size));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TasksGrid);
