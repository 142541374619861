import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
} from '@material-ui/core';

import { KEY_PLAYER_POSITIONS } from 'constants/campaigns';
import { MAX_LENGTH_FIELDS } from 'constants/settings';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
}));

export default function KeyPlayer({
  number,
  nameValue,
  positionValue,
  onKeyPlayerChange,
}) {
  const classes = useStyles();

  return (
    <Box mt={2} mb={2}>
      <Divider className={classes.root} />

      <TextField
        fullWidth
        color="secondary"
        id="name"
        name={`keyPlayer${number}`}
        inputProps={{
          maxLength: MAX_LENGTH_FIELDS.name,
        }}
        value={nameValue}
        label="Name"
        variant="filled"
        onChange={onKeyPlayerChange('name', number)}
      />

      <FormControl variant="filled">
        <InputLabel id="position">Position</InputLabel>

        <Select
          color="secondary"
          labelId="position"
          id="position"
          name={`keyPlayer${number}`}
          value={positionValue}
          onChange={onKeyPlayerChange('type', number)}
        >
          {KEY_PLAYER_POSITIONS.map((position) => (
            <MenuItem value={position} key={position}>
              {position}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

KeyPlayer.propTypes = {
  number: PropTypes.number,
  nameValue: PropTypes.string,
  positionValue: PropTypes.string,
  onKeyPlayerChange: PropTypes.func,
};

KeyPlayer.defaultProps = {
  number: 1,
  nameValue: '',
  positionValue: '',
  onKeyPlayerChange: () => {},
};
