import React, { useState } from 'react';
import TabContext from '@material-ui/lab/TabContext';

import BookingsGrid from 'components/grids/BookingsGrid';
import { Container } from '@material-ui/core';
import { Route, useParams } from 'react-router-dom';
import FloatingTabs from 'components/nav/FloatingTabs';
import { TABS } from 'constants/bookings';

export default function Bookings() {
  const { status } = useParams();

  const currentNumberTab = status
    ? TABS.filter((tab) => status === tab.label.toLowerCase())[0].value
    : 0;

  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (tab) => {
    setCurrentTab(tab);
  };

  return (
    <Container maxWidth="lg" style={{ marginTop: '2.5rem' }}>
      <TabContext value={currentTab}>
        <FloatingTabs
          tabs={TABS}
          onChange={handleTabChange}
          routeValue={currentNumberTab}
        />
        {TABS.map((tab) => (
          <Route
            exact
            path={tab.to}
            render={() => <BookingsGrid status={tab.status} />}
          />
        ))}
        <Route
          exact
          path="/bookings/pending"
          render={() => <BookingsGrid status="pending" />}
        />
      </TabContext>
    </Container>
  );
}
