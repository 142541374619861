import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import ChipInput from 'material-ui-chip-input';

import HelpIcon from '@material-ui/icons/Help';

import DataTable from 'components/lists/DataTable';
import {
  DEFAULT_REQUIREMENTS,
  CAMPAIGN_CREATION_HELPER,
} from 'constants/campaigns';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  tableContainer: {
    '& .MuiTableContainer-root': {
      margin: 0,
    },
  },
});

export default function CampaignRequirements({
  onUpdate,
  campaignDraft,
  errors,
}) {
  const classes = useStyles();
  const hashtagHelper = 'Type and press enter to add';
  const [values, setValues] = useState({
    posts: campaignDraft?.posts || 0,
    stories: campaignDraft?.stories || 0,
    hashtags: campaignDraft?.hashtags || null,
  });

  const handleSelectChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const handleTagsChange = (tags) => {
    setValues({ ...values, hashtags: tags });
  };

  const handleDeleteTag = (tag, i) => {
    // Remove deleted tag without mutating array directly
    const stateTags = values.hashtags;
    stateTags.splice(i, 1);
    setValues({ ...values, hashtags: stateTags });
  };

  const updateCallback = () => {
    onUpdate(values);
  };

  useEffect(updateCallback, [values]);

  return (
    <Container maxWidth="sm" style={{ marginTop: '2.5rem' }}>
      <form>
        <Container align="center">
          <Typography gutterBottom variant="h4" component="h2">
            Set your expectations.
          </Typography>

          <Typography gutterBottom component="p">
            In exchange for their visit, influencers will post on social media.
            Please set your requirements.
          </Typography>

          <Typography gutterBottom component="p">
            * Journalists will not have any requirements, just consideration for
            coverage.
          </Typography>
        </Container>

        <Card>
          <CardContent>
            <Typography gutterBottom variant="overline" component="h2">
              Posting
            </Typography>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography gutterBottom variant="body2" component="p">
                {CAMPAIGN_CREATION_HELPER.posting}
              </Typography>

              <Tooltip
                title="Social media posts to live on the main feed in perpetuity. Stories are visible to followers for 24 hours."
                placement="left"
              >
                <IconButton aria-label="more information">
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </Box>

            <FormControl variant="filled">
              <InputLabel id="postsNum">Number of Posts</InputLabel>
              <Select
                color="secondary"
                labelId="postsNum"
                name="posts"
                id="posts-num"
                value={values.posts}
                onChange={handleSelectChange}
              >
                <MenuItem value="0">None</MenuItem>
                <MenuItem value="1">1 post</MenuItem>
                <MenuItem value="2">2 posts</MenuItem>
                <MenuItem value="3">3 posts</MenuItem>
              </Select>
            </FormControl>

            <FormControl variant="filled">
              <InputLabel id="storiesNum">Number of Stories</InputLabel>
              <Select
                color="secondary"
                labelId="storiesNum"
                name="stories"
                id="stories-num"
                value={values.stories}
                onChange={handleSelectChange}
              >
                <MenuItem value="0">None</MenuItem>
                <MenuItem value="1">1 story</MenuItem>
                <MenuItem value="2">2 stories</MenuItem>
                <MenuItem value="3">3 stories</MenuItem>
              </Select>
            </FormControl>

            {/* ChipInput docs: https://www.npmjs.com/package/material-ui-chip-input */}
            <ChipInput
              label="Required Hashtags"
              value={values.hashtags}
              placeholder={hashtagHelper}
              helperText={
                errors?.hashtags && !values.hashtags ? errors?.hashtags : ''
              }
              error={errors?.hashtags && !values.hashtags}
              onChange={(chips) => handleTagsChange(chips)}
              onDelete={(chip, index) => handleDeleteTag(chip, index)}
              InputProps={{
                type: 'text',
              }}
            />
          </CardContent>
        </Card>

        <Card>
          <CardContent className={classes.tableContainer}>
            <DataTable
              tableTitle="All campaigns require:"
              rows={DEFAULT_REQUIREMENTS}
              condensed
            />
          </CardContent>
        </Card>
      </form>
    </Container>
  );
}

CampaignRequirements.propTypes = {
  onUpdate: PropTypes.func,
  campaignDraft: PropTypes.objectOf(PropTypes.object),
  errors: PropTypes.objectOf(PropTypes.object),
};

CampaignRequirements.defaultProps = {
  onUpdate: () => {},
  campaignDraft: {},
  errors: {},
};
