export const BOOKING_REQUEST_MODALS = {
  success: {
    open: true,
    title: 'Exciting Times',
    description: 'This will be fun. Keep an eye on your inbox.',
    label: 'Cool',
  },
  failure: {
    open: true,
    title: 'Hold Up',
    description: "Looks like you've already booked this campaign",
    label: 'OK',
  },
  validation: {
    open: true,
    title: 'Hold Up',
    description: 'We need a few more details from you',
    label: 'OK',
  },
};

export const TABS = [
  { label: 'Pending', value: 0, status: 'pending', to: '/bookings' },
  {
    label: 'Upcoming',
    value: 1,
    status: 'accepted',
    to: '/bookings/upcoming',
  },
  {
    label: 'Past',
    value: 2,
    status: 'finished,declined,cancelled',
    to: '/bookings/past',
  },
];
